import {
  SnackbarContent as MuiSnackbarContent,
  SnackbarContentProps,
  snackbarContentClasses,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const SnackbarContent = styled(MuiSnackbarContent)<SnackbarContentProps>(
  ({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    background: theme.palette.grey['70'],
    padding: 12,
    borderRadius: 8,

    [`&.${snackbarContentClasses.root}`]: {
      flexWrap: 'nowrap',
      alignItems: 'center',
      minWidth: 'auto',
      maxWidth: 770 - 12 * 2,
    },

    [`.${snackbarContentClasses.message}`]: {
      ...theme.typography.p2,
      padding: 0,
      '&:last-child': {
        maxWidth: 670 - 12 * 2,
      },
    },

    [`.${snackbarContentClasses.action}`]: {
      ...theme.typography.h5,
      color: theme.palette.yellow['40'],
      paddingLeft: 24,
      margin: 0,
      gap: 12,
      ['& .Snackbar-CloseButton']: {
        cursor: 'pointer',
        color: theme.palette.grey['30'],
      },
    },
  })
);

SnackbarContent.defaultProps = {
  theme: defaultTheme,
};

export default SnackbarContent;
