import addDays from 'date-fns/addDays';
import eachDay from 'date-fns/eachDayOfInterval';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';

export function getWeekdayLabels({
  firstDayOfWeek = 1,
  weekdayLabelFormat = (date) => format(date, 'iiiiii'),
} = {}) {
  const now = new Date();
  const arr = eachDay({
    start: addDays(startOfWeek(now), firstDayOfWeek),
    end: addDays(endOfWeek(now), firstDayOfWeek),
  });
  return arr.reduce((array, date) => {
    array.push(weekdayLabelFormat(date));
    return array;
  }, []);
}
export function getDays({
  year,
  month,
  firstDayOfWeek = 1,
  dayLabelFormat = (date) => format(date, 'dd'),
}) {
  const date = new Date(year, month);
  const monthStart = startOfMonth(date);
  const monthStartDay = getDay(monthStart);
  const monthEnd = endOfMonth(date);
  const prevMonthDays = Array.from(
    Array(
      monthStartDay >= firstDayOfWeek
        ? monthStartDay - firstDayOfWeek
        : 6 - firstDayOfWeek + monthStartDay + 1
    ).keys()
  ).fill(0);
  const days = eachDay({ start: monthStart, end: monthEnd }).map((date) => ({
    date,
    dayLabel: dayLabelFormat(date),
  }));
  return [...prevMonthDays, ...days];
}
