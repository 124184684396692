import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';
import React, { ElementType, FC, ReactNode, useCallback } from 'react';
import useClasses from './Link.styles';
import clsx from 'clsx';

export type LinkProps = {
  /**
   * Element placed after the children
   */
  endIcon?: ReactNode;
  /**
   * Element placed before the children
   * @deprecated use startIcon instead
   */
  icon?: ReactNode;
  /**
   * Element placed before the children
   * @deprecated use display="inline"
   */
  inline?: boolean;
  /**
   * Element placed before the children.
   */
  startIcon?: ReactNode;
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: ElementType;
  /**
   * A string representation of the Link location,
   * created by concatenating the location’s pathname, search, and hash properties.
   * Property is used in Link component from react-router-dom
   */
  to?: string;
} & MuiLinkProps;

export const Link: FC<LinkProps> = ({
  children,
  className,
  color = 'secondary',
  endIcon,
  icon,
  onClick,
  startIcon = icon,
  underline = 'none',
  ...rest
}) => {
  const classes = useClasses();
  const handleClick = useCallback(
    (event) => {
      if (typeof onClick === 'function') {
        event.preventDefault();
        onClick(event);
      }
    },
    [onClick]
  );

  const renderStartIcon = useCallback(
    () =>
      startIcon ? <span className={classes.startIcon}>{startIcon}</span> : null,
    [classes, startIcon]
  );

  const renderEndIcon = useCallback(
    () => (endIcon ? <span className={classes.endIcon}>{endIcon}</span> : null),
    [classes, endIcon]
  );

  return (
    <MuiLink
      className={clsx(classes.root, className)}
      color={color}
      onClick={handleClick}
      underline={underline}
      {...rest}
    >
      {renderStartIcon()}
      {children}
      {renderEndIcon()}
    </MuiLink>
  );
};

export default Link;