import React, { ReactNode } from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '..';

export interface MenuItemAttributes extends MuiMenuItemProps {
  icon?: ReactNode;
  label?: ReactNode;
}

export interface MenuItemsProps {
  menuItems: MenuItemAttributes[];
  onItemClick?: (key: MenuItemAttributes['key']) => void;
}

export function MenuItems({ menuItems, onItemClick }: MenuItemsProps) {
  const hasIcons = menuItems.some((item) => item.icon);
  return (
    <>
      {menuItems.map(
        ({ icon, children, label, key, onClick, ...menuItemProps }, index) => (
          <MenuItem
            key={key || index}
            {...menuItemProps}
            onClick={(event) => {
              onClick && onClick(event);
              onItemClick && onItemClick(key || index);
            }}
          >
            {hasIcons && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>{label || children}</ListItemText>
          </MenuItem>
        )
      )}
    </>
  );
}
