import {
  Toolbar as MuiToolbar,
  ToolbarProps as MuiToolbarProps,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback } from 'react';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import ToolbarStyles from './Toolbar.styles';

export type ToolbarProps = {
  disableClose?: boolean;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & MuiToolbarProps &
  WithStyles<typeof ToolbarStyles>;

const Toolbar: React.FC<ToolbarProps> = ({
  children,
  classes,
  className,
  disableClose = false,
  onClose,
  ...rest
}) => {
  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (typeof onClose === 'function') {
        onClose(event);
      }
    },
    []
  );
  return (
    <MuiToolbar
      className={clsx(classes.root, classes.gutters, className)}
      {...rest}
    >
      {children}
      {!disableClose ? (
        <Button
          className={clsx(classes.close)}
          icon={<Icon name="Close" />}
          onClick={handleClose}
          variant="text"
        />
      ) : null}
    </MuiToolbar>
  );
};

export default withStyles(ToolbarStyles, {
  name: 'Toolbar',
})(Toolbar);
