import {
  TableCell as MuiTableCell,
  tableCellClasses,
  TableCellProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const TableCell = styled(MuiTableCell)<TableCellProps>(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    ...theme.typography.p2,
    color: theme.palette.grey['100'],
    overflow: 'hidden',
    wordBreak: 'break-word',

    boxSizing: 'border-box',
    padding: '20px 0 20px 16px',
    borderBottom: 'none',
    boxShadow: `inset 0px -1px 0px ${theme.palette.grey['30']}`,

    '&:last-child': {
      paddingRight: 16,
    },

    ['&[hidden]']: {
      visibility: 'hidden',
    },
  },

  [`&.${tableCellClasses.head}`]: {
    fontWeight: 500,
    padding: '16px 0 16px 16px',
    boxShadow: `inset 0px -3px 0px ${theme.palette.grey['30']}`,
    borderBottom: 'none',
    verticalAlign: 'bottom',
  },
}));

TableCell.defaultProps = {
  theme: defaultTheme,
};

export default TableCell;
