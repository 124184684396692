import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    wrapper: {
      padding: '16px',
    },
    submitButton: {
      marginRight: '24px',
    },
  });
