import React from 'react';
import { Icons, defaultTheme, IconButton, styled } from '../../index';
import { IconButtonProps } from '@mui/material';

export const scrollToTopButtonSize = 52;
export const scrollToTopButtonPadding = 24;

export const ScrollToTopButton = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    width: scrollToTopButtonSize,
    height: scrollToTopButtonSize,
    borderRadius: '100%',
    backgroundColor: theme.palette.grey['0'],
    color: theme.palette.blue['70'],
    position: 'absolute',
    right: scrollToTopButtonPadding,
    bottom: `calc(100% + ${scrollToTopButtonPadding}px)`,
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      color: theme.palette.blue['100'],
      backgroundColor: theme.palette.grey['20'],
    },
    '&:active': {
      color: theme.palette.grey['100'],
      backgroundColor: theme.palette.grey['30'],
    },
  })
);

ScrollToTopButton.defaultProps = {
  theme: defaultTheme,
  children: <Icons.ArrowLongUp_medium />,
  disableRipple: true,
};
