import React, { forwardRef, useCallback } from 'react';
import {
  default as ReactDatePicker,
  ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Icons,
  defaultTheme,
  InputAdornment,
  styled,
  TextField,
} from '../index';

const DatePickerRoot = styled(Box)(({ theme }) => ({
  '.react-datepicker': {
    border: 'none',
    padding: 16,
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey['00'],
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.08)',
  },
  '.react-datepicker__day--keyboard-selected': {},
  '.react-datepicker__header': {
    backgroundColor: theme.palette.grey['00'],
    border: 'none',
    padding: 0,
    borderRadius: 0,
    '&__dropdown': {
      ...theme.typography.h4,
      padding: '0 4px',
      textAlign: 'start',
      textTransform: 'capitalize',
    },
  },
  '.react-datepicker-popper': {
    zIndex: 2,
    '&[data-placement^=bottom]': {
      paddingTop: 5,
    },
    '&[data-placement^=top]': {
      paddingBottom: 0,
    },
  },
  '.react-datepicker__current-month': {
    display: 'none',
  },
  '.react-datepicker__month-read-view, .react-datepicker__year-read-view': {
    display: 'flex',
    position: 'relative',
    padding: '8px 4px',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.grey['20'],
    },
  },
  '.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow':
    {
      position: 'inherit',
      alignSelf: 'flex-end',
      display: 'none',
    },
  '.react-datepicker__day-names': {
    backgroundColor: theme.palette.grey['00'],
    margin: 0,
  },
  '.react-datepicker__month': {
    margin: 0,
  },
  '.react-datepicker__week': {},
  '.react-datepicker__day-name': {
    ...theme.typography.p2,
    width: 36,
    height: 36,
    margin: 2,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey['60'],
    textTransform: 'capitalize',
    '&:nth-last-of-type(-n+2)': {
      color: theme.palette.red['70'],
    },
  },

  '.react-datepicker__day': {
    ...theme.typography.p2,
    width: 36,
    height: 36,
    borderRadius: 8,
    backgroundColor: theme.palette.grey['00'],
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 2,
    color: theme.palette.grey['100'],

    '&--today': {
      outline: `${theme.palette.grey['60']} solid 1px`,
    },
    '&--selected': {
      backgroundColor: theme.palette.yellow['40'],
    },

    '&:hover': {
      backgroundColor: theme.palette.grey['20'],
    },
    '&--selected:hover': {
      backgroundColor: theme.palette.grey['40'],
    },
    '&--disabled': {
      color: theme.palette.grey['50'],
      '&:hover': {
        backgroundColor: theme.palette.grey['00'],
      },
    },
    '&--highlighted': {
      color: theme.palette.blue['70'],
    },
    '&:focus-visible': {
      outline: 'none',
    },
    '&--keyboard-selected:focus-visible, &--keyboard-selected': {
      outline: `${theme.palette.yellow['60']} auto 1px`,
      '&.react-datepicker__day--disabled': {
        outline: `${theme.palette.grey['40']} auto 1px`,
      },
    },
    '&--outside-month': {
      visibility: 'hidden',
    },
  },
  '.react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container':
    {
      margin: 0,
    },
  '.react-datepicker__year-dropdown, .react-datepicker__month-dropdown': {
    backgroundColor: theme.palette.grey['00'],
    borderRadius: 8,
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
    border: 'none',
    width: '60%',
    left: 0,
    top: 0,
  },
  '.react-datepicker__navigation': {
    color: theme.palette.grey['60'],
    '&:hover': {
      color: theme.palette.blue['90'],
    },
    '&--previous': {
      right: 52,
      top: 21,
      left: 'unset',
    },
    '&--next': {
      right: 16,
      top: 21,
    },
    '&-icon': {
      top: 0,
      '&::before': {
        content: '""',
        borderWidth: '2px 2px 0 0',
        height: 6,
        width: 6,
        borderColor: 'unset',
      },
      '&--previous': {
        right: 2,
      },
      '&--next': {
        left: 2,
      },
    },
  },
  '.react-datepicker__month-option, .react-datepicker__year-option': {
    ...theme.typography.p2,
    padding: '8px 12px',
    boxSizing: 'border-box',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.grey['20'],
    },
  },
  '.react-datepicker__year-option': {
    margin: '2px 0 2px 4px',
    width: 'auto',
  },
  '.react-datepicker__month-option--selected_month, .react-datepicker__year-option--selected_year':
    {
      backgroundColor: theme.palette.yellow['40'],
      '&:hover': {
        backgroundColor: theme.palette.grey['40'],
      },
    },
  '.react-datepicker__month-option--selected, .react-datepicker__year-option--selected':
    {
      display: 'none',
    },
  '.react-datepicker__month-dropdown': {
    padding: 8,
    overflow: 'hidden',
    width: 'fit-content',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 4,
  },
  '.react-datepicker__year-dropdown--scrollable': {
    padding: '8px 0',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 40 * 6,
    '&::-webkit-scrollbar': {
      width: 16,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey['30'],
      border: `4px solid ${theme.palette.grey['00']}`,
      borderRadius: 8,
    },
  },
}));

DatePickerRoot.defaultProps = {
  theme: defaultTheme,
};

const CalendarButton = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.grey['60'],
  '&[aria-disabled=true]': {
    color: theme.palette.grey['60'],
    cursor: 'default',
  },
}));

CalendarButton.defaultProps = {
  theme: defaultTheme,
  children: <Icons.Calendar_medium />,
};

const DateInput = forwardRef<
  typeof TextField,
  React.HTMLProps<HTMLInputElement>
>((props, ref) => {
  return (
    <TextField
      InputProps={{
        readOnly: props.readOnly,
        inputProps: {
          onChange: props.onChange,
        },
        endAdornment: (
          <InputAdornment position="end">
            <CalendarButton aria-disabled={props.disabled || props.readOnly} />
          </InputAdornment>
        ),
      }}
      autoComplete={props.autoComplete}
      autoFocus={props.autoFocus}
      className={props.className}
      disabled={props.disabled}
      error={props['aria-invalid'] === 'true'}
      helperText={props.placeholder}
      id={props.id}
      label={props.title}
      name={props.name}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onKeyDown={props.onKeyDown}
      ref={ref}
      required={props.required}
      value={props.value}
    />
  );
});

export interface DatePickerProps extends ReactDatePickerProps {
  helperText?: string;
  label?: string;
  error?: boolean;
}

export default function DatePicker(props: DatePickerProps) {
  const {
    helperText,
    placeholderText,
    label,
    title,
    error,
    ariaInvalid,
    onKeyDown,
    ...reactDatePickerProps
  } = props;

  const onKeyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
      }
      onKeyDown?.(event);
    },
    [onKeyDown]
  );

  return (
    <DatePickerRoot className="DatePickerRoot">
      <ReactDatePicker
        {...reactDatePickerProps}
        ariaInvalid={error ? 'true' : ariaInvalid}
        onKeyDown={onKeyDownHandler}
        placeholderText={helperText || placeholderText}
        title={label || title}
      />
    </DatePickerRoot>
  );
}

DatePicker.defaultProps = {
  dateFormat: 'dd.MM.yyyy',
  customInput: <DateInput />,
  popperPlacement: 'bottom-end',
  showMonthDropdown: true,
  showYearDropdown: true,
  scrollableYearDropdown: true,
  showPopperArrow: false,
};
