import { FieldRenderProps } from 'react-final-form';
import React, { useMemo } from 'react';
import {
  AdditionalFilterRenderProps,
  FilterableColumnInstance,
  FilterValidationHandler,
} from '../TableFilter.types';

interface ColumnFilterProps<T extends Record<string, any>> {
  column: FilterableColumnInstance<T>;
  formField: FieldRenderProps<any>;
  onValidatorChange: (fn?: FilterValidationHandler<any>) => void;
}

export function ColumnFilter<T extends Record<string, any>>({
  column,
  formField,
  onValidatorChange,
}: ColumnFilterProps<T>) {
  const { value, onChange, onBlur } = formField.input;
  const { error } = formField.meta;

  const filterRenderProps = useMemo(
    (): AdditionalFilterRenderProps<any> => ({
      filterValue: value === '' || value === null ? undefined : value,
      setFilterValue: onChange,
      useValidator: (validationFn?: FilterValidationHandler<any>) => {
        onValidatorChange(validationFn);
        return { error };
      },
      clearValue: () => onChange(undefined),
      executeValidation: () => onBlur(),
    }),
    [error, onBlur, onChange, onValidatorChange, value]
  );
  return <>{column.render('Filter', filterRenderProps)}</>;
}
