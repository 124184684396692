import { makeStyles } from '@material-ui/core';

export const useClasses = makeStyles((theme) => ({
  root: {
    background: 'none',
    border: 'none',
    width: '100%',
    lineHeight: 16 / 14,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  select: {
    padding: '24px 16px 0 !important',

    '&:focus': {
      background: 'none',
    },
  },
  icon: {
    color: 'inherit',
    position: 'static',
    pointerEvents: 'none',
    zIndex: 1,
    transition: theme.transitions.create('transform'),

    '$focused &': {
      transform: 'rotate(180deg)',
    },
  },

  focused: {},
}));

export const useMenuClasses = makeStyles({
  list: {
    maxHeight: '300px',
    overflow: 'auto',
  },
});
