import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core';
import React, { FC } from 'react';

import useClasses from './Switch.styles';

export interface SwitchProps extends MuiSwitchProps {}

export const Switch: FC<SwitchProps> = ({ color = 'primary', ...rest }) => {
  const classes = useClasses();
  return <MuiSwitch classes={classes} color={color} {...rest} />;
};

export default Switch;
