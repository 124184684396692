/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo, useState } from 'react';
import { Time } from './TimePicker.types';
import { Input } from '@crpt-ui/core';
import TimePicker from './TimePicker';
import Menu from '@material-ui/core/Menu';
import { getTimeTitle } from './utils';

type TimePickerInputProps = {
  initialTime?: Time;
  disabled: boolean;
  onChange: (time: Time) => void;
};

const TimePickerInput: React.FC<TimePickerInputProps> = ({
  initialTime,
  disabled = false,
  onChange,
  ...props
}) => {
  const initialTimeValue = initialTime
    ? initialTime
    : {
        hours: '',
        minutes: '',
        seconds: '',
      };

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [timeValue, setTimeValue] = useState<Time>(initialTimeValue);

  const inputValue = useMemo(() => {
    return getTimeTitle(timeValue, true);
  }, [timeValue]);

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handleTimeSubmit = (timeValue: Time) => {
    setTimeValue(timeValue);
    onChange(timeValue);
    handleClose();
  };

  return (
    <>
      <Input
        // @ts-ignore
        value={inputValue}
        onClick={handleInputClick}
        disabled={disabled}
        {...props}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {anchorEl ? (
          <TimePicker
            onSubmit={handleTimeSubmit}
            onClose={handleClose}
            initialTime={timeValue}
          />
        ) : null}
      </Menu>
    </>
  );
};

export default TimePickerInput;
