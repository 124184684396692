import { createStyles, Theme } from '@material-ui/core';

export const TimeAnnotationStyles = (theme: Theme) =>
  createStyles({
    content: {
      padding: '16px',
      fontSize: '12px',
      color: theme.palette.text.secondary,
    },
  });
