import { Tabs, withStyles } from '@material-ui/core';
import React, { FC } from 'react';

import { TabsProps } from '../Tabs/Tabs';
import Step from './Step';
import StepsStyles from './Steps.styles';

const Steps: FC<TabsProps> = ({
  items = [],
  variant = 'standard',
  ...rest
}) => {
  return (
    <Tabs variant={variant} {...rest}>
      {items.map((item, index) => (
        <Step key={index} {...item} />
      ))}
    </Tabs>
  );
};

export default withStyles(StepsStyles, {
  name: 'Steps',
})(Steps);
