import { defaultTheme, styled } from '../theme';

const SelectPlaceholder = styled('span')(({ theme }) => ({
  color: theme.palette.grey['60'],
}));

SelectPlaceholder.defaultProps = {
  theme: defaultTheme,
};

export default SelectPlaceholder;
