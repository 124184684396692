import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMonth } from '../hooks';
import Day from './Day';
import MonthStyles from './Month.styles';

const Month = ({ classes, year, month, firstDayOfWeek }) => {
  const { days, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <span className={classes.month}>{monthLabel}</span>
      </div>
      <div className={classes.days}>
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }
          return <div key={index} />;
        })}
      </div>
    </div>
  );
};

Month.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  firstDayOfWeek: PropTypes.number,
};

export default withStyles(MonthStyles)(Month);
