import {
  UsePluginsParams,
  UseTableInstance,
  UseTableInstanceHookOptions,
} from '../table.types';
import {
  useColumnOrder,
  useFilters,
  useTable,
  TableOptions,
  PluginHook,
  useRowSelect,
  useSortBy,
  useExpanded,
  useRowState,
  usePagination,
} from 'react-table';
import {
  makeTextInputFilter,
  useCellContainer,
  useEmptyValue,
  useMerge,
  useSelectRowColumn,
  useActionColumn,
  useTableConfig,
  useAlwaysVisibleColumns,
} from '../assets';
import { useMemo } from 'react';

function makeDefaultColumnConfig<
  T extends Record<string, any>
>(): UseTableInstanceHookOptions<T>['defaultColumn'] {
  return {
    Filter: makeTextInputFilter(),
    emptyValue: true,
  };
}

const largeDash = '—';

function useTablePlugins<T extends Record<string, any>>(
  use: UsePluginsParams | undefined
): PluginHook<T>[] {
  return useMemo(() => {
    const plugins = [
      useCellContainer,
      useMerge,
      useEmptyValue,
      useColumnOrder,
      useTableConfig,
      useAlwaysVisibleColumns,
    ];

    use?.filters && plugins.push(useFilters);
    use?.sortBy && plugins.push(useSortBy);
    use?.expanded && plugins.push(useExpanded);
    use?.pagination && plugins.push(usePagination);
    use?.rowSelect && plugins.push(useRowSelect, useSelectRowColumn);
    use?.rowState && plugins.push(useRowState);
    use?.rowActions && plugins.push(useActionColumn);

    return plugins;
  }, [
    use?.filters,
    use?.sortBy,
    use?.expanded,
    use?.rowSelect,
    use?.rowState,
    use?.pagination,
    use?.rowActions,
  ]);
}

export function useTableInstance<T extends Record<string, any>>(
  options: UseTableInstanceHookOptions<T>,
  ...plugins: PluginHook<T>[]
) {
  const instanceOptions: UseTableInstanceHookOptions<T> = useMemo(
    () => ({
      ...options,
      initialState: {
        defaultConfig: options.defaultConfig,
        defaultEmptyValue: options.defaultEmptyValue || largeDash,
        alwaysVisibleColumns: options.alwaysVisibleColumns,
        rowActions: options.rowActions,
        ...options.initialState,
      },
      defaultColumn: options.defaultColumn || makeDefaultColumnConfig(),
    }),
    [options]
  );

  const instancePlugins = useTablePlugins<T>(options.use);

  const instance = useTable<T>(
    instanceOptions as unknown as TableOptions<T>,
    ...instancePlugins,
    ...plugins
  ) as UseTableInstance<T>;

  return instance;
}
