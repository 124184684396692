import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    wrapper: {
      padding: '16px',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

export const TimeDividerStyles = () =>
  createStyles({
    content: {
      margin: '0px 16px',
    },
  });
