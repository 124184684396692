/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createTheme } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';

const margins = {
  marginBottom: 24,
  marginTop: 24,

  '&:first-child': {
    marginTop: 0,
  },
  '&:last-child': {
    marginBottom: 0,
  },
};

const palette = createPalette({
  background: {
    default: '#f9f9f9',
  },
  primary: {
    main: '#f6f42e',
    contrastText: '#63666a',
  },
  secondary: {
    main: '#5ca6dc',
    contrastText: '#fff',
  },
  text: {
    primary: '#63666a',
    secondary: '#868686',
    disabled: '#b8b8b8',
  },
  error: {
    main: '#f34747',
    dark: '#cb3939',
    contrastText: '#fff',
  },
  success: {
    dark: '#408f33',
    main: '#48c834',
    contrastText: '#fff',
  },
  warning: {
    main: '#f5a020',
    contrastText: '#fff',
  },
  grey: {
    100: '#fcfcfc',
    200: '#f9f9f9',
    300: '#f7f7f7',
    400: '#f2f2f2',
    500: '#d9d9d9',
    600: '#b8b8b8',
    700: '#868686',
  },
});

export default createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        width: '368px',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: 32,
        fontWeight: 700,
        letterSpacing: '0.02em',
        lineHeight: 48 / 32,
        textTransform: 'uppercase',
        ...margins,
      },
      h2: {
        fontSize: 24,
        fontWeight: 700,
        letterSpacing: '0.02em',
        lineHeight: 32 / 24,
        textTransform: 'uppercase',
        ...margins,
      },
      h3: {
        fontSize: 32,
        fontWeight: 600,
        lineHeight: 40 / 32,
        ...margins,
      },
      h4: {
        fontSize: 18,
        fontWeight: 700,
        letterSpacing: '0.02em',
        lineHeight: 24 / 18,
        textTransform: 'uppercase',
        ...margins,
      },
      h5: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 24 / 18,
        ...margins,
      },
      h6: {
        fontSize: 14,
        fontWeight: 700,
        letterSpacing: '0.02em',
        lineHeight: 24 / 14,
        textTransform: 'uppercase',
        ...margins,
      },
      body1: {
        fontSize: 16,
        lineHeight: 24 / 16,
        ...margins,
      },
      body2: {
        fontSize: 14,
        lineHeight: 16 / 14,
        ...margins,
      },
      button: {
        fontSize: 12,
        lineHeight: 24 / 12,
        fontWeight: 700,
        letterSpacing: '0.02em',
        textTransform: 'uppercase',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        verticalAlign: 'top',
      },
    },
    MuiPaper: {
      root: {
        background: '#fff',
        borderRadius: '4px',
        boxSizing: 'border-box',
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'top',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, .4)',
      },
    },
    // @ts-ignore
    MuiPickersBasePicker: {
      pickerView: {
        boxSizing: 'border-box',
        justifyContent: 'flex-start',
        maxWidth: 312,
        minHeight: 352,
        minWidth: 312,
        padding: 16,
        paddingTop: 24,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: 0,
        marginBottom: 0,
      },
      transitionContainer: {
        height: 16,
        overflow: 'visible',
        '& p': {
          textTransform: 'uppercase',
          fontSize: 14,
          fontWeight: 700,
          lineHeight: 1.2,
          letterSpacing: '.02em',
        },
      },
      iconButton: {
        fontSize: 16,
        '& svg': {
          fontSize: '1em',
        },
      },
      daysHeader: {
        marginTop: 16,
        maxHeight: 40,
        padding: '8px 0',
      },
      dayLabel: {
        fontSize: 14,
        lineHeight: 24 / 14,
        color: palette.text.disabled,
        textTransform: 'capitalize',
      },
    },
    MuiPickersYear: {
      root: {
        height: 56,
        fontSize: 15,
        lineHeight: 16 / 15,

        '&, &:focus, &$yearSelected': {
          color: palette.text.primary,
          fontWeight: 700,
          margin: 0,
        },
      },
      yearSelected: {
        background: palette.primary.main,
      },
    },
    MuiPickersYearSelection: {
      container: {
        margin: '-16px -16px 0',
        height: 352 - 16,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: 0,
        minHeight: 240,
      },
    },
    MuiPickersDay: {
      day: {
        width: 40,
        height: 40,
        borderRadius: 4,
        margin: 0,
        color: palette.text.primary,
        '& p, &$current': {
          fontSize: 14,
          lineHeight: 16 / 14,
          fontWeight: 600,
        },
      },
      current: {
        color: palette.text.primary,
        border: `1px solid ${palette.text.primary}`,
      },
      daySelected: {
        backgroundColor: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.primary.main,
        },
      },
    },
  },
  palette,
  spacing: 8,
  typography: {
    fontFamily: ['"Segoe UI"', 'sans-serif'].join(','),
  },
});
