import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    root: {
      borderRadius: '4px 4px 0px 0px',
      minHeight: 48,
      position: 'relative',
    },
    indicator: {
      display: 'none',
    },
  });
