import { fade, Theme } from '@material-ui/core';
import get from 'lodash/get';
import memoize from 'lodash/memoize';

export const getColor = memoize((color: string, theme: Theme) => {
  const map: Record<string, string> = {
    error: theme.palette.error.main,
    grey: theme.palette.grey[200],
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    success: theme.palette.success.main,
    textPrimary: theme.palette.text.primary,
    textSecondary: theme.palette.text.secondary,
    warning: theme.palette.warning.main,
  };

  return get(map, color, fade(theme.palette.primary.main, 0.15));
});

export const getContrastColor = memoize((color: string, theme: Theme) => {
  const map: Record<string, string> = {
    error: theme.palette.error.contrastText,
    primary: theme.palette.primary.contrastText,
    secondary: theme.palette.secondary.contrastText,
    warning: theme.palette.warning.contrastText,
    success: theme.palette.success.contrastText,
    textPrimary: theme.palette.common.white,
    textSecondary: theme.palette.common.white,
  };

  return get(map, color, theme.palette.text.primary);
});
