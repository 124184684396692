import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import {
  FilterableColumnInstance,
  FilterValidationHandler,
} from '../TableFilter.types';
import { ColumnFilter } from './ColumnFilter';

interface FilterFieldProps<T extends Record<string, any>> {
  column: FilterableColumnInstance<T>;
}

interface ValidationHolder {
  fn?: FilterValidationHandler<any>;
}

export function FilterField<T extends Record<string, any>>(
  props: FilterFieldProps<T>
) {
  const { column } = props;
  const [validation] = useState<ValidationHolder>({});
  const [validationState, setValidationState] = useState<0 | 1>(0);

  useLayoutEffect(
    () => setValidationState(validation.fn ? 1 : 0),
    [Boolean(validation.fn)]
  );

  const onValidatorChange = useCallback((fn) => (validation.fn = fn), []);

  const onValidate = useCallback<FilterValidationHandler<any>>(
    (...args) => validation.fn?.(...args),
    []
  );

  const renderColumnFilter = useCallback(
    (formFiled: FieldRenderProps<any, HTMLElement>) => {
      return (
        <ColumnFilter
          column={column}
          formField={formFiled}
          onValidatorChange={onValidatorChange}
        />
      );
    },
    [column]
  );

  return (
    <Field
      key={validationState}
      name={column.id}
      validate={onValidate}
      render={renderColumnFilter}
    />
  );
}
