import React, { memo, useMemo } from 'react';
import { Autocomplete, AutocompleteProps } from '../Autocomplete';

export interface LookupInputProps<
  Value,
  Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined = false
> extends Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, false>,
    'options' | 'getOptionLabel' | 'renderOptionItem' | 'freeSolo'
  > {
  options: readonly Option[];
  getOptionValue?: (option: Option) => Value;
  getOptionLabel?: (option: Option) => string;
  renderOptionItem?: (option: Option) => React.ReactNode;
}

export interface LookupInputDefaultOption<Value> {
  label?: string;
  name?: string;
  value: Value;
}

const LookupInput = <
  Value,
  Option extends Record<string, any> = LookupInputDefaultOption<Value>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false
>(
  props: LookupInputProps<Value, Option, Multiple, DisableClearable>
) => {
  const {
    options,
    getOptionValue = ({ value }) => value,
    getOptionLabel = ({ label, name, value }) => label || name || value,
    renderOptionItem,
    ...autocompleteProps
  } = props;

  const optionRegistry = useMemo<Map<Value, Option>>(
    () => new Map(options.map((option) => [getOptionValue(option), option])),
    [getOptionValue, options]
  );

  const optionValues = useMemo<Value[]>(
    () => Array.from(optionRegistry.keys()),
    [optionRegistry]
  );

  return (
    <Autocomplete
      filterOptions={(optionValues, state) => {
        const inputValue = state.inputValue.toLowerCase();
        return inputValue
          ? optionValues.filter((value) => {
              const option = optionRegistry.get(value);
              const label = option ? getOptionLabel(option) : null;
              return label?.toLowerCase().includes(inputValue);
            })
          : optionValues;
      }}
      {...autocompleteProps}
      getOptionLabel={(optionValue) => {
        const option = optionRegistry.get(optionValue);
        return option ? getOptionLabel(option) : optionValue;
      }}
      options={optionValues}
      renderOptionItem={(optionValue) => {
        const option = optionRegistry.get(optionValue);
        return option ? renderOptionItem?.(option) : null;
      }}
    />
  );
};

export default memo(LookupInput) as any as typeof LookupInput;
