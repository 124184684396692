import { makeStyles } from '@material-ui/core';

export const useRootClasses = makeStyles(({ palette }) => ({
  root: {
    '&$required::before': {
      background: palette.error.main,
      content: "''",
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4,
      display: 'block',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '3px',
      bottom: 0,
      zIndex: 1,
    },

    '&$required&$value::before': {
      background: palette.success.main,
    },

    '&$required&$error::before': {
      background: palette.error.main,
    },

    width: '100%',
  },
  error: {},
  required: {},
  value: {},
}));

export const useInputClasses = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    borderColor: theme.palette.grey[500],
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 48,
    justifyContent: 'flex-end',
    position: 'relative',
    transition: theme.transitions.create(['border', 'color']),

    '&$error': {
      borderColor: theme.palette.error.main,
    },

    '&$focused': {
      borderColor: theme.palette.primary.main,
    },

    '&$focused, &:hover': {
      background: theme.palette.common.white,
    },

    '&$disabled': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.text.primary,
    },
  },
  input: {
    boxSizing: 'border-box',
    fontSize: 14,
    fontWeight: 600,
    height: 46,
    padding: '16px 16px 0',
  },
  disabled: {},
  error: {},
  focused: {},
  multiline: {
    height: 'auto',
    paddingBottom: 4,
    paddingLeft: 0,
    paddingTop: 24,
  },
  inputMultiline: {
    height: 'auto',
    paddingTop: '0 !important',
  },
  adornedEnd: {
    paddingRight: '8px !important',
  },
}));

export const useLabelClasses = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    transform: 'translate3d(16px, 16px, 0)',
    fontSize: 14,
    lineHeight: 16 / 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%',
    paddingRight: theme.spacing(3),
    transition: theme.transitions.create(['color', 'transform', 'width']),
    boxSizing: 'border-box',

    '&$focused, &$shrink': {
      width: '100%',
      color: theme.palette.text.secondary,
      transform: 'translate3d(16px, 8px, 0) scale(0.85714)',
    },

    '&$disabled': {
      color: theme.palette.text.secondary,
    },
    '&$error': {
      color: theme.palette.error.main,
    },
  },

  focused: {},
  shrink: {},
  error: {},
  disabled: {},
}));

export const useStyles = makeStyles(() => ({
  endAdornment: {
    display: 'flex',
    alignItems: 'center',
  },
}));
