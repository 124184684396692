import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import format from 'date-fns/format';
import React, { useCallback } from 'react';

import Icon from '../Icon/Icon';
import SignatureStyles, { SignatureAttributeStyles } from './Signature.styles';
import { SignatureViewDirection } from './Signature.types';

import type { Certificate } from './Signature.types';

export type SignatureProps = {
  certificate: Certificate;
  direction?: SignatureViewDirection;
  disableBackground?: boolean;
  disableBorderRadius?: boolean;
} & React.ComponentProps<'div'> &
  WithStyles<typeof SignatureStyles>;

const Signature: React.FC<SignatureProps> = ({
  certificate,
  classes,
  className,
  direction = SignatureViewDirection.horizontal,
  disableBackground,
  disableBorderRadius,
  ...rest
}) => {
  const renderExpirationDate = useCallback(
    () => (
      <>
        с&nbsp;{format(new Date(certificate.validFromDate), 'dd.MM.yyyy')}
        &nbsp;до&nbsp;
        {format(new Date(certificate.validToDate), 'dd.MM.yyyy')}
      </>
    ),
    [certificate]
  );

  return (
    <div
      className={clsx(
        classes.root,
        classes[direction],
        {
          [classes.disableBackground]: disableBackground,
          [classes.disableBorderRadius]: disableBorderRadius,
          [classes.error]: !certificate.valid,
        },
        className
      )}
      {...rest}
    >
      <div className={classes.thumbprint}>
        <div className={clsx(classes.icon)}>
          <Icon name={certificate.valid ? 'success' : 'attention'} />
        </div>
        <div className={classes.serial}>{certificate.serialNumber}</div>
      </div>
      <div className={classes.rows}>
        <div className={classes.row}>
          <SignatureAttribute label="Владелец" value={certificate.subject.CN} />
          <SignatureAttribute label="ИНН" value={certificate.subject.INN} />
        </div>

        <div className={classes.row}>
          <SignatureAttribute label="Выдан" value={certificate.issuer.CN} />
          <SignatureAttribute
            label="Действителен"
            value={renderExpirationDate()}
          />
        </div>
      </div>
    </div>
  );
};

type SignatureAttributeProps = {
  label: React.ReactNode;
  value: React.ReactNode;
} & React.ComponentProps<'div'> &
  WithStyles<typeof SignatureAttributeStyles>;

const SignatureAttributeComponent: React.FC<SignatureAttributeProps> = ({
  classes,
  className,
  label,
  value,
  ...rest
}) => (
  <div className={clsx(classes.root, className)} {...rest}>
    {label}:&nbsp;<span className={classes.value}>{value}</span>
  </div>
);

const SignatureAttribute = withStyles(SignatureAttributeStyles)(
  SignatureAttributeComponent
);

export default withStyles(SignatureStyles)(Signature);
