import { Table as MuiTable, tableClasses, TableProps } from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Table = styled(MuiTable)<TableProps>(({ theme }) => ({
  [`&.${tableClasses.root}`]: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey['5'],
  },
}));

Table.defaultProps = {
  theme: defaultTheme,
};

export default Table;
