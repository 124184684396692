import React, { useMemo } from 'react';
import {
  ButtonBase,
  CircularProgress,
  InputAdornment as MuiInputAdornment,
  withStyles,
  withTheme,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

export const InputAdornment = withStyles({
  root: {
    zIndex: 1,
    '& > * + *': {
      marginLeft: 8,
    },
  },
})(MuiInputAdornment);

const withTooltip =
  (Component: any) =>
  ({ title }: any) =>
    (
      <Tooltip
        arrow
        disableHoverListener={isEmpty(title)}
        placement="bottom"
        title={
          Array.isArray(title) ? title.toString().replace(',', '\t\n') : title
        }
      >
        <div
          style={{
            cursor: 'help',
          }}
        >
          <Component />
        </div>
      </Tooltip>
    );

const Error = withTooltip(
  withTheme(({ theme }: any) => (
    <Icon
      name="Error"
      style={{
        color: theme.palette.error.main,
      }}
    />
  ))
);

const Hint = withTooltip(
  withTheme(({ theme }: any) => (
    <Icon
      name="Information"
      style={{
        color: theme.palette.secondary.main,
      }}
    />
  ))
);

interface Props {
  InputProps: { endAdornment: React.ReactElement };
  isControlled: boolean;
  value: string;
  clearable: boolean;
  canClear: boolean;
  handleChange: (event: any, value: any) => void;
  loading: boolean;
  error: any;
  hint: any;
}

export const EndAdornment: React.FC<Props> = ({
  InputProps,
  isControlled,
  value,
  clearable,
  canClear,
  handleChange,
  loading,
  error,
  hint,
}) => {
  const adornments = [];

  const isClear = useMemo(() => {
    if (!isControlled) {
      return canClear;
    }

    return Array.isArray(value) ? value.length > 0 : Boolean(value);
  }, [canClear, isControlled, value]);

  if (clearable && isClear) {
    adornments.push(
      <ButtonBase
        disableRipple
        disableTouchRipple
        key="clear"
        onClick={(event) => {
          handleChange(
            {
              ...event,
              target: {
                ...event.target,
                value: '',
              },
            },
            ''
          );
        }}
      >
        <Icon name="close" />
      </ButtonBase>
    );
  }

  if (loading) {
    adornments.push(
      <div
        style={{
          padding: 4,
        }}
      >
        <CircularProgress color="inherit" key="progress" size={16} />
      </div>
    );
  }

  if (error) {
    if (Array.isArray(error)) {
      error = error.join('\n\n');
    }

    adornments.push(<Error key="error" title={error} />);
  } else if (hint) {
    adornments.push(<Hint key="hint" title={hint} />);
  }

  if (InputProps && InputProps.endAdornment) {
    adornments.push(
      React.cloneElement(InputProps.endAdornment, {
        key: 'endAdornment',
      })
    );
  }

  return adornments.length ? (
    <InputAdornment position="end">{adornments}</InputAdornment>
  ) : null;
};
