import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@material-ui/core';
import React, { FC, forwardRef } from 'react';

export interface TypographyProps extends MuiTypographyProps {}

export const Typography: FC<TypographyProps> = forwardRef(({
  variant = 'body1',
  ...rest
}, ref) => {
  return <MuiTypography variant={variant} {...rest} ref={ref} />;
});

export default Typography;
