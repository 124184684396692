import {
  ColumnListItem,
  ColumnOrderableTableInstance,
} from '../TableConfig.types';
import React, { useCallback, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ColumnListDragLayer } from './ColumnListDragLayer';
import { DraggableColumnListItem } from './DraggableColumnListItem';
import {
  Box,
  BoxProps,
  Button,
  defaultTheme,
  Icons,
  Link,
  styled,
} from '@crpt/material';
import { isColumnConfigurable } from '../TableConfig.tools';

interface TableConfigFormProps<T extends Record<string, any>> extends BoxProps {
  title: string;
  description: string;
  applyLabel: string;
  resetLabel: string;
  tableInstance: ColumnOrderableTableInstance<T>;
  disableVisibilityToggle?: (string | number)[];
  onReset?: () => void;
  onClose: () => void;
  onApply: (config: ColumnListItem<T>[]) => void;
}

export function TableConfigForm<T extends Record<string, any>>({
  title,
  description,
  applyLabel,
  resetLabel,
  tableInstance,
  disableVisibilityToggle,
  onClose,
  onApply,
  onReset,
  ...boxProps
}: TableConfigFormProps<T>) {
  const { t: translation } = useTranslation<'translation'>('translation');
  const columns = useMemo(() => {
    return tableInstance.allColumns.filter(isColumnConfigurable);
  }, [tableInstance.allColumns]);

  const columnRegistry = useMemo(
    () => Object.fromEntries(columns.map((column) => [column.id, column])),
    [columns]
  );

  const initial = useMemo(() => {
    return {
      order: columns
        .filter((column) => !column.disableConfig)
        .map((column) => column.id),
      visibility: Object.fromEntries(
        columns.map((column) => [column.id, column.isVisible])
      ),
    };
  }, [columns]);

  const [order, setOrder] = useState<(string | number)[]>(initial.order);
  const [visibility, setVisibility] = useState(initial.visibility);

  const onMove = useCallback(
    (draggedId: string | number, afterId: string | number) => {
      const newIndex = order.indexOf(afterId);
      const newOrder = order.filter((columnId) => columnId !== draggedId);
      newOrder.splice(newIndex, 0, draggedId);
      setOrder(newOrder);
    },
    [order]
  );

  const onVisibilityToggle = useCallback(
    (columnId: string | number, isVisible?: boolean) => {
      const newVisibility = {
        ...visibility,
        [columnId]: isVisible ?? !visibility[columnId],
      };
      setVisibility(newVisibility);
    },
    [visibility]
  );

  const config = useMemo(() => {
    return order.map<ColumnListItem<T>>((columnId, index) => ({
      column: columnRegistry[columnId],
      isVisible: visibility[columnId],
      visibilityToggle: !disableVisibilityToggle?.includes(columnId),
      order: index,
    }));
  }, [order, visibility, columnRegistry, disableVisibilityToggle]);

  const apply = useCallback(() => {
    onApply(config);
  }, [config]);

  return (
    <ConfigFormRoot {...boxProps}>
      <div className="ConfigForm-Header">
        <div className="Header-Title">{translation(title)}</div>
        <Link className="Header-Close" onClick={onClose}>
          <Icons.Close_large />
        </Link>
        <div className="Header-Description">{translation(description)}</div>
      </div>
      <div className="ConfigForm-ColumnList">
        <DndProvider backend={HTML5Backend}>
          <ColumnListDragLayer />
          {config.map((columnConfig) => (
            <DraggableColumnListItem
              visibilityToggle={columnConfig.visibilityToggle}
              item={columnConfig}
              key={columnConfig.column.id}
              onMove={onMove}
              onVisibilityToggle={onVisibilityToggle}
            />
          ))}
        </DndProvider>
      </div>
      <div className="ConfirmForm-Footer">
        <Button
          color="primary"
          style={{
            border: '1px solid #67ac5c',
            background: '#67ac5c',
            color: '#ffffff',
          }}
          onClick={apply}
        >
          {translation(applyLabel)}
        </Button>
        {onReset && (
          <Button color="secondary" onClick={onReset}>
            {translation(resetLabel)}
          </Button>
        )}
      </div>
    </ConfigFormRoot>
  );
}

const ConfigFormRoot = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  backgroundColor: theme.palette.grey['5'],
  width: 648,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  '.ConfigForm-Header': {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: 20,
    paddingBottom: 12,
    paddingLeft: 20,
    paddingRight: 20,
    '.Header-Title': {
      ...theme.typography.h3,
      flex: '1 1 0',
    },
    '.Header-Close': {
      cursor: 'pointer',
    },
    '.Header-Description': {
      ...theme.typography.p1,
      marginTop: '4px',
      width: '100%',
      flex: '0 0 auto',
    },
  },
  '.ConfigForm-ColumnList': {
    position: 'relative',
    overflowY: 'scroll',
    overflowX: 'none',
    flex: '1 1 auto',
    paddingLeft: 20,

    '&::-webkit-scrollbar': {
      width: 20,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey['5'],
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey['60'],
      borderLeft: `8px solid ${theme.palette.grey['5']}`,
      borderRight: `8px solid ${theme.palette.grey['5']}`,
    },
  },
  '.ConfigForm-Config': {
    backgroundColor: theme.palette.grey['5'],
    userSelect: 'none',
    display: 'flex',
    padding: '8px 0',
    gap: 8,
    '.Config-DragHandle': {
      color: theme.palette.grey['60'],
      flex: '0 0 auto',
      cursor: 'grab',
    },
    '.Config-ColumHeader': {
      ...theme.typography.p2,
      color: theme.palette.grey['100'],
      flex: '1 1 auto',
      '&.--invisible': {
        color: theme.palette.grey['60'],
      },
    },
    '.Config-Visibility': {
      color: theme.palette.grey['60'],
      flex: '0 0 auto',
      cursor: 'pointer',
    },
  },
  '.ConfirmForm-Footer': {
    flex: '0 0 auto',
    paddingTop: 16,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,

    gap: 20,
    display: 'flex',
  },
}));

ConfigFormRoot.defaultProps = {
  theme: defaultTheme,
};
