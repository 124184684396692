import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    height: 24,
    padding: 0,
    width: 40,
  },
  switchBase: {
    padding: 2,

    '&$checked': {
      transform: 'translateX(16px)',

      '& + $track': {
        background: theme.palette.text.secondary,
        borderColor: theme.palette.text.secondary,
        opacity: 1,
      },
    },
  },
  thumb: {
    boxShadow: 'none',
  },
  colorPrimary: {
    color: theme.palette.text.secondary,
  },
  colorSecondary: {
    color: theme.palette.text.secondary,
  },
  track: {
    background: 'none',
    borderColor: theme.palette.grey[500],
    borderRadius: 12,
    borderStyle: 'solid',
    borderWidth: 1,
    boxSizing: 'border-box',
    opacity: 1,
  },
  checked: {},
}));
