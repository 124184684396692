import {
  ToggleButton as MuiToggleButton,
  toggleButtonClasses,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  [`&.${toggleButtonClasses.root}`]: {
    ...theme.typography.p2,
    color: theme.palette.blue['70'],
    textTransform: 'none',
    borderRadius: 7,
    borderColor: theme.palette.grey['30'],
    transition: theme.transitions.create(['background', 'color']),

    [`&:hover`]: {
      color: theme.palette.blue['90'],
      background: theme.palette.grey['0'],
      borderColor: theme.palette.grey['30'],
    },

    [`&.${toggleButtonClasses.selected}`]: {
      color: theme.palette.grey['100'],
      background: theme.palette.grey['30'],
    },
    [`&.${toggleButtonClasses.sizeLarge}`]: {
      minHeight: 52,
      padding: '15px 23px',
    },
    [`&.${toggleButtonClasses.sizeSmall}`]: {
      minHeight: 36,
      padding: '7px 11px',
    }
  },
}));

ToggleButton.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
};

export default ToggleButton;
