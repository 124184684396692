import React, { memo, useCallback, useMemo, useState } from 'react';
import { FilterEditor, FilterRenderProps } from '../../TableFilter';
import { Checkbox, FormControlLabel, Link, Stack } from '@crpt/material';

interface CheckboxGroupOptions<T> {
  title?: React.ReactNode;
  viewLimit?: number;
  theme?: any;
  collapseLabel?: string;
  expandLabel?: string;
  options: { label?: string; name?: string; value: T }[];
}

export function makeCheckboxGroupFilter<T extends string | number>(
  filterOptions?: CheckboxGroupOptions<T>
) {
  const {
    title,
    theme,
    options = [],
    viewLimit = 5,
    collapseLabel = 'Свернуть',
    expandLabel = 'Развернуть',
  } = filterOptions || {};
  return memo((props: FilterRenderProps<T[]>) => {
    const {
      column,
      filterValue = [],
      setFilterValue,
      executeValidation,
    } = props;
    const isCollapsible = options.length > viewLimit;
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    const onChange = useCallback(
      (value: T) => (event: React.SyntheticEvent, isChecked: boolean) => {
        const newValue = isChecked
          ? filterValue.concat(value)
          : filterValue.filter((item) => item !== value);
        setFilterValue(newValue);
        executeValidation();
      },
      [executeValidation, filterValue, setFilterValue]
    );

    const visibleOptions = useMemo(() => {
      return isCollapsible && isCollapsed
        ? options.slice(0, viewLimit)
        : options;
    }, [isCollapsible, isCollapsed]);

    return (
      <FilterEditor column={column} title={title}>
        <Stack gap="16px" sx={{paddingTop: '8px'}}>
          {visibleOptions.map((option) => (
            <FormControlLabel
              name={column.id}
              theme={theme}
              checked={filterValue.includes(option.value)}
              onChange={onChange(option.value)}
              control={<Checkbox />}
              key={option.value}
              label={option.label || option.name || option.value}
              sx={{ color: '#181F38' }}
            />
          ))}
          {isCollapsible && (
            <Link
              onClick={() => setIsCollapsed((value) => !value)}
              sx={{ fontSize: 14 }}
            >
              {isCollapsed ? expandLabel : collapseLabel}
            </Link>
          )}
        </Stack>
      </FilterEditor>
    );
  });
}
