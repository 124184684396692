import { Tab, TabProps, withStyles } from '@material-ui/core';
import React, { FC } from 'react';

import StepStyles from './Step.styles';

const Step: FC<TabProps> = ({
  disableFocusRipple = true,
  disableRipple = true,
  disableTouchRipple = true,
  ...rest
}) => (
  <Tab
    disableFocusRipple={disableFocusRipple}
    disableRipple={disableRipple}
    disableTouchRipple={disableTouchRipple}
    {...rest}
  />
);

export default withStyles(StepStyles, {
  name: 'Step',
})(Step);
