import { createStyles } from '@material-ui/core';

export default createStyles({
  root: {
    '&:first-child': {
      paddingTop: 32,
    },
    boxSizing: 'border-box',
    padding: 32,
  },
});
