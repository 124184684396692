import React, { memo } from 'react';
import {
  FilterEditor,
  FilterRenderProps,
  FilterValidationHandler,
} from '../../TableFilter';
import { Link, MenuItem, TextField } from '@crpt/material';

export interface SelectFilterOptions<T> {
  title?: React.ReactNode;
  helperText?: string;
  label?: string;
  theme?: any;
  placeholder?: string;
  options: { label?: string; name?: string; value: T }[];
  validator?: FilterValidationHandler<T>;
  disableReset?: boolean;
  resetText?: string;
  onChangeValue?: (v: string | null) => void;
}

export function makeSelectFilter<T extends string | number>(
  filterOptions?: SelectFilterOptions<T>
) {
  const {
    title,
    helperText,
    label,
    placeholder,
    validator,
    options = [],
    disableReset,
    resetText = 'Сбросить фильтр',
    theme,
    onChangeValue,
  } = filterOptions || {};
  return memo((props: FilterRenderProps<T>) => {
    const {
      column,
      filterValue,
      setFilterValue,
      useValidator,
      executeValidation,
    } = props;
    const { error } = useValidator(validator);

    const renderResetMenuItem = () => {
      return filterValue ? (
        <MenuItem
          value=""
          divider
          theme={theme}
          sx={{ '&:hover': { background: 'unset' }, padding: 0 }}
        >
          <Link sx={{ width: '100%', padding: '12px 16px' }}>{resetText}</Link>
        </MenuItem>
      ) : (
        <MenuItem theme={theme} divider disabled>
          {resetText}
        </MenuItem>
      );
    };

    return (
      <FilterEditor column={column} error={error} title={title}>
        <TextField
          name={column.id}
          helperText={helperText}
          label={label}
          onChange={(value) => {
            setFilterValue(value);
            executeValidation();
            onChangeValue && onChangeValue(value.target.value);
          }}
          select
          value={filterValue || ''}
          error={Boolean(error)}
          placeholder={placeholder}
        >
          {!disableReset && renderResetMenuItem()}
          {options.map((option, index) => (
            <MenuItem theme={theme} key={index} value={option.value}>
              {option.label || option.name || option.value}
            </MenuItem>
          ))}
        </TextField>
      </FilterEditor>
    );
  });
}
