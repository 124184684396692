import { createStyled } from '@mui/system';
import { createTheme, Theme, ThemeOptions } from '@mui/material';
import './font/Inter.css';

export const crptPalette = {
  grey: {
    100: '#181f38',
    90: '#4b5168',
    70: '#555d67',
    60: '#7a819b',
    50: '#8da8c9',
    40: '#c9d6e2',
    30: '#dce6f0',
    20: '#e9eff5',
    10: '#eeeff0',
    '05': '#f3f6f9',
    5: '#f3f6f9',
    '00': '#ffffff',
    0: '#ffffff',
  },
  blue: {
    100: '#144ea6',
    90: '#2866c3',
    70: '#3474d3',
  },
  yellow: {
    100: '#cecc21',
    60: '#ebe81c',
    40: '#f6f42e',
    10: '#f7f5ac',
  },
  green: {
    70: '#009933',
    60: '#00CC66',
    20: '#CBFFDD',
  },
  orange: {
    70: '#f08d1b',
    20: '#ffebcc',
  },
  red: {
    70: '#f34747',
    60: '#ff7474',
    20: '#ffe4e4',
  },
};

const slimScrollBar = {
  '::-webkit-scrollbar': {
    width: 12,
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: crptPalette.grey['30'],
    border: `4px solid ${crptPalette.grey['00']}`,
    borderRadius: 8,
  },
};

const fontFamily = 'Inter, sans-serif';

const resetFontStyles = {
  fontFamily,
  fontWeight: 'unset',
  fontSize: 'unset',
  letterSpacing: 'normal',
  lineHeight: 'unset',
};

const defaultThemeOptions: ThemeOptions = {
  palette: {
    background: {
      default: crptPalette.grey['5'],
    },
    common: {
      black: crptPalette.grey['100'],
      white: crptPalette.grey['0'],
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          ...slimScrollBar,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
        listbox: {
          padding: 0,
          ...slimScrollBar,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
          ...slimScrollBar,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          pointerEvents: 'none',
          userSelect: 'none',
        },
      },
    },
  },
  spacing: 8,
  typography: {
    htmlFontSize: 14,
    fontFamily,
    fontSize: 14,
    body1: {
      ...resetFontStyles,
    },
    body2: {
      ...resetFontStyles,
    },
    subtitle1: {
      ...resetFontStyles,
    },
    subtitle2: {
      ...resetFontStyles,
    },
    caption: {
      ...resetFontStyles,
    },
    button: {
      ...resetFontStyles,
    },
    overline: {
      ...resetFontStyles,
    },
    h1: {
      fontFamily,
      textTransform: 'none',
      fontSize: 32,
      fontWeight: 500,
      lineHeight: '40px',
      letterSpacing: 'normal',
    },
    h2: {
      fontFamily,
      textTransform: 'none',
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily,
      textTransform: 'none',
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    h4: {
      fontFamily,
      textTransform: 'none',
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    h5: {
      fontFamily,
      textTransform: 'none',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    h6: {
      fontFamily,
      textTransform: 'none',
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: 'normal',
    },
  },
};

const crptThemeOptions = {
  palette: crptPalette,
  shadows: [
    'none',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)', // Popup shadow
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)', // Elevation shadow
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
    '0px 8px 20px rgba(0, 0, 0, 0.08)',
  ],
  typography: {
    p1: {
      fontFamily,
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
      letterSpacing: 'normal',
    },
    p2: {
      fontFamily,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: 'normal',
    },
    p3: {
      fontFamily,
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      letterSpacing: 'normal',
    },
  },
  sizes: {
    maxDropdownListSize: 320,
  }
};

export type CrptTheme = Theme & typeof crptThemeOptions;

export const defaultTheme = createTheme(
  defaultThemeOptions,
  crptThemeOptions
) as CrptTheme;

export const styled = createStyled<CrptTheme>({ defaultTheme });
export const useTheme = () => defaultTheme;
