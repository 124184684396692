import React from 'react';
import {
  AccordionSummary as MuiAccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';
import { Icons } from '..';

export const AccordionSummary = styled(
  MuiAccordionSummary
)<AccordionSummaryProps>(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: '19px 20px 20px 20px',
  gap: 16,
  backgroundColor: theme.palette.grey['00'],
  minHeight: 64,
  borderTop: `1px solid`,
  borderColor: theme.palette.grey['00'],

  ':hover': {
    borderColor: theme.palette.grey['20'],
    backgroundColor: theme.palette.grey['20'],
  },

  [`.${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(90deg)',
    },
  [`.${accordionSummaryClasses.content}`]: {
    margin: 0,
    ...theme.typography.h4,
  },
}));

AccordionSummary.defaultProps = {
  theme: defaultTheme,
  expandIcon: (
    <Icons.ChevronRight_large color={defaultTheme.palette.blue['70']} />
  ),
};
