import React, { useMemo } from 'react';
import Button from '../../../Button/Button';
import Icon from '../../../Icon/Icon';
import { Time } from '../../TimePicker.types';
import { getTimeTitle } from '../../utils';

import TimePickerHeaderStyles from './TimePickerHeader.styles';
import { WithStyles, withStyles } from '@material-ui/core';

type TimePickerHeaderProps = {
  isFilter: boolean;
  time?: Time;
  onClose: () => void;
} & WithStyles<typeof TimePickerHeaderStyles>;

const TimePickerHeaderComponent: React.FC<TimePickerHeaderProps> = ({
  isFilter,
  time,
  onClose,
  classes,
}) => {
  const headerTitle = useMemo(() => {
    return getTimeTitle(time);
  }, [time]);
  return (
    <div>
      <div className={classes.headerWrapper}>
        <span>{headerTitle}</span>
        {!isFilter && (
          <Button
            variant="text"
            size="sm"
            onClick={onClose}
            icon={<Icon name="close" />}
          />
        )}
      </div>
    </div>
  );
};

export const TimePickerHeader = withStyles(TimePickerHeaderStyles)(
  TimePickerHeaderComponent
);
