
import * as Icons from '@crpt-ui/icons';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  withStyles,
} from '@material-ui/core';
import React, { Fragment, useCallback, useRef, useState } from 'react';

import CrptuiButton from '../Button/Button';

const Button = withStyles((theme) => ({
  root: {
    '&$sizeMd': {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },

    '&$active': {
      background: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.15)',
      color: theme.palette.text.primary,
    },
  },
  active: {},
  sizeLg: {},
  sizeMd: {},
  sizeSm: {},
}))(CrptuiButton);

const Menu = withStyles((theme) => ({
  paper: {
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.125)',
    borderRadius: 4,
    maxHeight: 40 * 5 + 16,

    '&::-webkit-scrollbar': {
      width: 4,
    },

    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey[400],
    },

    '&::-webkit-scrollbar-thumb': {
      width: 2,
      borderRadius: 2,
      background: theme.palette.text.primary,
    },
  },
  list: {
    paddingTop: 8,
    paddingBottom: 8,
  },
}))(MuiMenu);

const MenuItem = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 16 / 14,
    minHeight: 40,
    padding: '8px 16px',

    '&:hover, &:focus': {
      background: theme.palette.grey[300],
    },

    '&$selected, &$selected:hover': {
      background: theme.palette.primary.main,
    },
  },
  selected: {},
}))(MuiMenuItem);

const MenuButton = ({
  children,
  items = [],
  disableChevron,
  endIcon,
  paperProps,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderMenuItem = useCallback(
    ({ label, onClick, icon, ...rest }) => (
      <MenuItem
        key={label}
        onClick={(event) => {
          if (typeof onClick === 'function') {
            onClick(event);
          }
          handleClose();
        }}
        disableRipple
        disableTouchRipple
        {...rest}
      >
        {icon ? <span style={{ marginRight: 8 }}>{icon}</span> : null}
        {label}
      </MenuItem>
    ),
    []
  );

  const renderMenuItems = useCallback(
    () =>
      items.map((item) =>
        React.isValidElement(item) ? item : renderMenuItem(item)
      ),
    [items]
  );

  return (
    <Fragment>
      <Button
        active={Boolean(anchorEl)}
        onClick={handleClick}
        ref={buttonRef}
        endIcon={
          disableChevron ? (
            endIcon
          ) : (
            <span
              style={{
                transform: anchorEl ? 'rotate(180deg)' : undefined,
              }}
            >
              <Icons.Chevron />
            </span>
          )
        }
        {...rest}
      >
        {children}
      </Button>
      <Menu
        PaperProps={{
          style: {
            minWidth: buttonRef.current
              ? buttonRef.current.offsetWidth
              : undefined,
          },
          ...paperProps,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: -4 }}
        disableAutoFocusItem
        getContentAnchorEl={null}
        marginThreshold={0}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transitionDuration={0}
      >
        {renderMenuItems()}
      </Menu>
    </Fragment>
  );
};

export default MenuButton;
