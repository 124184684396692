import { makeStyles, Theme } from '@material-ui/core';

import { getColor, getContrastColor } from '../utils';

export default makeStyles(
  (theme: Theme) => ({
    root: {
      background: (props: any) => getColor(props.color, theme),
      borderRadius: 4,
      color: (props: any) => getContrastColor(props.color, theme),
      padding: '16px 40px 16px 16px',
      position: 'relative',

      '&$disableBorderRadius': {
        borderRadius: 0,
      },

      '&$disableClose': {
        padding: 16,
      },
    },
    close: {
      color: 'inherit',
      position: 'absolute',
      right: 8,
      top: 8,
    },
    disableBorderRadius: {},
    disableClose: {},
  }),
  {
    name: 'Notification',
  }
);
