import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core';
import React, { FC } from 'react';

import useClasses from './Tooltip.styles';

export interface TooltipProps extends MuiTooltipProps {
  maxWidth?: number;
}

export const Tooltip: FC<TooltipProps> = ({
  arrow = true,
  children,
  maxWidth = 272,
  ...rest
}) => {
  const classes = useClasses({ maxWidth });

  return (
    <MuiTooltip arrow={arrow} classes={classes} {...rest}>
      {children}
    </MuiTooltip>
  );
};
export default Tooltip;
