import React, { memo, useMemo, useCallback } from 'react';
import Button from '../../../Button/Button';
import Icon from '../../../Icon/Icon';
import TimePickerFooterActionStyles from './TimePickerFooter.styles';
import { WithStyles, withStyles } from '@material-ui/core';
import { isTimeValid } from '../../utils';
import { Time } from '../../TimePicker.types';

type TimePickerFooterProps = {
  onSubmit: (value: Time) => void;
  onReset: () => void;
  currentTimeValue: Time;
};

export const TimePickerFooter: React.FC<TimePickerFooterProps> = memo(
  ({ onSubmit, onReset, currentTimeValue }) => {
    const isActionButtonDisabled = useMemo(() => {
      return !isTimeValid(currentTimeValue);
    }, [currentTimeValue]);

    const submitData = useCallback(() => {
      onSubmit(currentTimeValue);
    }, [currentTimeValue]);

    const resetData = useCallback(() => {
      onReset();
    }, []);
    return (
      <MemoizedActionButtons
        resetData={resetData}
        isButtonsDisabled={isActionButtonDisabled}
        submitData={submitData}
      />
    );
  }
);

type MemoizedActionButtonsProps = {
  isButtonsDisabled: boolean;
  submitData: () => void;
  resetData: () => void;
} & WithStyles<typeof TimePickerFooterActionStyles>;
const MemoizedActionButtonsComponent: React.FC<MemoizedActionButtonsProps> = ({
  isButtonsDisabled,
  submitData,
  resetData,
  classes,
}) => {
  return (
    <div className={classes.wrapper}>
      <Button
        onClick={submitData}
        disabled={isButtonsDisabled}
        className={classes.submitButton}
        size="md"
        variant="contained"
      >
        Применить
      </Button>
      <Button
        onClick={resetData}
        disabled={isButtonsDisabled}
        size="md"
        variant="outlined"
        icon={<Icon name="trash" />}
      />
    </div>
  );
};

const MemoizedActionButtons = withStyles(TimePickerFooterActionStyles)(
  MemoizedActionButtonsComponent
);
