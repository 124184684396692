import { Time, TimeType } from '../TimePicker.types';

const checkTimeValueWithLimits = (
  value: number,
  minimalValue: number,
  maxValue: number
): boolean => {
  return value >= minimalValue && value <= maxValue;
};

const isHoursValid = (value: string): boolean => {
  if (value === '') return false;
  return checkTimeValueWithLimits(Number(value), 0, 23);
};

const isMinutesAndSecondsValid = (value: string): boolean => {
  if (value === '') return false;
  return checkTimeValueWithLimits(Number(value), 0, 59);
};

const isTimePartValid = (timeValue: string, type: TimeType): boolean => {
  switch (type) {
    case TimeType.hours:
      return isHoursValid(timeValue);
    default:
      return isMinutesAndSecondsValid(timeValue);
  }
};

export const isTimeValid = (time: Time): boolean => {
  return Array.from(Object.entries(time)).every((item) => {
    const [type, timeValue] = item;
    return isTimePartValid(timeValue, type as TimeType);
  });
};
