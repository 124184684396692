import { Checkbox, createStyles, Theme, withStyles } from '@material-ui/core';

export default withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      color: theme.palette.grey[500],
      userSelect: 'none',

      '&.Mui-disabled': {
        color: theme.palette.grey[500],
      },

      '&:hover': {
        background: 'transparent',
      },
    },
    indeterminate: {
      color: theme.palette.text.primary,
    },
  })
)(Checkbox);
