import { Radio as MuiRadio, RadioProps } from '@mui/material';
import React from 'react';
import { defaultTheme, styled } from '../theme';

const Default = styled('span')<RadioProps>(({ theme }) => ({
  width: 19,
  height: 19,
  borderRadius: '50%',
  position: 'relative',

  transition: theme.transitions.create(['background', 'border']),

  'input:disabled ~ &': {
    background: theme.palette.grey['30'],
    border: `1px solid ${theme.palette.grey['30']}`,
  },
}));

Default.defaultProps = {
  theme: defaultTheme,
};

const Icon = styled(Default)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey['60']}`,

  'input:not(:disabled):hover ~ &': {
    borderColor: theme.palette.grey['100'],
  },
  '.MuiRadio-colorError input ~ &': {
    borderColor: theme.palette.red['70'],
  },
}));

Icon.defaultProps = {
  theme: defaultTheme,
};

const CheckedIcon = styled(Default)(({ theme }) => ({
  border: `1px solid ${theme.palette.yellow['40']}`,
  background: theme.palette.yellow['40'],

  'input:not(:disabled):hover ~ &': {
    borderColor: theme.palette.yellow['60'],
    background: theme.palette.yellow['60'],
  },

  '&::before': {
    content: '""',
    borderRadius: '50%',
    position: 'absolute',
    background: theme.palette.grey['100'],
    width: 10,
    height: 10,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    'input:disabled ~ &': {
      background: theme.palette.grey['60'],
    },
  },
}));

CheckedIcon.defaultProps = {
  theme: defaultTheme,
};

const Radio = styled(MuiRadio)({
  padding: 0,

  '&:hover': {
    background: 'transparent',
  },
});

Radio.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
  icon: <Icon />,
  checkedIcon: <CheckedIcon />,
};

export default Radio;
