/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon } from '@crpt-ui/core';
import clsx from 'clsx';
import React, { ComponentProps, FC, ReactNode } from 'react';

import useClasses from './Status.styles';
import { useResolved } from './Status.utils';

export type StatusProps = {
  /**
   * The color of the component.
   */
  color?:
    | 'error'
    | 'grey'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'textPrimary'
    | 'textSecondary'
    | 'warning';
  colors?: {
    [key: string]: StatusProps['color'];
  };
  icon?: ReactNode;
  icons?: {
    [key: string]: ReactNode;
  };
  label?: ReactNode;
  labels?: {
    [key: string]: ReactNode;
  };
  value?: string;
} & ComponentProps<'div'>;

export const Status: FC<StatusProps> = ({
  className,
  color: defaultColor = 'textSecondary',
  colors,
  icon: defaultIcon = <Icon name="DocumentUndefined" />,
  icons,
  label: defaultLabel = 'Не определено',
  labels,
  value,
  ...rest
}) => {
  const color = useResolved(defaultColor, colors, value);
  const icon = useResolved(defaultIcon, icons, value);
  const label = useResolved(defaultLabel, labels, value);

  const classes = useClasses({
    color: color,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <span className={classes.icon}>{icon}</span>
      <span className={classes.label}>{label}</span>
    </div>
  );
};

export default Status;
