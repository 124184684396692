import { useCallback, useReducer } from 'react';
import { changeTimeValue, handleInputValueChange } from '../utils';
import {
  ChangeTimeType,
  Time,
  TimeActions,
  TimeStateActions,
  TimeType,
} from '../TimePicker.types';

function baseReducer(state: Time, action: TimeStateActions) {
  switch (action.type) {
    case TimeActions.DECREASE_HOURS:
      return {
        ...state,
        hours: changeTimeValue(
          state.hours,
          ChangeTimeType.decrease,
          TimeType.hours
        ),
      };
    case TimeActions.DECREASE_MINUTES:
      return {
        ...state,
        minutes: changeTimeValue(
          state.minutes,
          ChangeTimeType.decrease,
          TimeType.minutes
        ),
      };
    case TimeActions.DECREASE_SECONDS:
      return {
        ...state,
        seconds: changeTimeValue(
          state.seconds,
          ChangeTimeType.decrease,
          TimeType.seconds
        ),
      };
    case TimeActions.INCREASE_SECONDS:
      return {
        ...state,
        seconds: changeTimeValue(
          state.seconds,
          ChangeTimeType.increase,
          TimeType.seconds
        ),
      };
    case TimeActions.INCREASE_HOURS:
      return {
        ...state,
        hours: changeTimeValue(
          state.hours,
          ChangeTimeType.increase,
          TimeType.hours
        ),
      };
    case TimeActions.INCREASE_MINUTES:
      return {
        ...state,
        minutes: changeTimeValue(
          state.minutes,
          ChangeTimeType.increase,
          TimeType.minutes
        ),
      };
    case TimeActions.CHANGE_HOURS:
      return {
        ...state,
        hours: handleInputValueChange(action.value, TimeType.hours),
      };
    case TimeActions.CHANGE_MINUTES:
      return {
        ...state,
        minutes: handleInputValueChange(action.value, TimeType.minutes),
      };
    case TimeActions.CHANGE_SECONDS:
      return {
        ...state,
        seconds: handleInputValueChange(action.value, TimeType.seconds),
      };
    case TimeActions.RESET_VALUES:
      return {
        hours: '',
        minutes: '',
        seconds: '',
      };
    default:
      return state;
  }
}

export function useTime(time?: Time) {
  const initialState = time
    ? time
    : {
        hours: '',
        minutes: '',
        seconds: '',
      };
  const [state, dispatch] = useReducer(baseReducer, initialState);

  const increaseMinutes = useCallback(
    () => dispatch({ type: TimeActions.INCREASE_MINUTES }),
    []
  );
  const increaseHours = useCallback(
    () => dispatch({ type: TimeActions.INCREASE_HOURS }),
    []
  );
  const increaseSeconds = useCallback(
    () => dispatch({ type: TimeActions.INCREASE_SECONDS }),
    []
  );

  const decreaseMinutes = useCallback(
    () => dispatch({ type: TimeActions.DECREASE_MINUTES }),
    []
  );
  const decreaseHours = useCallback(
    () => dispatch({ type: TimeActions.DECREASE_HOURS }),
    []
  );
  const decreaseSeconds = useCallback(
    () => dispatch({ type: TimeActions.DECREASE_SECONDS }),
    []
  );

  const changeHours = useCallback(
    (value) => dispatch({ type: TimeActions.CHANGE_HOURS, value }),
    []
  );
  const changeMinutes = useCallback(
    (value) => dispatch({ type: TimeActions.CHANGE_MINUTES, value }),
    []
  );
  const changeSeconds = useCallback(
    (value) => dispatch({ type: TimeActions.CHANGE_SECONDS, value }),
    []
  );

  const resetValues = useCallback(
    () => dispatch({ type: TimeActions.RESET_VALUES }),
    []
  );

  return {
    state,
    increaseMinutes,
    increaseHours,
    increaseSeconds,
    decreaseSeconds,
    decreaseMinutes,
    decreaseHours,
    changeHours,
    changeMinutes,
    changeSeconds,
    resetValues,
  };
}
