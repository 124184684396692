import { Filters } from 'react-table';
import { FilterableColumnInstance } from './TableFilter.types';

export function columnFiltersToFormValues<T extends Record<string, any>>(
  columnList: FilterableColumnInstance<T>[]
): Record<string, any> {
  const filterValues = columnList.map((col) => [col.id, col.filterValue]);
  return Object.fromEntries(filterValues);
}

export function formValuesToFilterValues(
  formFilters: Record<string, any>
): Filters<Record<string, any>> {
  return Object.entries(formFilters).map(([id, value]) => ({ id, value }));
}
