import {
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import React from 'react';
import { defaultTheme, styled } from '../theme';

const Tooltip = styled(({ className, ...rest }: TooltipProps) => (
  <MuiTooltip
    {...rest}
    classes={{
      popper: className,
    }}
  />
))<TooltipProps>(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...theme.typography.h5,
    color: theme.palette.grey['100'],
    background: theme.palette.grey['0'],
    padding: 12,
    filter: 'drop-shadow(0px 4px 8px #00000008)',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey['0'],
  },
}));

Tooltip.defaultProps = {
  theme: defaultTheme,
  arrow: true,
};

export default Tooltip;
