import { Tabs as MuiTabs, tabsClasses, TabsProps } from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Tabs = styled(MuiTabs)<TabsProps>(({ theme }) => ({
  [`&.${tabsClasses.root}`]: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey['0'],
    paddingLeft: 16,
    paddingRight: 16,
  },
  [`.${tabsClasses.indicator}`]: {
    backgroundColor: theme.palette.yellow['40'],
    borderRadius: 2,
    height: 3,
  },
}));

Tabs.defaultProps = {
  theme: defaultTheme,
};

export default Tabs;
