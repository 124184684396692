import React from 'react';
import { Checkbox } from '@crpt/material';
import {
  CellProps,
  Hooks,
  UseColumnOrderState,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  UseRowStateRowProps,
} from 'react-table';

export const toggleRowSelectionColumnId = 'toggleRowSelection';

type ToggleSelectionCellProps = Omit<CellProps<any>, 'row'> & {
  row: CellProps<any>['row'] &
    UseRowSelectRowProps<any> &
    UseRowStateRowProps<any>;
};

const ToggleSelectionCell = (props: ToggleSelectionCellProps) => {
  const toggleProps = props.row.getToggleRowSelectedProps();
  return (
    <Checkbox
      checked={toggleProps.checked}
      onChange={toggleProps.onChange}
      indeterminate={Boolean(props.row.state && props.row.state.indeterminate)}
      disabled={Boolean(props.row.state && props.row.state.disabled)}
    />
  );
};

type ToggleAllSelectionCellProps = CellProps<any> &
  UseRowSelectInstanceProps<any>;

const ToggleAllSelectionCell = (props: ToggleAllSelectionCellProps) => {
  const toggleProps = props.getToggleAllRowsSelectedProps();
  return (
    <Checkbox
      checked={toggleProps.checked}
      indeterminate={toggleProps.indeterminate}
      onChange={toggleProps.onChange}
    />
  );
};

const toggleSelectionColumn = {
  Cell: ToggleSelectionCell,
  Header: ToggleAllSelectionCell,
  id: toggleRowSelectionColumnId,
  disableFilters: true,
  minWidth: 36,
  maxWidth: 36,
};

export const useSelectRowColumn = <T extends Record<string, any>>(
  hooks: Hooks<T>
) => {
  hooks.visibleColumns.push((columns) => {
    return [toggleSelectionColumn, ...columns];
  });
  hooks.visibleColumnsDeps.push((deps, { instance }) => {
    const state = instance.state as UseColumnOrderState<any>;
    return [...deps, state.columnOrder];
  });
};
