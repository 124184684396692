import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isArray, get } from 'lodash';

import * as Icons from '@crpt-ui/icons';
import { MenuItem as MuiMenuItem, withStyles } from '@material-ui/core';
import Input from '../Input/Input';
import { useClasses, useMenuClasses } from './Select.styles';

const MenuItem = withStyles((theme) => ({
  root: {
    fontSize: 14,
    lineHeight: 16 / 14,
    minHeight: 40,
    padding: '8px 16px',

    '&:hover, &:focus': {
      background: '#f7f7f7',
    },

    '&$selected, &$selected:hover': {
      background: theme.palette.primary.main,
    },
  },
  selected: {},
}))(MuiMenuItem);

const Select = ({
  options,
  multiple,
  value,
  disableAutoFocusItem = true,
  SelectProps,
  menuItemProps,
  ...rest
}) => {
  const classes = useClasses();
  const classesMenu = useMenuClasses();
  const checkedValue = multiple && !isArray(value) ? [] : value;
  const selectMenuProps = useMemo(
    () => get(SelectProps, 'MenuProps', {}),
    [SelectProps]
  );

  return (
    <Input
      {...rest}
      value={checkedValue}
      select
      SelectProps={{
        classes,
        ...SelectProps,
        multiple,
        IconComponent: Icons.Chevron,
        MenuProps: {
          classes: classesMenu,
          ...selectMenuProps,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          disableAutoFocusItem,
          marginThreshold: 0,
          transformOrigin: {
            vertical: -4,
            horizontal: 1,
          },
          getContentAnchorEl: null,
          transitionDuration: 0,
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          disableRipple
          disableTouchRipple
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          {...menuItemProps}
        >
          {option.label}
        </MenuItem>
      ))}
    </Input>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  disableAutoFocusItem: PropTypes.bool,
  SelectProps: PropTypes.object,
};

Select.defaultProps = {
  options: [],
  disableAutoFocusItem: false,
  SelectProps: {},
};

export default React.memo(Select);
