import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentProps, FC } from 'react';

import Icon from '../Icon/Icon';
import RequiredFieldsWarningStyles from './RequiredFieldsWarning.styles';

export type RequiredFieldsWarningProps = {
  error?: boolean;
} & ComponentProps<'div'> &
  WithStyles<typeof RequiredFieldsWarningStyles>;

const RequiredFieldsWarning: FC<RequiredFieldsWarningProps> = ({
  classes,
  className,
  error = true,
  ...rest
}) => (
  <div className={clsx(classes.root, className)} {...rest}>
    {error ? (
      <div>
        <Icon name="error" className={clsx(classes.icon, classes.error)} />
        <span className={classes.error}>Красным</span>
        &nbsp;отмечены обязательные для заполнения поля
      </div>
    ) : (
      <div>
        <Icon name="success" className={clsx(classes.icon, classes.success)} />
        Все обязательные поля&nbsp;
        <span className={classes.success}>заполнены</span>
      </div>
    )}
  </div>
);

export default withStyles(RequiredFieldsWarningStyles, {
  name: 'RequiredFieldsWarning',
})(RequiredFieldsWarning);
