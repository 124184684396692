import { createStyles, fade, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    paper: {
      boxShadow: `0px 8px 64px ${fade(theme.palette.text.primary, 0.25)}`,
      margin: 40,
    },
    paperFullWidth: {
      width: 'calc(100% - 80px)',
    },
    paperWidthFalse: {
      maxWidth: '100%',
    },
    paperWidthXs: {
      maxWidth: 460,
    },
    paperWidthSm: {
      maxWidth: 640,
    },
    paperWidthMd: {
      maxWidth: 720,
    },
    paperWidthLg: {
      maxWidth: 960,
    },
    paperWidthXl: {
      maxWidth: 1136,
    },
  });
