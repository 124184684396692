import {
  Dialog as MuiDialog,
  backdropClasses,
  dialogClasses,
  DialogProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Dialog = styled(MuiDialog)<DialogProps>(({ theme }) => ({
  [`.${backdropClasses.root}`]: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: 'rgba(28, 40, 55, 0.7)',
    backdropFilter: 'blur(12px)',
  },
  [`.${dialogClasses.paper}`]: {
    borderRadius: 8,
  }
}));

Dialog.defaultProps = {
  theme: defaultTheme,
};

export default Dialog;
