import { toggleRowSelectionColumnId } from '../assets';
import { actionsColumnId } from '../assets/plugins/useActionColumn';
import { ColumnInstance } from 'react-table';
import { ColumnListItem } from './TableConfig.types';
import { TableConfiguration } from '../table.types';

function isServiceColumn<T extends Record<string, any>>(
  column: ColumnInstance<T>
): boolean {
  return (
    column.id === toggleRowSelectionColumnId || column.id === actionsColumnId
  );
}

export function isColumnConfigurable<T extends Record<string, any>>(
  column: ColumnInstance<T> & { isMerged?: boolean }
): boolean {
  return !isServiceColumn(column) && column.toggleHidden && !column.isMerged;
}

export function getTableConfig<T extends Record<string, any>>(
  columnConfigs: ColumnListItem<T>[]
): TableConfiguration {
  return {
    columns: columnConfigs.map((conf) => ({
      id: conf.column.id,
      isVisible: conf.isVisible,
      order: conf.order,
    })),
  };
}
