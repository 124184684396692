import { createStyles, fade, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    preloader: {
      background: theme.palette.common.white,
      bottom: 0,
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(-100%)',

      '$loading &': {
        animation: `$preloaderStart 5s ${theme.transitions.easing.easeInOut}`,
        animationFillMode: 'forwards',
      },

      '$outlinedPrimary &, $textPrimary &': {
        background: theme.palette.primary.main,
      },

      '$outlinedError &, $textError &': {
        background: theme.palette.error.main,
      },
    },
    '@keyframes preloaderStart': {
      from: {
        opacity: 0.5,
      },
      to: {
        opacity: 0.5,
        transform: 'translateX(0)',
      },
    },
    disabled: {},
    fullWidth: {
      width: '100%',
    },

    body: {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      '$startIcon + &, & + $endIcon': {
        marginLeft: 8,
      },
    },
    root: {
      border: '1px solid transparent',
      borderRadius: 4,
      color: 'inherit',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      letterSpacing: '.02em',
      textTransform: 'uppercase',
      overflow: 'hidden',
      transition: theme.transitions.create([
        'background',
        'border-color',
        'box-shadow',
        'color',
        'opacity',
      ]),
      verticalAlign: 'top',

      '&:focus': {
        outline: 0,
      },

      '&$disabled': {
        opacity: 0.5,
      },

      '&$sizeLg': {
        fontSize: 14,
        height: theme.spacing(7),
        lineHeight: theme.spacing(2) / 14,
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),

        '&$square': {
          padding: 0,
          width: theme.spacing(7),
        },
      },

      '&$sizeMd': {
        fontSize: 12,
        height: theme.spacing(6),
        lineHeight: 16 / 12,
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),

        '&$square': {
          padding: 0,
          width: theme.spacing(6),
        },
      },

      '&$sizeSm': {
        fontSize: 10,
        height: theme.spacing(4),
        lineHeight: theme.spacing(2) / 10,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        '&$square': {
          padding: 0,
          width: theme.spacing(4),
        },
      },

      '&$sizeSm, &$sizeMd, &$sizeLg': {
        '&$text': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    contained: {},
    containedPrimary: {
      background: theme.palette.primary.main,
      color: theme.palette.text.primary,

      '&:hover': {
        boxShadow: `0px 4px 32px ${fade(theme.palette.primary.main, 0.5)}`,
      },

      '&$active': {
        background: theme.palette.text.primary,
        boxShadow: 'none',
        color: theme.palette.common.white,
      },
    },
    containedError: {
      background: theme.palette.error.main,
      color: theme.palette.common.white,

      '&:hover': {
        boxShadow: `0px 4px 32px ${fade(theme.palette.error.main, 0.5)}`,
      },

      '&$active': {
        background: theme.palette.text.primary,
        boxShadow: 'none',
      },
    },
    outlined: {
      '&:hover': {
        background: theme.palette.grey[300],
        borderColor: theme.palette.grey[300],
      },
    },
    outlinedPrimary: {
      borderColor: theme.palette.grey[500],

      '& $ripple': {
        color: theme.palette.primary.main,
      },

      '&$active': {
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
      },

      '&:active, &$loading': {
        borderColor: theme.palette.primary.main,
        background: theme.palette.common.white,
      },
    },
    outlinedError: {
      borderColor: theme.palette.error.main,

      '&, & $ripple': {
        color: theme.palette.error.main,
      },

      '&$active': {
        borderColor: theme.palette.error.main,
        background: theme.palette.error.main,
        color: theme.palette.common.white,
      },

      '&:active, &$loading': {
        borderColor: theme.palette.error.main,
        background: theme.palette.common.white,
      },
    },
    sizeLg: {},
    sizeMd: {},
    sizeSm: {},
    loading: {
      pointerEvents: 'none',
    },
    text: {
      borderColor: 'transparent',
    },
    textError: {
      color: theme.palette.error.main,

      '&$active': {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
      },
    },
    textPrimary: {
      '&$active': {
        background: theme.palette.primary.main,
      },
    },
    textSecondary: {
      color: theme.palette.secondary.main,
    },
    startIcon: {},
    endIcon: {},
    square: {},
    ripple: {
      color: theme.palette.common.white,
    },
    active: {},
  });
