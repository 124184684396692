export default function getColor(
  isSelected,
  isSelectedStartOrEnd,
  isWithinHoverRange,
  isDisabled,
  isToday
) {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
    selectedTodayColor,
  }) => {
    if (isToday && isSelected) {
      return selectedTodayColor;
    }
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor;
    } else if (isSelected) {
      return selectedColor;
    } else if (isWithinHoverRange) {
      return rangeHoverColor;
    } else if (isDisabled) {
      return disabledColor;
    } else {
      return normalColor;
    }
  };
}

export const getWeekendTextColor = (isSelected) => {
  return ({ selectedColor, normalColor }) => {
    if (isSelected) {
      return selectedColor;
    }
    return normalColor;
  };
};
