import {
  InputAdornment as MuiInputAdornment,
  InputAdornmentProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const InputAdornment = styled(MuiInputAdornment)<InputAdornmentProps>(
  ({ theme }) => ({
    ...theme.typography.p2,
  })
);

InputAdornment.defaultProps = {
  theme: defaultTheme,
};

export default InputAdornment;
