import {
  tableCellClasses,
  TableHead as MuiTableHead,
  tableHeadClasses,
  TableHeadProps,
  tableRowClasses,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const TableHead = styled(MuiTableHead)<TableHeadProps>(({ theme }) => ({
  [`&.${tableHeadClasses.root}`]: {
    backgroundColor: theme.palette.background.default,
  },
  [`&.${tableHeadClasses.root} :not(.${tableRowClasses.root}:last-child) .${tableCellClasses.root}`]:
    {
      boxShadow: `inset 0px -2px 0px ${theme.palette.grey['30']}`,
    },
}));

TableHead.defaultProps = {
  theme: defaultTheme,
};

export default TableHead;
