import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      zIndex: theme.zIndex.mobileStepper,
    },

    flexContainer: {
      '& > :not(:first-child)': {
        marginLeft: 16,
      },
    },
    indicator: {
      backgroundColor: theme.palette.text.primary,
      height: 2,
    },
  });
