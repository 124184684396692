import { useEffect, useRef } from 'react';
import { UseTableInstance } from '../table.types';
import {
  UseFiltersState,
  UseRowSelectState,
  UseSortByState,
} from 'react-table';

export function useTableInstanceEvents<T extends Record<string, any>>(params: {
  tableInstance: UseTableInstance<T>;
  onFiltersChange?: (filterState?: UseFiltersState<T>['filters']) => void;
  onSortByChange?: (sortByState?: UseSortByState<T>['sortBy']) => void;
  onSelectedRowsChange?: (
    selectedRowsState?: UseRowSelectState<T>['selectedRowIds']
  ) => void;
}) {
  const state = params.tableInstance.state;

  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      params.onFiltersChange?.(state.filters);
    }
  }, [state.filters]);

  useEffect(() => {
    if (ref.current) {
      params.onSortByChange?.(state.sortBy);
    }
  }, [state.sortBy]);

  useEffect(() => {
    if (ref.current) {
      params.onSelectedRowsChange?.(state.selectedRowIds);
    }
  }, [state.selectedRowIds]);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    }
  }, []);
}
