import {
  Box,
  BoxProps,
  defaultTheme,
  styled,
  TableCell,
  TableHead,
} from '@crpt/material';
import React, { useCallback, useEffect, useRef } from 'react';
import { tableCellClasses } from '@mui/material';
import { SortByToggle } from './SortByToggle';
import {
  getCellSizing,
  getDepthCorrection,
  getInstanceInfo,
  levelWidth,
} from '../TableGrid.tools';
import { TableGridInstance } from '../TableGrid.types';

const TableHeaderRowRoot = styled(TableHead as typeof Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  position: 'sticky',
  boxSizing: 'border-box',
  top: 0,
  zIndex: 1,
  overflow: 'hidden',
  transition: theme.transitions.create(['background', 'box-shadow']),
  '&.isPinned': {
    backgroundColor: theme.palette.grey['0'],
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04)',
    [`.${tableCellClasses.head}`]: {
      boxShadow: 'none',
    },
  },
  '& .HeaderCell': {
    flex: '1 1 auto',
    display: 'flex',
    gap: '8px',
    alignItems: 'flex-end',
  },
  '& .HeaderCell-SortToggle': {
    flex: '0 0 auto',
  },
  '& .HeaderCell-Content': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: '1 1 auto',
  },
}));

TableHeaderRowRoot.defaultProps = {
  theme: defaultTheme,
  component: Box,
};

interface TableHeaderRowProps<D extends Record<string, any>> extends BoxProps {
  horizontalPadding: number;
  tableInstance: TableGridInstance<D>;
  maxDepth: number;
}

const ExpandingOffset = () => (
  <TableCell
    component={Box}
    style={{
      width: levelWidth,
      flex: '0 0 auto',
    }}
  />
);

export function TableHeaderRow<D extends Record<string, any>>({
  horizontalPadding,
  tableInstance,
  maxDepth,
  ...boxProps
}: TableHeaderRowProps<D>) {
  const { visibleColumns } = tableInstance;
  const headerRowRef = useRef<HTMLDivElement>();

  const onScroll = useCallback(() => {
    const rect = headerRowRef.current?.getBoundingClientRect();
    headerRowRef.current?.classList.toggle('isPinned', rect?.top === 0);
  }, [headerRowRef.current]);

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  const { hasRowSelection, hasRowExpanding } = getInstanceInfo(tableInstance);

  return (
    <TableHeaderRowRoot
      ref={headerRowRef}
      paddingX={`${horizontalPadding}px`}
      {...boxProps}
    >
      {hasRowExpanding && <ExpandingOffset />}
      {visibleColumns.map((column, index) => {
        const depthCorrection = getDepthCorrection({
          hasRowExpanding,
          hasRowSelection,
          cellIndex: index,
          rowDepth: 0,
          maxDepth,
        });

        const cellSizing = getCellSizing(column, depthCorrection);

        return (
          <TableCell
            className="HeaderCell"
            component={Box}
            key={column.id}
            style={cellSizing}
          >
            <SortByToggle className="HeaderCell-SortToggle" column={column} />
            <Box className="HeaderCell-Content">{column.render('Header')}</Box>
          </TableCell>
        );
      })}
    </TableHeaderRowRoot>
  );
}
