import React from 'react';
import {
  ColumnInstance,
  UseSortByColumnOptions,
  UseSortByColumnProps,
} from 'react-table';
import { ButtonBase, ButtonBaseProps, Icons, styled } from '@crpt/material';

type SortableColumnOptions<D extends Record<string, any>> = ColumnInstance<D> &
  UseSortByColumnOptions<D> &
  Omit<UseSortByColumnProps<D>, 'getSortByToggleProps'> & {
    getSortByToggleProps: () => { onClick: () => void };
  };

type SortByToggleProps<D extends Record<string, any>> = {
  column: Partial<SortableColumnOptions<D>>;
} & Omit<ButtonBaseProps, 'onClick'>;

const NotSortedClassName = 'NotSorted';

const ToggleButton = styled(ButtonBase)<ButtonBaseProps>(({ theme }) => ({
  color: theme.palette.blue['70'],

  '&:hover': {
    color: theme.palette.blue['70'],
  },

  '&:active': {
    color: theme.palette.blue['100'],
  },

  [`&.${NotSortedClassName}`]: {
    color: theme.palette.grey['50'],
  },
}));

ToggleButton.defaultProps = {
  disableRipple: true,
};

export function SortByToggle<D extends Record<string, any>>({
  column,
  className,
  ...buttonProps
}: SortByToggleProps<D>) {
  if (!column.canSort || !column.getSortByToggleProps) {
    return null;
  }

  const { onClick: onSortClick } = column.getSortByToggleProps();

  const isDescIcon =
    (column.isSorted && column.isSortedDesc) ||
    (!column.isSorted && column.sortDescFirst);

  const buttonClass = column.isSorted
    ? className
    : [className, NotSortedClassName].join(' ');

  return (
    <ToggleButton
      {...buttonProps}
      className={buttonClass}
      onClick={onSortClick}
    >
      {isDescIcon ? <Icons.SortingUp_medium /> : <Icons.SortingDown_medium />}
    </ToggleButton>
  );
}
