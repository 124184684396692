import * as React from 'react';
import {
  inputClasses,
  Select as MuiSelect,
  selectClasses,
  SelectProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';
import Input from '../Input';
import { Icons } from '../Icon';

const BaseSelect = styled(MuiSelect)<SelectProps>(({ theme }) => ({
  [`.${inputClasses.root}`]: {
    fontFamily: theme.typography.fontFamily,
  },

  [`.${inputClasses.input}`]: {
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
  },

  [`&.${selectClasses.disabled}`]: {
    [`&, &:hover`]: {
      border: `1px solid ${theme.palette.grey['50']}`,
      background: theme.palette.grey['30'],
    },
  },
  [`.${selectClasses.icon}`]: {
    right: 16,
  },
}));

BaseSelect.defaultProps = {
  theme: defaultTheme,
  input: <Input />,
  fullWidth: true,
  disableUnderline: true,
  IconComponent: Icons.ArrowDown_small,
  MenuProps: {
    transitionDuration: 0,
    PaperProps: {
      style: { maxHeight: defaultTheme.sizes?.maxDropdownListSize },
    },
  },
};

export default BaseSelect as any as typeof MuiSelect;
