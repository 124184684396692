import { actions, Hooks, TableInstance } from 'react-table';
import React from 'react';

actions.setAlwaysVisibleColumns = 'setAlwaysVisibleColumns';

type UseAlwaysVisibleColumnsTableInstance<T extends Record<string, any>> = Omit<
  TableInstance<T>,
  'state'
> & {
  setAlwaysVisibleColumns: (columns: string[]) => void;
  state: TableInstance<T>['state'] & UseAlwaysVisibleColumnsState;
};

type UseAlwaysVisibleColumnsState = {
  alwaysVisibleColumns?: string[];
};

export const useAlwaysVisibleColumns = <T extends Record<string, any>>(
  hooks: Hooks<T>
) => {
  hooks.stateReducers.push((state, action) =>
    reducer(state as UseAlwaysVisibleColumnsState, action)
  );
  hooks.useInstance.push((instance) =>
    useInstance(instance as UseAlwaysVisibleColumnsTableInstance<T>)
  );

  hooks.useInstanceBeforeDimensions.push((instance) =>
    useInstanceBeforeDimensions(
      instance as UseAlwaysVisibleColumnsTableInstance<T>
    )
  );

  hooks.headerGroupsDeps.push((deps, { instance }) => [
    ...deps,
    (instance as UseAlwaysVisibleColumnsTableInstance<T>).state
      .alwaysVisibleColumns,
  ]);
};

function useInstanceBeforeDimensions<T extends Record<string, any>>(
  instance: UseAlwaysVisibleColumnsTableInstance<T>
) {
  const alwaysVisibleColumns = instance.state.alwaysVisibleColumns;
  const allColumns = instance.flatHeaders;
  if (alwaysVisibleColumns?.length) {
    alwaysVisibleColumns.forEach((columnId) => {
      const column = allColumns.find(({ id }) => id === columnId);
      if (column) {
        column.isVisible = true;
      }
    });
  }
}

function reducer(
  state: UseAlwaysVisibleColumnsState,
  action: Record<string, any>
): UseAlwaysVisibleColumnsState | undefined {
  if (action.type === actions.init) {
    return {
      ...state,
      alwaysVisibleColumns: state.alwaysVisibleColumns || [],
    };
  }

  if (action.type === actions.setAlwaysVisibleColumns) {
    return {
      ...state,
      alwaysVisibleColumns: action.value,
    };
  }
}

function useInstance<T extends Record<string, any>>(
  instance: UseAlwaysVisibleColumnsTableInstance<T>
) {
  const { dispatch } = instance;

  instance.setAlwaysVisibleColumns = React.useCallback(
    (value: string[]) =>
      dispatch({
        type: actions.setAlwaysVisibleColumns,
        value,
      }),
    []
  );
}
