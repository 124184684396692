import { createStyles } from '@material-ui/core';

export const TodayStyles = (theme) =>
  createStyles({
    root: {
      textAlign: 'center',
    },
    container: {
      display: 'flex',
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      padding: '8px 16px',
    },
    button: {
      fontSize: '14px!important',
      color: theme.palette.secondary.main,
    },
  });

export const SubmitStyles = () =>
  createStyles({
    root: {
      padding: '24px 16px 16px 16px',
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      marginRight: '24px',
      fontWeight: 'bold',
    },
  });

export const TrashStyles = (theme) =>
  createStyles({
    button: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: '4px',
    },
  });
