import * as React from 'react';
import {
  CellProps,
  Column,
  Hooks,
  actions,
  TableInstance,
  Renderer,
  ColumnInterfaceBasedOnValue,
} from 'react-table';

actions.setDefaultEmptyValue = 'setDefaultEmptyValue';

type UseEmptyValueState = {
  defaultEmptyValue: string;
};

type UseEmptyValueTableInstance = {
  setDefaultEmptyValue: (value: string) => void;
};

type UseEmptyValueColumnInstance = {
  emptyValue: {
    skipRendering?: boolean;
    deepCheck?: boolean;
    value?: boolean | string | React.ReactFragment;
    valueRenderer: Renderer<any>;
  };
};

type UseEmptyValueCellProps<T extends Record<string, any>> = CellProps<T> & {
  column: Column<T> & Partial<UseEmptyValueColumnInstance>;
  children?: React.ReactNode;
};

type UseEmptyValueConfig = {
  valueRenderer?: Renderer<any>;
  skipRendering?: boolean;
  deepCheck?: boolean;
  value?: boolean | string | React.ReactFragment;
};

type UseEmptyValueOptions = {
  emptyValue?: boolean | string | React.ReactFragment | UseEmptyValueConfig;
};

const isEmpty = (value: any, deep = false): boolean => {
  if (deep && Array.isArray(value)) {
    return value.every((v) => isEmpty(v, true));
  } else if (deep && typeof value === 'object' && value !== null) {
    return Object.values(value).every((v) => isEmpty(v, true));
  } else {
    return value === undefined || value === null || value === '';
  }
};

export function useEmptyValue<T extends Record<string, any>>(hooks: Hooks<T>) {
  hooks.columns.push((columns) => {
    return addEmptyRenderer(
      columns as UseEmptyValueHookColumn[]
    ) as Column<T>[];
  });
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
}

function reducer(state: Record<string, any>, action: Record<string, string>) {
  if (action.type === actions.init) {
    return {
      ...state,
      defaultEmptyValue: state.defaultEmptyValue || '',
    };
  }

  if (action.type === actions.setDefaultEmptyValue) {
    return {
      ...state,
      defaultEmptyValue: action.value,
    };
  }
}

function useInstance(instance: any) {
  const { dispatch } = instance as TableInstance;

  (instance as UseEmptyValueTableInstance).setDefaultEmptyValue =
    React.useCallback(
      (value) => {
        return dispatch({
          type: actions.setDefaultEmptyValue,
          value,
        });
      },
      [dispatch]
    );
}

const resolveEmptyValue = (props: UseEmptyValueCellProps<any>) => {
  const defaultEmptyValue = (props.state as UseEmptyValueState)
    .defaultEmptyValue;
  const emptyValue = props.column.emptyValue?.value;

  return emptyValue === true || isEmpty(emptyValue)
    ? defaultEmptyValue
    : emptyValue;
};

const SkipRendering = (props: UseEmptyValueCellProps<any>) => {
  const { emptyValue: emptyValueConf } =
    props.column as UseEmptyValueColumnInstance;
  if (isEmpty(props.value, emptyValueConf.deepCheck)) {
    if (emptyValueConf.value === false) {
      return null;
    }
    const emptyValue = resolveEmptyValue(props);
    return <>{emptyValue}</>;
  } else {
    const Cell: any = emptyValueConf.valueRenderer;
    return <Cell {...props} />;
  }
};

const EmptyCell = (props: UseEmptyValueCellProps<any>) => {
  const { emptyValue: emptyValueConf } =
    props.column as UseEmptyValueColumnInstance;
  const Cell: any = emptyValueConf.valueRenderer;
  const cellProps = { ...props };
  if (isEmpty(props.value, emptyValueConf.deepCheck)) {
    if (emptyValueConf.value === false) {
      cellProps.value = null;
      cellProps.children = null;
    } else {
      const emptyValue = resolveEmptyValue(props);
      cellProps.value = emptyValue;
      cellProps.children = emptyValue;
    }
  }
  return <Cell {...cellProps} />;
};

const EmptyValue = (props: CellProps<any>) => {
  return isEmpty(props.value) ? resolveEmptyValue(props) : props.value;
};

type UseEmptyValueHookColumn = Column<any> &
  UseEmptyValueOptions &
  ColumnInterfaceBasedOnValue<any> & {
    columns?: UseEmptyValueHookColumn[];
  };

function addEmptyRenderer(columns?: UseEmptyValueHookColumn[]) {
  if (!columns) {
    return;
  }
  return columns.map((original) => {
    const column = { ...original };
    switch (typeof column.emptyValue) {
      case 'object': {
        const emptyValue = column.emptyValue as UseEmptyValueConfig;
        if (column.Cell && emptyValue.skipRendering) {
          emptyValue.valueRenderer = column.Cell;
          column.Cell = SkipRendering;
        } else if (column.Cell) {
          emptyValue.valueRenderer = column.Cell;
          column.Cell = EmptyCell;
        } else {
          column.Cell = EmptyValue;
        }
        break;
      }
      case 'boolean': {
        if (column.emptyValue) {
          if (column.Cell) {
            column.emptyValue = {
              value: true,
              valueRenderer: column.Cell,
            };
            column.Cell = EmptyCell;
          } else {
            column.emptyValue = {
              value: true,
            };
            column.Cell = EmptyValue;
          }
        }
        break;
      }
      default: {
        if (!isEmpty(column.emptyValue)) {
          if (column.Cell) {
            column.emptyValue = {
              value: column.emptyValue,
              valueRenderer: column.Cell,
            };
            column.Cell = EmptyCell;
          } else {
            column.emptyValue = {
              value: column.emptyValue,
            };
            column.Cell = EmptyValue;
          }
        }
      }
    }
    column.columns = addEmptyRenderer(column.columns);
    return column;
  });
}
