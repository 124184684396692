import React, { ComponentType, memo, useMemo } from 'react';
import {
  FilterEditor,
  FilterRenderProps,
  FilterValidationHandler,
} from '../../TableFilter';
import { AsyncLookupInput, AsyncLookupInputProps } from '@crpt/material';
import { AutocompleteValue } from '@mui/material';

type FilterValue<Value, Multiple> = AutocompleteValue<
  Value,
  Multiple,
  false,
  false
>;

export interface AsyncLookupFilterParams<
  Value,
  Option,
  Multiple extends boolean | undefined
> extends Pick<
    AsyncLookupInputProps<Value, Option, Multiple>,
    | 'searchOptions'
    | 'getOptionValue'
    | 'getOptionLabel'
    | 'fetchValue'
    | 'multiple'
    | 'label'
    | 'classes'
    | 'placeholder'
    | 'helperText'
  > {
  title?: React.ReactNode;
  OptionItemComponent?: ComponentType<Option>;
  validator?: FilterValidationHandler<FilterValue<Value, Multiple>>;
}

export function makeAsyncLookupFilter<
  Option extends Record<string, any>,
  Value extends string | number = string,
  Multiple extends boolean | undefined = false,
>(filterParams: AsyncLookupFilterParams<Value, Option, Multiple>) {
  const {
    title,
    multiple,
    placeholder,
    label,
    helperText,
    validator,
    fetchValue,
    searchOptions,
    getOptionLabel,
    getOptionValue,
    OptionItemComponent,
    classes,
  } = filterParams;

  return memo((props: FilterRenderProps<FilterValue<Value, Multiple>>) => {
    const {
      column,
      filterValue,
      setFilterValue,
      useValidator,
      executeValidation,
    } = props;
    const { error } = useValidator(validator);
    const normalizedValue = useMemo<FilterValue<Value, Multiple>>(() => {
      if (filterValue == null || filterValue === '') {
        return (multiple ? [] : null) as FilterValue<Value, Multiple>;
      }
      return filterValue;
    }, [filterValue]);

    return (
      <FilterEditor column={column} error={error} title={title}>
        <AsyncLookupInput
          classes={classes}
          name={column.id}
          multiple={multiple}
          value={normalizedValue}
          searchOptions={searchOptions}
          fetchValue={fetchValue}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          renderOptionItem={(option) => {
            if (OptionItemComponent) {
              return <OptionItemComponent {...option} />;
            }
          }}
          onBlur={executeValidation}
          onChange={(_, value) => setFilterValue(value)}
          error={Boolean(error)}
        />
      </FilterEditor>
    );
  });
}
