import { TextField, InputTags } from '@crpt/material';
import React from 'react';
import { memo } from 'react';
import {
  FilterEditor,
  FilterRenderProps,
  FilterValidationHandler,
} from '../../TableFilter';

interface TextInputFilterOptions {
  title?: React.ReactNode;
  multiple?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  validator?: FilterValidationHandler<string | string[]>;
}

export function makeTextInputFilter(filterOptions?: TextInputFilterOptions) {
  const {
    title,
    validator,
    helperText,
    label,
    placeholder,
    multiple = false,
  } = filterOptions || {};
  return memo((props: FilterRenderProps<string | string[]>) => {
    const {
      column,
      filterValue,
      setFilterValue,
      useValidator,
      executeValidation,
    } = props;
    const { error } = useValidator(validator);

    return (
      <FilterEditor column={column} error={error} title={title}>
        {multiple ? (
          <InputTags
            name={column.id}
            placeholder={placeholder}
            helperText={helperText}
            label={label}
            onBlur={executeValidation}
            onChange={setFilterValue}
            error={Boolean(error)}
            value={Array.isArray(filterValue) ? filterValue : []}
          />
        ) : (
          <TextField
            name={column.id}
            helperText={helperText}
            placeholder={placeholder}
            label={label}
            onBlur={executeValidation}
            onChange={setFilterValue}
            value={filterValue ?? ''}
            error={Boolean(error)}
          />
        )}
      </FilterEditor>
    );
  });
}
