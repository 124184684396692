import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    wrapper: {
      color: theme.palette.text.primary,
      width: '64px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${theme.palette.grey['500']}`,
      borderRadius: '4px',
    },
    input: {
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'textfield',
        margin: 0,
        fontSize: '14px',
        paddingLeft: '8px',
      },
    },
    actionsButtonsWrapper: {
      borderLeft: `1px solid ${theme.palette.grey['500']}`,
    },
    increaseButtonWrapper: {
      borderBottom: `1px solid ${theme.palette.grey['500']}`,
    },
    button: {
      width: '22px !important',
      height: '20px !important',
    },
    rotatedButton: {
      transform: 'rotate(180deg)',
    },
  });
