import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
} from '@material-ui/lab';
import React, { FC } from 'react';

import useClasses from './ToggleButton.styles';

export interface ToggleButtonProps extends MuiToggleButtonProps {}

export const ToggleButton: FC<ToggleButtonProps> = ({ children, ...rest }) => {
  const classes = useClasses();
  return (
    <MuiToggleButton classes={classes} {...rest}>
      {children}
    </MuiToggleButton>
  );
};

export default ToggleButton;
