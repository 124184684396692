import React, { CSSProperties } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { Box } from '@crpt/material';
import { ColumnDraggingPreview } from './ColumnDraggingPreview';
import { ColumnDragObject, DND_COLUMN } from '../TableConfig.types';

const dragLayerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getDragBoxStyles(initialOffset: XYCoord, currentOffset: XYCoord) {
  const { x } = initialOffset;
  const { y } = currentOffset;

  return {
    transform: `translate(${x}px, ${y}px)`,
  };
}

export function ColumnListDragLayer() {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem<ColumnDragObject>(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  if (
    !isDragging ||
    itemType !== DND_COLUMN ||
    !initialOffset ||
    !currentOffset
  ) {
    return null;
  }

  return (
    <Box style={dragLayerStyles}>
      <Box
        style={getDragBoxStyles(initialOffset, currentOffset)}
        width={item.getWidth()}
      >
        <ColumnDraggingPreview
          column={item.column}
          isVisible={item.isVisible}
        />
      </Box>
    </Box>
  );
}
