import { Box, TableCell } from '@crpt/material';
import { DropdownToggle } from './DropdownToggle';
import React from 'react';
import { TableGridRow } from '../TableGrid.types';

export interface ExpanderCellProps<D extends Record<string, any>> {
  row: TableGridRow<D>;
  levelWidth: number;
}

export function ExpanderCell<D extends Record<string, any>>({
  row,
  levelWidth,
}: ExpanderCellProps<D>) {
  return (
    <TableCell
      component={Box}
      className="ExpanderCell"
      style={{ width: ((row.depth || 0) + 1) * levelWidth }}
    >
      {row.canExpand && row.toggleRowExpanded && (
        <DropdownToggle
          open={row.isExpanded}
          onClick={() => row.toggleRowExpanded(!row.isExpanded)}
        />
      )}
    </TableCell>
  );
}
