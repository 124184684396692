import React, { useCallback, useState } from 'react';
import MenuItem from '../MenuItem';
import Button from '../Button';
import { Box, ButtonProps, MenuItemProps } from '@mui/material';
import {
  DropdownButtonProps,
  Icons,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '../index';
import { Dropdown } from './Dropdown';

export interface MenuButtonItem extends MenuItemProps {
  label?: React.ReactNode;
  icon?: React.ReactNode;
}

export interface MenuButtonProps extends ButtonProps {
  items: MenuButtonItem[];
  popoverProps?: DropdownButtonProps['popoverProps'];
}

export const MenuButton = (props: MenuButtonProps) => {
  const {
    children,
    items = [],
    endIcon = <Icons.ArrowDown_medium />,
    popoverProps,
    ...buttonProps
  } = props;

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderMenuItems = useCallback(
    (closePopup: () => void) => {
      const hasIcons = items.some((item) => item.icon);
      return items.map((item, index) => {
        const { label, onClick, icon, children, ...menuItemProps } = item;

        return (
          <MenuItem
            key={`${index}-${label}`}
            onClick={(event) => {
              onClick?.(event);
              closePopup();
            }}
            {...menuItemProps}
          >
            {hasIcons && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>{children || label}</ListItemText>
          </MenuItem>
        );
      });
    },
    [items]
  );

  return (
    <Dropdown
      {...buttonProps}
      component={Button}
      content={renderMenuItems}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      endIcon={
        <Box style={{ transform: isOpen ? 'rotate(180deg)' : undefined }}>
          {endIcon}
        </Box>
      }
      popoverProps={{
        ...popoverProps,
        PaperProps: {
          ...popoverProps?.PaperProps,
          style: {
            maxHeight: theme.sizes?.maxDropdownListSize,
            ...popoverProps?.PaperProps?.style,
          },
        },
      }}
    >
      {children}
    </Dropdown>
  );
};
