import * as React from 'react';
import {
  Cell,
  CellProps,
  Column,
  ColumnInstance,
  ColumnInterfaceBasedOnValue,
  Hooks,
} from 'react-table';

type UseMergeColumnInstance<T extends Record<string, any>> = ColumnInstance<T> &
  UseMergeColumn<T>;

type UseMergeCell<T extends Record<string, any>> = Cell<T> & {
  column: UseMergeColumnInstance<T>;
};

type UseMergeColumn<T extends Record<string, any>> = Column<T> &
  ColumnInterfaceBasedOnValue<T> & {
    isMerged?: boolean;
    columns?: UseMergeColumn<T>[];
    merge?: boolean;
    merged?: string[];
    RootCell?: ColumnInterfaceBasedOnValue<T>['Cell'];
  };

const fakeAccessor = () => undefined;

export const useMerge = <T extends Record<string, any>>(hooks: Hooks<T>) => {
  hooks.columns.push((columns) => {
    return addMergedColumns(columns) as Column<T>[];
  });

  hooks.visibleColumns.push((columns: UseMergeColumn<T>[]) => {
    return columns.filter((c) => !c.isMerged);
  });

  hooks.prepareRow.push((row) => {
    row.cells.forEach((cell: UseMergeCell<T>) => {
      if (cell.column.merged?.length && cell.column.accessor === fakeAccessor) {
        const valueEntry = cell.column.merged.map((id) => [id, row.values[id]]);
        row.values[cell.column.id] = Object.fromEntries(valueEntry);
      }
    });
  });
};

const isEmpty = (value: any) => value === undefined || value === null;

const DefaultRootCell: React.FC = ({ children }) => <>{children}</>;
const DefaultCell = ({ value }: CellProps<any>) =>
  isEmpty(value) ? null : String(value);

function collectSubColumns<T extends Record<string, any>>(
  column: UseMergeColumn<T>
): UseMergeColumn<T>[] {
  if (column.columns?.length) {
    return column.columns.map(collectSubColumns).flat();
  } else {
    return [column];
  }
}

const optionsToExclude = ['merge', 'columns'];

const getColumnId = <T extends Record<string, any>>({
  id,
  accessor,
}: UseMergeColumn<T>) => {
  if (!isEmpty(id)) {
    return String(id);
  } else if (isEmpty(id) && typeof accessor === 'string') {
    return accessor;
  } else {
    throw new Error(
      'A column does not have an ID. All sub columns must have an ID to be merged.'
    );
  }
};

const makeRootColumn = <T extends Record<string, any>>(
  column: UseMergeColumn<T>
) => {
  const root = Object.fromEntries(
    Object.entries(column).filter(([key]) => !optionsToExclude.includes(key))
  );
  const subColumns = collectSubColumns(column);
  subColumns.forEach((subColumn) => (subColumn.isMerged = true));
  root.merged = subColumns.map(getColumnId);

  root.accessor = root.accessor || fakeAccessor;
  root.RootCell = root.Cell;
  root.Cell = MergedCellRoot;
  return root;
};

function addMergedColumns<T extends Record<string, any>>(
  columns: UseMergeColumn<T>[] | undefined
) {
  if (!columns) {
    return;
  }

  return columns
    .map((original) => {
      const column = { ...original };

      if (column.merge && column.columns?.length) {
        if (isEmpty(column.id)) {
          throw new Error(
            'A root merging column does not have an ID. The root column must have an ID to merge.'
          );
        }
        const root = makeRootColumn(column);
        column.id = `@${column.id}`;
        return [column, root];
      } else {
        column.columns = addMergedColumns(
          column.columns
        ) as UseMergeColumn<T>[];
        return [column];
      }
    })
    .flat();
}

type MergedCellRootProps<T extends Record<string, any>> = CellProps<T> & {
  column: UseMergeColumnInstance<T> & { merged: UseMergeColumn<T>['merged'][] };
};

const MergedCellRoot = <T extends Record<string, any>>(
  props: MergedCellRootProps<T>
) => {
  const {
    row,
    column: rootColumn,
    cell: rootCell,
    value: rootValue,
    ...instanceProps
  } = props;
  const cells = rootColumn.merged
    .map((columnId) => {
      return row.allCells.find((cell) => cell.column.id === columnId);
    })
    .filter(Boolean) as Cell<T>[];

  const RootCell: any = rootColumn.RootCell || DefaultRootCell;
  const rootProps: any = rootColumn.RootCell ? props : undefined;

  return (
    <RootCell {...rootProps}>
      {cells.map((cell) => {
        const Cell: any = cell.column.Cell || DefaultCell;
        const cellProps = {
          row,
          column: cell.column,
          cell,
          value: cell.value,
          ...instanceProps,
        };
        return <Cell key={cell.column.id} {...cellProps} />;
      })}
    </RootCell>
  );
};
