import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  toggleButtonGroupClasses,
  ToggleButtonGroupProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const ToggleButtonGroup = styled(MuiToggleButtonGroup)<ToggleButtonGroupProps>(
  ({ theme }) => ({
    [`&.${toggleButtonGroupClasses.root}`]: {
      backgroundColor: theme.palette.grey['5'],
    },
  })
);

ToggleButtonGroup.defaultProps = {
  theme: defaultTheme,
};

export default ToggleButtonGroup;
