import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@material-ui/lab';
import React, { FC } from 'react';

export interface ToggleButtonGroupProps extends MuiToggleButtonGroupProps {}

export const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({
  exclusive = true,
  ...rest
}) => <MuiToggleButtonGroup exclusive={exclusive} {...rest} />;

export default ToggleButtonGroup;
