import {
  Box,
  BoxProps,
  Button,
  defaultTheme,
  Icons,
  styled,
  Link,
} from '@crpt/material';
import React, { useMemo } from 'react';
import { Form, FormProps } from 'react-final-form';
import { columnFiltersToFormValues } from '../TableFilter.tools';
import { FilterableColumnInstance } from '../TableFilter.types';
import { FilterField } from './FilterField';
import createFocusOnFirstErrorDecorator from 'final-form-focus';

type FormValues = Record<string, any>;

type FilterFormProps<T extends Record<string, any>> = BoxProps & {
  title: string;
  applyLabel: string;
  cancelLabel: string;
  columns: FilterableColumnInstance<T>[];
  onSubmit: (formValues: FormValues) => void;
  onClose: () => void;
};

const decorators: FormProps<FormValues>['decorators'] = [
  createFocusOnFirstErrorDecorator(),
];
const mutators: FormProps<FormValues>['mutators'] = {
  // workaround for revalidate form before submit
  identityValue: ([name], state, { changeValue }) => {
    changeValue(state, name, (value) => value);
  },
};

export function FilterForm<T extends Record<string, any>>({
  title,
  applyLabel,
  cancelLabel,
  columns,
  onClose,
  onSubmit,
  ...boxProps
}: FilterFormProps<T>) {
  const filterColumns = useMemo(
    () => columns.filter((col) => col.id && col.canFilter && col.Filter),
    [columns]
  );

  const initialValues = useMemo<FormValues>(
    () => columnFiltersToFormValues(filterColumns),
    [filterColumns]
  );

  return (
    <FilterFormRoot {...boxProps}>
      <Form
        initialValues={initialValues}
        decorators={decorators}
        onSubmit={onSubmit}
        mutators={mutators}
        validateOnBlur
      >
        {({ handleSubmit, form, ...formState }) => (
          <form
            className="FilterForm"
            onSubmit={(values) => {
              form.mutators.identityValue('revalidate');
              return handleSubmit(values);
            }}
          >
            <Box className="FilterForm-ScrollArea">
              <Box className="FilterForm-Header">
                <Box className="Header-Title">{title}</Box>
                <Link className="Header-CloseButton" onClick={onClose}>
                  <Icons.Close_large />
                </Link>
              </Box>
              <Box className="FilterForm-FilterList">
                {filterColumns.map((column) => (
                  <FilterField column={column} key={column.id} />
                ))}
              </Box>
            </Box>
            <Box className="FilterForm-Footer">
              <Button
                disabled={formState.submitting || formState.validating}
                type="submit"
              >
                {applyLabel}
              </Button>
              <Button color="secondary" onClick={onClose}>
                {cancelLabel}
              </Button>
            </Box>
          </form>
        )}
      </Form>
    </FilterFormRoot>
  );
}

const FilterFormRoot = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  justifyContent: 'stretch',

  '.FilterForm': {
    flex: '1 1 0',
    display: 'flex',
    flexDirection: 'column',
  },
  '.FilterForm-Header': {
    padding: '20px 0 20px 20px',
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,

    '.Header-Title': {
      ...theme.typography.h3,
      flex: '1 1 0',
    },
    '.Header-CloseButton': {
      flex: '0 0  auto',
    },
  },
  '.FilterForm-FilterList': {
    paddingLeft: 20,
    paddingBottom: 100,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default,
  },
  '.FilterForm-ScrollArea': {
    overflowY: 'scroll',
    overflowX: 'hidden',
    flex: '1 1 0',
    backgroundColor: theme.palette.background.default,

    '&::-webkit-scrollbar': {
      width: 20,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.background.default,
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey['30'],
      borderLeft: `12px solid ${theme.palette.background.default}`,
      borderRight: `4px solid ${theme.palette.background.default}`,
      borderTop: `8px solid ${theme.palette.background.default}`,
      borderBottom: `8px solid ${theme.palette.background.default}`,
    },
  },
  '.FilterForm-Footer': {
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.04)',
    padding: 20,
    backgroundColor: theme.palette.common.white,
    flex: '0 0 auto',
    display: 'flex',
    gap: 20,

    Button: {
      flex: '1 1 0',
    },
  },
}));

FilterFormRoot.defaultProps = {
  theme: defaultTheme,
};
