import { ColumnInstance } from 'react-table';
import { ColumnHeaderRenderHandler } from '../TableConfig.types';
import React from 'react';

export const defaultHeaderRenderer = (column: ColumnInstance<any>) => {
  return column.render ? column.render('Header') : column.Header;
};

export const customHeaderRenderer =
  (translationHandler?: ColumnHeaderRenderHandler<any>) =>
  (column: ColumnInstance<any>) => {
    const customRenderer = translationHandler?.(column);
    return customRenderer || defaultHeaderRenderer(column);
  };

export const ColumnHeaderRenderer = React.createContext(defaultHeaderRenderer);
