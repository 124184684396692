import { Menu as MuiMenu, menuClasses, MenuProps } from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  [`.${menuClasses.paper}`]: {
    fontFamily: theme.typography.fontFamily,
    boxShadow: '0px 8px 20px #00000008',
    borderRadius: 8,
  },
}));

Menu.defaultProps = {
  theme: defaultTheme,
  disableAutoFocusItem: true,
  MenuListProps: {
    disablePadding: true,
  },
};

export default Menu;
