import format from './format.i18n';

export default function formatDatesString(dates, options) {
  const {
    type = 'single',
    displayFormat = 'dd.MM.yyyy',
    placeholder = '',
  } = options;
  if (!dates) {
    return placeholder;
  }
  const { startDate, endDate } = dates;
  if (!startDate && !endDate) {
    return placeholder;
  } else if (startDate && !endDate) {
    return (
      format(startDate, displayFormat, 'ru') +
      (type !== 'single' ? ' - ...' : '')
    );
  } else if (startDate && endDate) {
    return (
      format(startDate, displayFormat, 'ru') +
      (type !== 'single' ? ' - ' + format(endDate, displayFormat, 'ru') : '')
    );
  } else {
    return '';
  }
}
