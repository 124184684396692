import {
  usePagination,
  UsePaginationItem,
  UsePaginationProps,
} from '@material-ui/lab';
import clsx from 'clsx';
import React, {
  ChangeEvent,
  ComponentProps,
  FC,
  ReactNode,
  useCallback,
} from 'react';

import Icon from '../Icon/Icon';
import useClasses from './Pagination.styles';
import PaginationButton from './PaginationButton';

export type PaginationProps = UsePaginationProps &
  ComponentProps<'div'> & {
    pagesCount?: number;
    onPageChange?: (page: number) => void;
  };

export const Pagination: FC<PaginationProps> = ({
  className,
  pagesCount,
  siblingCount = 2,
  onChange,
  onPageChange,
  ...rest
}) => {
  const classes = useClasses();
  const handleChange = useCallback(
    (event: ChangeEvent<unknown>, page: number): void => {
      if (typeof onChange === 'function') {
        onChange(event, page);
      }

      if (typeof onPageChange === 'function') {
        onPageChange(page);
      }
    },
    [onChange, onPageChange]
  );

  const { items } = usePagination({
    count: pagesCount,
    onChange: handleChange,
    siblingCount: siblingCount,
    ...rest,
  });

  const renderPaginationItem = useCallback(
    (item: UsePaginationItem, index: number) => {
      let children: ReactNode;

      if (/ellipsis/.test(item.type)) {
        children = '…';
      } else if (/next/.test(item.type)) {
        children = <Icon name="arrowRight" />;
      } else if (/previous/.test(item.type)) {
        children = <Icon name="arrowLeft" />;
      } else {
        children = item.page;
      }

      return (
        <PaginationButton
          disabled={/ellipsis/.test(item.type) || item.disabled}
          key={index}
          onClick={item.onClick}
          selected={item.selected}
        >
          {children}
        </PaginationButton>
      );
    },
    []
  );

  return (
    <div className={clsx(classes.root, className)}>
      {items.map(renderPaginationItem)}
    </div>
  );
};

export default Pagination;
