import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDatepicker, START_DATE } from './hooks';
import DatePickerContext from './DatePicker.context';
import Month from './components/Month';
import Controls from './components/Controls';
import Header from './components/Header';
import Footer from './components/Footer';
import { dateEquality } from './utils/dateEquality';
import { START_YEAR, YEARS_AFTER } from './Datepicker.constants';

const isToday = (date) => {
  if (!date) {
    return false;
  }
  const today = new Date();
  return dateEquality(today, date);
};

const Datepicker = ({
  onSubmit,
  onClose,
  minDate,
  maxDate,
  date,
  isFilter,
  onDateChange,
  startYear = START_YEAR,
  yearsAfter = YEARS_AFTER,
  disabledDayFocus,
}) => {
  const dateState = {
    date: date ? date : null,
    focusedInput: START_DATE,
  };

  const [state, setState] = useState(dateState);

  useEffect(() => {
    setState(dateState);
  }, [date]);

  const handleTodayClick = (newDate) => {
    setState({
      ...state,
      date: newDate,
    });

    if (typeof onDateChange === 'function') {
      onDateChange(newDate);
    }
  };

  const handleDateChange = (data) => {
    setState({
      ...state,
      date: data.startDate,
    });

    if (typeof onDateChange === 'function') {
      onDateChange(data.startDate);
    }
  };

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isSingleDateSelected,
    isDateHovered,
    isFirstSelectedDate,
    isLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    onResetDates,
    goToMonth,
  } = useDatepicker({
    startDate: state.date,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
    minBookingDate: minDate,
    maxBookingDate: maxDate,
    numberOfMonths: 1,
  });

  const memoizedGoToMonth = useCallback((newDate) => goToMonth(newDate), []);
  const memoizedOnSubmit = useCallback((result) => onSubmit(result), []);
  const memoizedOnResetDates = useCallback(() => onResetDates(), []);
  const currentDateIsBlocked = useMemo(() => isDateBlocked(state.date), [
    state.date,
  ]);

  const memoizedIsSingleDateSelected = useCallback(
    (selectedDate) => {
      if (!state.date) {
        return false;
      }
      return isSingleDateSelected(state.date, selectedDate);
    },
    [state.date]
  );

  return (
    <div>
      <DatePickerContext.Provider
        value={{
          focusedDate,
          isDateFocused,
          isSingleDaySelected: memoizedIsSingleDateSelected,
          isToday,
          isDateSelected,
          isDateHovered,
          isDateBlocked,
          isFirstSelectedDate,
          isLastSelectedDate,
          onDateSelect,
          onDateFocus,
          onDateHover,
          type: 'single',
          currentDate: state.date,
          disabledDayFocus,
        }}
      >
        <div style={{ width: 320 }}>
          <Header
            startDate={state.date}
            onClose={onClose}
            type={'single'}
            isFilter={isFilter}
          />

          <Controls
            goToMonth={memoizedGoToMonth}
            year={activeMonths[0].year}
            month={activeMonths[0].month}
            startYear={startYear}
            yearsAfter={yearsAfter}
          />

          <div>
            {activeMonths.map((month) => (
              <Month
                key={`${month.year}-${month.month}`}
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
              />
            ))}
          </div>
          <Footer
            activeMonths={activeMonths}
            currentDateIsBlocked={currentDateIsBlocked}
            goToMonth={memoizedGoToMonth}
            onResetDates={memoizedOnResetDates}
            onSubmit={memoizedOnSubmit}
            result={state.date}
            type={'single'}
            isTodayDay={isToday(state.date)}
            onTodayDateSelect={handleTodayClick}
            isFilter={isFilter}
          />
        </div>
      </DatePickerContext.Provider>
    </div>
  );
};

Datepicker.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  /** Indicate that Datepicker use in table filter area
   * Hide close button in Header. Hide control buttons in Footer */
  isFilter: PropTypes.bool,
  onDateChange: PropTypes.func,
  startYear: PropTypes.number,
  yearsAfter: PropTypes.number,
};

Datepicker.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  isFilter: false,
};

export default Datepicker;
