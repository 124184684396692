import { Chip as MuiChip, chipClasses, ChipProps } from '@mui/material';
import React from 'react';
import { defaultTheme, styled } from '../theme';
import { Icons } from '../Icon';

const Chip = styled(MuiChip)<ChipProps>(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    color: theme.palette.grey['90'],
    fontFamily: theme.typography.fontFamily,

    [`&.${chipClasses.sizeSmall}`]: {
      background: theme.palette.common.white,
      height: 32,
      padding: '8px 12px',
      borderRadius: 100,

      [`.${chipClasses.label}`]: {
        ...theme.typography.p3,
        padding: 0,
        margin: 0,
      },
    },

    [`&.${chipClasses.sizeMedium}`]: {
      background: theme.palette.grey['30'],
      height: 40,
      padding: '8px 12px',
      borderRadius: 20,

      [`&.MuiChip-colorWarning`]: {
        background: theme.palette.orange['20'],
      },

      [`&.MuiChip-colorSuccess`]: {
        background: theme.palette.green['20'],
      },

      [`&.MuiChip-colorError`]: {
        background: theme.palette.red['20'],
      },

      [`.${chipClasses.label}`]: {
        ...theme.typography.p2,
        padding: 0,
        margin: 0,
        maxWidth: '90%',
      },
    },

    [`.${chipClasses.deleteIcon}`]: {
      fontSize: 16,
      margin: '0 0 0 8px',
      color: theme.palette.blue['70'],
      transition: theme.transitions.create('color'),

      '&:hover': {
        color: theme.palette.blue['100'],
      },

      '&:active': {
        color: theme.palette.grey['100'],
      },
    },
  },
}));

Chip.defaultProps = {
  theme: defaultTheme,
  size: 'small',
  onDelete: undefined,
  deleteIcon: <Icons.Close_small />,
};

export default Chip;
