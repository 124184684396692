import {
  ColumnInstance,
  TableInstance,
  UseColumnOrderInstanceProps,
} from 'react-table';
import { TableConfiguration } from '../table.types';
import React from 'react';

export const DND_COLUMN = 'D&D-Column-Config';

export interface ColumnDragObject {
  id: string;
  getWidth: () => number | string;
  isVisible: boolean;
  column: ColumnInstance<Record<string, any>>;
}

export interface ColumnListItem<T extends Record<string, any>> {
  column: ColumnInstance<T>;
  order: number;
  isVisible: boolean;
  visibilityToggle: boolean;
}

export type ColumnOrderableTableInstance<T extends Record<string, any>> = Omit<
  TableInstance<T>,
  'state'
> &
  Partial<UseColumnOrderInstanceProps<T>> & {
    state: TableInstance<T>['state'] & {
      alwaysVisibleColumns?: string[];
      defaultConfig?: TableConfiguration;
    };
    applyConfig?: (config: TableConfiguration) => void;
    applyDefaultConfig?: () => void;
  };

export type ColumnHeaderRenderHandler<T extends Record<string, any>> = (
  column: ColumnInstance<T>,
) => React.ReactNode | string;