import { getCookie } from '../utils/cookieHelper';

export const PRODUCT_GROUPS_NAMES = {
  milk: 'Молочная продукция',
  pharma: 'Лекарственные средства',
  alcohol: 'Алкогольная продукция',
  shoes: 'Обувные товары',
  tobacco: 'Табачная продукция',
  water: 'Питьевая вода',
  beer: 'Пиво и пивные напитки',
  appliances: 'Бытовая техника',
  lp: 'Предметы одежды, белье постельное, столовое, туалетное и кухонное',
  otp: 'Альтернативная табачная продукция',
  medicals: 'Изделия медицинского назначения',
};

export const POINT_OF_ENTER = getCookie('pointOfEnter') || '/login-kep';
