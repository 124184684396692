import React, { memo } from 'react';
import TimePickerBodyStyles, {
  TimeDividerStyles,
} from './TimePickerBody.styles';
import { withStyles, WithStyles } from '@material-ui/core';
import { Time } from '../../TimePicker.types';
import { TimeComponent } from '../TimeComponent';

type TimePickerBodyProps = {
  time: Time;
  increaseMinutes: () => void;
  increaseHours: () => void;
  increaseSeconds: () => void;
  decreaseSeconds: () => void;
  decreaseMinutes: () => void;
  decreaseHours: () => void;
  changeSeconds: (value: string) => void;
  changeMinutes: (value: string) => void;
  changeHours: (value: string) => void;
} & WithStyles<typeof TimePickerBodyStyles>;

const TimePickerBodyComponent: React.FC<TimePickerBodyProps> = ({
  time,
  increaseSeconds,
  increaseMinutes,
  increaseHours,
  changeSeconds,
  changeMinutes,
  changeHours,
  decreaseSeconds,
  decreaseMinutes,
  decreaseHours,
  classes,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <TimeComponent
          handleChange={changeHours}
          value={time.hours}
          onIncrease={increaseHours}
          onDecrease={decreaseHours}
        />
        <TimeDivider />
        <TimeComponent
          handleChange={changeMinutes}
          value={time.minutes}
          onIncrease={increaseMinutes}
          onDecrease={decreaseMinutes}
        />
        <TimeDivider />
        <TimeComponent
          handleChange={changeSeconds}
          value={time.seconds}
          onIncrease={increaseSeconds}
          onDecrease={decreaseSeconds}
        />
      </div>
    </div>
  );
};

export const TimePickerBody = withStyles(TimePickerBodyStyles)(
  TimePickerBodyComponent
);

const TimeDividerComponent: React.FC<
  WithStyles<typeof TimeDividerStyles>
> = memo(({ classes }) => {
  return <div className={classes.content}>:</div>;
});

const TimeDivider = withStyles(TimeDividerStyles)(TimeDividerComponent);
