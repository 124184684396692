import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentProps, FC, MouseEventHandler } from 'react';

import Icon from '../Icon/Icon';
import useClasses from './Notification.styles';

export type NotificationProps = {
  color?:
    | 'error'
    | 'grey'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'textPrimary'
    | 'textSecondary'
    | 'warning';
  disableBorderRadius?: boolean;
  disableClose?: boolean;
  onClose?: MouseEventHandler<HTMLButtonElement>;
} & ComponentProps<'div'>;

export const Notification: FC<NotificationProps> = ({
  children,
  color,
  disableBorderRadius = false,
  disableClose,
  onClose,
  ...rest
}) => {
  const classes = useClasses({
    color,
  });

  return (
    <div
      className={clsx(classes.root, {
        [classes.disableBorderRadius]: disableBorderRadius,
        [classes.disableClose]: disableClose,
      })}
      {...rest}
    >
      <div>{children}</div>
      {!disableClose && (
        <IconButton className={classes.close} onClick={onClose}>
          <Icon name="Close" />
        </IconButton>
      )}
    </div>
  );
};

export default Notification;
