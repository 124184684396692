import * as React from 'react';
import { SelectProps } from '@mui/material';
import Placeholder from './SelectPlaceholder';
import BaseSelect from './BaseSelect';

function Select<T>(props: SelectProps<T>) {
  const { value, placeholder } = props;
  const isEmptyValue =
    value == null || (typeof value === 'string' && value === '');
  const showPlaceholder = isEmptyValue && Boolean(placeholder);

  const renderPlaceHolder = React.useCallback(
    () => <Placeholder>{placeholder}</Placeholder>,
    [placeholder]
  );

  return (
    <BaseSelect
      {...props}
      displayEmpty={showPlaceholder || props.displayEmpty}
      renderValue={showPlaceholder ? renderPlaceHolder : props.renderValue}
    />
  );
}

export default React.memo(Select) as typeof Select;
