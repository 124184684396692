import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles(
  (theme: Theme) => ({
    root: {
      border: `1px solid transparent`,
      color: theme.palette.text.primary,
      fontSize: 11,
      fontWeight: 700,
      lineHeight: 24 / 11,
      letterSpacing: '.02em',
      textTransform: 'uppercase',
      padding: '11px 15px',

      '&, &:hover': {
        background: theme.palette.grey[400],
      },

      '&$selected, &$selected:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.text.primary,
      },
    },
    selected: {},
  }),
  {
    name: 'ToggleButton',
  }
);
