import {
  MenuItem as MuiMenuItem,
  menuItemClasses,
  MenuItemProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({ theme }) => ({
  ...theme.typography.p2,
  padding: '12px 16px',
  color: theme.palette.grey['100'],

  '&:hover': {
    background: theme.palette.grey['20'],
  },

  [`&.${menuItemClasses.selected}`]: {
    background: theme.palette.yellow['40'],
  },

  [`&.${menuItemClasses.disabled}`]: {
    color: theme.palette.grey['60'],
  },
}));

MenuItem.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
};

export default MenuItem;
