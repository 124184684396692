/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContentProps as MuiDialogContentProps,
  withStyles,
  Box,
} from '@material-ui/core';
import React from 'react';

import DialogContent from '../DialogContent/DialogContent';
import DialogStyles from './Dialog.styles';

import { Button, Icon } from '@crpt-ui/core';

interface DialogExtraProps extends MuiDialogProps {
  header?: React.ReactNode;
  withCloseIcon?: boolean;
  DialogContentProps?: MuiDialogContentProps;
}

const Dialog: React.FC<DialogExtraProps> = ({
  children,
  fullWidth = true,
  scroll = 'body',
  transitionDuration = 0,
  onClose,
  withCloseIcon,
  header,
  DialogContentProps,
  ...rest
}) => (
  <MuiDialog
    fullWidth={fullWidth}
    onClose={onClose}
    scroll={scroll}
    transitionDuration={transitionDuration}
    {...rest}
  >
    {header || withCloseIcon ? (
      <Box pt={4} px={4}>
        {header ? <Box pr={withCloseIcon ? 4 : 0}>{header}</Box> : null}
        {withCloseIcon ? (
          <Box position="absolute" right={32} top={32}>
            <Button
              icon={<Icon name="close" />}
              // @ts-ignore
              onClick={onClose}
              size="sm"
              variant="text"
            />
          </Box>
        ) : null}
      </Box>
    ) : null}
    <DialogContent {...DialogContentProps}>{children}</DialogContent>
  </MuiDialog>
);

export default withStyles(DialogStyles)(Dialog);
