import React from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { styles } from './Breadcrumbs.styles';
import {
  RouteParams,
  BreadcrumbsProps,
  Route,
  RoutesList,
  RouteIdProps,
} from './Breadcrumbs.types';

function getRouteParams(
  route: string,
  previousRoute: string,
  routesList: RoutesList
): RouteParams {
  const prevRoute: string = previousRoute === '/' ? '' : previousRoute;
  const idPrefix: string = routesList[`${prevRoute}/:id`];
  let name: string = routesList[`${prevRoute}/${route}`];
  let routeId = '';

  if (!name && typeof idPrefix === 'string') {
    name = idPrefix;
    routeId = route;
  }

  return { name, routeId };
}

function getRoutesNames(
  fullRoute = '',
  routesList: RoutesList
): Array<RouteParams> {
  const routesArray: Array<string> = fullRoute.split('/').filter((x) => x);
  const clearRoutesArray: Array<string> = [];
  const namesArray: Array<RouteParams> = [];

  routesArray.forEach((currentRoute, index) => {
    const previousRoute = `/${clearRoutesArray.slice(0, index).join('/')}`;
    const routeParams: RouteParams = getRouteParams(
      currentRoute,
      previousRoute,
      routesList
    );

    clearRoutesArray.push(routeParams.routeId ? ':id' : currentRoute);

    if (routeParams.name) {
      const prevRoute = previousRoute === '/' ? '' : previousRoute;
      namesArray.push({
        name: routeParams.name,
        path: `${prevRoute}/${currentRoute}`,
        isLast: index === routesArray.length - 1,
        routeId: routeParams.routeId,
      });
    }
  });
  return namesArray;
}

function prepareRoutesObject(routes: Route[], res?: RoutesList): RoutesList {
  let result: RoutesList = { ...res };
  routes.forEach((route: Route) => {
    if (route.path && route.title) {
      if (typeof route.path === 'string') {
        result[route.path] = route.title;
      } else {
        route.path.forEach((path: string) => {
          if (route.title) {
            result[path] = route.title;
          }
        });
      }
    }
    if (route.routes) {
      const res = prepareRoutesObject(route.routes);
      result = { ...result, ...res };
    }
  });
  return result;
}

const LinkRouter = (props: any) => <Link {...props} />;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  routes,
  location,
  linkComponent,
  classes,
}: BreadcrumbsProps) => {
  const namesArray = getRoutesNames(
    location.pathname,
    prepareRoutesObject(routes)
  );
  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      classes={{
        root: classes.root,
        separator: classes.separator,
        ol: classes.ol,
      }}
    >
      {namesArray.map((route) => {
        return route.isLast ? (
          <Typography
            color="textPrimary"
            key={route.path}
            className={clsx(classes.nav, classes.lastNav)}
          >
            {route.name}
            {namesArray.length === 1 ? (
              <span className={classes.separator}>/</span>
            ) : (
              ''
            )}
            <RouteId className={classes.id} routeId={route.routeId} />
          </Typography>
        ) : (
          <LinkRouter
            component={linkComponent}
            className={classes.nav}
            to={route.path}
            key={route.path}
          >
            {route.name}
            <RouteId className={classes.id} routeId={route.routeId} />
          </LinkRouter>
        );
      })}
    </MuiBreadcrumbs>
  );
};

const RouteId: React.FC<RouteIdProps> = ({ routeId, className }) => {
  if (!routeId) return null;
  return (
    <React.Fragment>
      &nbsp;<span className={className}>{decodeURIComponent(routeId)}</span>
    </React.Fragment>
  );
};

export default withStyles(styles)(Breadcrumbs);
