import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    headerWrapper: {
      padding: '8px 16px',
      color: theme.palette.text.primary,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '14px',
    },
  });
