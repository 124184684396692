import { ButtonBase as MuiButtonBase, ButtonBaseProps } from '@mui/material';
import { defaultTheme, styled } from '../theme';

export const ButtonBase = styled(MuiButtonBase)<ButtonBaseProps>(
  ({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    letterSpacing: 'normal',
  })
);

ButtonBase.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
};
