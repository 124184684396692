import {
  FormHelperText as MuiFormHelperText,
  FormHelperTextProps,
  formHelperTextClasses,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const FormHelperText = styled(MuiFormHelperText)<FormHelperTextProps>(
  ({ theme }) => ({
    [`&.${formHelperTextClasses.root}`]: {
      ...theme.typography.p3,
      margin: '4px 0 0',
      color: theme.palette.grey['60'],

      [`&.${formHelperTextClasses.error}`]: {
        color: theme.palette.red['70'],
      },

      [`&.${formHelperTextClasses.disabled}`]: {
        color: theme.palette.grey['60'],
      },
    },
  })
);

FormHelperText.defaultProps = {
  theme: defaultTheme,
};

export default FormHelperText;
