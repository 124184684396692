import {
  FormControlLabel as MuiFormControlLabel,
  formControlLabelClasses,
  FormControlLabelProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    margin: 0,
    userSelect: 'none',

    ['.Mui-checked']: {
      span: {
        background: theme.palette.yellow['40'],
      },
      'input:not(:disabled):hover~span': {
        background: theme.palette.yellow['40'],
      }
    },

    [`.${formControlLabelClasses.label}`]: {
      ...theme.typography.p2,
      color: theme.palette.grey['100'],
      margin: '0 0 0 12px',
    },

    [`&.${formControlLabelClasses.labelPlacementTop}`]: {
      [`.${formControlLabelClasses.label}`]: {
        margin: '0 0 12px 0',
      },
    },

    [`&.${formControlLabelClasses.labelPlacementStart}`]: {
      [`.${formControlLabelClasses.label}`]: {
        margin: '0 12px 0 0',
      },
    },

    [`&.${formControlLabelClasses.labelPlacementBottom}`]: {
      [`.${formControlLabelClasses.label}`]: {
        margin: '12px 0 0 0',
      },
    },
  })
);

FormControlLabel.defaultProps = {
  theme: defaultTheme,
};

export default FormControlLabel;
