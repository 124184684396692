import {
  IconButton as MuiIconButton,
  iconButtonClasses,
  IconButtonProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const IconButton = styled(MuiIconButton)<IconButtonProps>(({ theme }) => ({
  ...theme.typography.p2,
  borderRadius: 8,

  [`&.${iconButtonClasses.sizeMedium}`]: {
    minWidth: 52,
    minHeight: 52,
  },

  color: theme.palette.blue['70'],

  '&:hover': {
    background: theme.palette.grey['30'],
    color: theme.palette.blue['100'],
  },

  '&:active': {
    color: theme.palette.grey['100'],
  },

  [`&.${iconButtonClasses.disabled}`]: {
    background: theme.palette.grey['20'],
    color: theme.palette.grey['60'],
  },
}));

IconButton.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
};

export default IconButton;
