import { ButtonBase, ButtonBaseProps, defaultTheme, styled } from '../index';
import React, {
  ElementType,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Popover } from '@mui/material';
import { PopoverProps } from '@mui/material/Popover/Popover';

export interface DropdownButtonProps
  extends Omit<ButtonBaseProps | PropsWithChildren<any>, 'onClick'> {
  component?: ElementType;
  content?: (closePopup: () => void) => React.ReactNode;
  popoverProps?: Partial<Omit<PopoverProps, 'open' | 'anchorEl' | 'onClose'>>;
  onOpen?: () => void;
  onClose?: () => void;
}

const DropdownDefaultComponent = styled(ButtonBase)<ButtonBaseProps>({});
DropdownDefaultComponent.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
};

export function Dropdown({
  component,
  content,
  onClose,
  onOpen,
  popoverProps,
  ...componentProps
}: DropdownButtonProps) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    const isOpen = !open;
    setOpen(isOpen);
    if (isOpen) {
      onOpen && onOpen();
    }
  }, [open]);
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, [onClose]);

  const Component = component || DropdownDefaultComponent;

  return (
    <>
      <Component {...componentProps} ref={anchorRef} onClick={handleClick} />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...popoverProps}
        PaperProps={{
          ...popoverProps?.PaperProps,
          style: {
            marginTop: '4px',
            borderRadius: '8px',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.08)',
            minWidth: 282,
            ...popoverProps?.PaperProps?.style,
          },
        }}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        open={open}
        transitionDuration={0}
      >
        {content?.(handleClose)}
      </Popover>
    </>
  );
}
