export type Time = {
  hours: string;
  minutes: string;
  seconds: string;
};

export enum TimeType {
  minutes = 'minutes',
  hours = 'hours',
  seconds = 'seconds',
}

export enum ChangeTimeType {
  decrease = 'decrease',
  increase = 'increase',
}

export enum TimeActions {
  INCREASE_HOURS = 'increaseHours',
  INCREASE_MINUTES = 'increaseMinutes',
  INCREASE_SECONDS = 'increaseSeconds',
  DECREASE_HOURS = 'decreaseHours',
  DECREASE_MINUTES = 'decreaseMinutes',
  DECREASE_SECONDS = 'decreaseSeconds',
  CHANGE_MINUTES = 'changeMinutes',
  CHANGE_HOURS = 'changeHours',
  CHANGE_SECONDS = 'changeSeconds',
  RESET_VALUES = 'resetValues',
}

type IncreaseHours = {
  type: TimeActions.INCREASE_HOURS;
};

type IncreaseMinutes = {
  type: TimeActions.INCREASE_MINUTES;
};

type IncreaseSeconds = {
  type: TimeActions.INCREASE_SECONDS;
};

type DecreaseHours = {
  type: TimeActions.DECREASE_HOURS;
};

type DecreaseMinutes = {
  type: TimeActions.DECREASE_MINUTES;
};

type DecreaseSeconds = {
  type: TimeActions.DECREASE_SECONDS;
};

type ResetValues = {
  type: TimeActions.RESET_VALUES;
};

type ChangeHours = {
  type: TimeActions.CHANGE_HOURS;
  value: string;
};

type ChangeMinutes = {
  type: TimeActions.CHANGE_MINUTES;
  value: string;
};

type ChangeSeconds = {
  type: TimeActions.CHANGE_SECONDS;
  value: string;
};

export type TimeStateActions =
  | IncreaseHours
  | IncreaseMinutes
  | IncreaseSeconds
  | DecreaseHours
  | DecreaseMinutes
  | DecreaseSeconds
  | ChangeSeconds
  | ChangeMinutes
  | ChangeHours
  | ResetValues;
