import { TableDataRow } from './TableDataRow';
import { List } from 'react-virtualized';
import React from 'react';
import { ListProps } from 'react-virtualized/dist/es/List';
import { TableGridInstance } from '../TableGrid.types';

interface TableFixedRowsProps<D extends Record<string, any>>
  extends Omit<
    ListProps,
    'rowRenderer' | 'rowCount' | 'scrollToIndex' | 'rowHeight'
  > {
  tableInstance: TableGridInstance<D>;
  width: number;
  rowHeight?: number;
  horizontalPadding: number;
  maxDepth: number;
}

const defaultRowHeight = 100;

export function TableFixedRows<D extends Record<string, any>>(
  props: TableFixedRowsProps<D>
) {
  return (
    <List
      autoHeight
      height={props.height}
      isScrolling={props.isScrolling}
      onScroll={props.onChildScroll}
      overscanRowCount={props.overscanRowCount}
      rowCount={props.tableInstance.rows.length}
      rowHeight={props.rowHeight || defaultRowHeight}
      rowRenderer={(rowProps) => (
        <TableDataRow
          key={rowProps.key}
          rowIndex={rowProps.index}
          horizontalPadding={props.horizontalPadding}
          style={rowProps.style}
          tableInstance={props.tableInstance}
          width={props.width}
          maxDepth={props.maxDepth}
        />
      )}
      scrollToIndex={-1}
      scrollTop={props.scrollTop}
      width={props.width}
    />
  );
}
