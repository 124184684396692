import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.text.primary,
      boxSizing: 'border-box',
      color: theme.palette.common.white,
    },
    gutters: {
      padding: '16px 64px 16px 16px',
    },
    close: {
      position: 'absolute',
      right: 16,
      top: 16,
    },
  });
