import React, { ComponentType, memo, useMemo } from 'react';
import {
  FilterEditor,
  FilterRenderProps,
  FilterValidationHandler,
} from '../../TableFilter';
import { LookupInput, LookupInputProps } from '@crpt/material';
import { AutocompleteValue } from '@mui/material';

type FilterValue<Value, Multiple> = AutocompleteValue<
  Value,
  Multiple,
  false,
  false
>;

export interface LookupFilterParams<
  Value,
  Option,
  Multiple extends boolean | undefined
> extends Pick<
    LookupInputProps<Value, Option, Multiple>,
    | 'options'
    | 'getOptionValue'
    | 'getOptionLabel'
    | 'multiple'
    | 'label'
    | 'placeholder'
    | 'helperText'
  > {
  title?: React.ReactNode;
  OptionItemComponent?: ComponentType<Option>;
  validator?: FilterValidationHandler<FilterValue<Value, Multiple>>;
}

export function makeLookupFilter<
  Option extends Record<string, any>,
  Value extends string | number = string,
  Multiple extends boolean | undefined = false
>(filterParams: LookupFilterParams<Value, Option, Multiple>) {
  const {
    title,
    multiple,
    placeholder,
    label,
    helperText,
    validator,
    options,
    getOptionLabel,
    getOptionValue,
    OptionItemComponent,
  } = filterParams;

  return memo((props: FilterRenderProps<FilterValue<Value, Multiple>>) => {
    const {
      column,
      filterValue,
      setFilterValue,
      useValidator,
      executeValidation,
    } = props;
    const { error } = useValidator(validator);
    const normalizedValue = useMemo<FilterValue<Value, Multiple>>(() => {
      if (filterValue == null || filterValue === '') {
        return (multiple ? [] : null) as FilterValue<Value, Multiple>;
      }
      return filterValue;
    }, [filterValue]);

    return (
      <FilterEditor column={column} error={error} title={title}>
        <LookupInput
          name={column.id}
          multiple={multiple}
          value={normalizedValue}
          options={options}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          renderOptionItem={(option) => {
            if (OptionItemComponent) {
              return <OptionItemComponent {...option} />;
            }
          }}
          onBlur={executeValidation}
          onChange={(_, value) => setFilterValue(value)}
          error={Boolean(error)}
        />
      </FilterEditor>
    );
  });
}
