import React from 'react';
import * as svg from './svg';
import { IconPlaceholder_medium } from './svg';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: keyof typeof svg;
}

export function Icon(props: IconProps) {
  const { name, ...svgProps } = props;
  const Svg = svg[name] || IconPlaceholder_medium;
  return <Svg {...svgProps} />;
}
