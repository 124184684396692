import { makeStyles, Theme } from '@material-ui/core';

import { getColor, getContrastColor } from '../utils';

export default makeStyles(
  (theme: Theme) => ({
    root: {
      alignItems: 'center',
      backgroundColor: (props: any) => getColor(props.color, theme),
      borderRadius: 20,
      boxSizing: 'border-box',
      color: (props: any) => getContrastColor(props.color, theme),
      display: 'inline-flex',
      fontSize: 10,
      fontWeight: 700,
      height: theme.spacing(5),
      letterSpacing: '0.02em',
      padding: '0 16px',
      textTransform: 'uppercase',
    },
    icon: {
      marginRight: 8,
    },
    label: {
      whiteSpace: 'nowrap',
    },
  }),
  {
    name: 'Status',
  }
);
