import { Button as MuiButton, buttonClasses, ButtonProps } from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Button = styled(MuiButton)<ButtonProps>(({ theme }) => ({
  [`&.${buttonClasses.root}`]: {
    ...theme.typography.p2,
    color: theme.palette.grey['100'],
    borderRadius: 8,
    textTransform: 'none',
  },

  [`&.${buttonClasses.sizeLarge}`]: {
    minHeight: 52,
    padding: '15px 23px',

    [`&.${buttonClasses.text}`]: {
      padding: '15px 0',
    }
  },

  [`&.${buttonClasses.sizeSmall}`]: {
    padding: '7px 23px',
    minHeight: 36,

    [`&.${buttonClasses.text}`]: {
      padding: '7px 0',
    }
  },

  /* Contained Primary - Yellow */
  [`&.${buttonClasses.containedPrimary}`]: {
    border: `1px solid ${theme.palette.yellow['40']}`,
    background: theme.palette.yellow['40'],

    '&:hover': {
      border: `1px solid ${theme.palette.yellow['100']}`,
    },

    '&:active': {
      backgroundColor: theme.palette.yellow['60'],
    },

    [`&.${buttonClasses.disabled}`]: {
      background: theme.palette.yellow['10'],
      border: `1px solid ${theme.palette.yellow['10']}`,
    },
  },

  /* Contained Secondary - Gray */
  [`&.${buttonClasses.containedSecondary}`]: {
    background: theme.palette.grey['30'],
    color: theme.palette.grey['100'],
    border: `1px solid ${theme.palette.grey['30']}`,

    '&:hover': {
      border: `1px solid ${theme.palette.grey['50']}`,
    },

    '&:active': {
      backgroundColor: theme.palette.grey['40'],
    },

    [`&.${buttonClasses.disabled}`]: {
      border: `1px solid ${theme.palette.grey['20']}`,
      background: theme.palette.grey['20'],
    },
  },

  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: 16,
    marginRight: -8,
  },
  [`& .${buttonClasses.startIcon}`]: {
    marginRight: 16,
    marginLeft: -8,
  },

  /* Text - Blue */
  [`&.${buttonClasses.text}`]: {
    color: theme.palette.blue['70'],
    background: 'none',
    minWidth: 32,

    '&:hover': {
      color: theme.palette.blue['100'],
    },
    '&:active': {
      color: theme.palette.grey['100'],
    },

    [`& .${buttonClasses.endIcon}`]: {
      marginLeft: 8,
      marginRight: 0,
    },
    [`& .${buttonClasses.startIcon}`]: {
      marginRight: 8,
      marginLeft: 0,
    },
  },

  [`&.${buttonClasses.disabled}`]: {
    color: theme.palette.grey['60'],
  },

}));

Button.defaultProps = {
  disableElevation: true,
  disableRipple: true,
  variant: 'contained',
  size: 'large',
  theme: defaultTheme,
};

export default Button;
