import { createStyles } from '@material-ui/core';

export const styles = (theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '32px',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      fontSize: '10px',
      lineHeight: '32px',
      marginBottom: '32px',
      '& $separator': {
        margin: '0 16px',
      },
    },
    separator: {
      marginRight: '16px',
    },
    ol: {
      padding: '0 16px',
    },
    nav: {
      textTransform: 'uppercase',
      fontSize: '10px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
      letterSpacing: '0.02em',
      display: 'table-cell',
      lineHeight: '32px',
    },
    lastNav: {
      color: theme.palette.text.disabled,
    },
    id: {
      textTransform: 'none',
    },
  });
