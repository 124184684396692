import React from 'react';
import {
  SnackbarProvider as OriginSnackbarProvider,
  SnackbarProviderProps,
} from 'notistack';
import { SnackbarServiceConfigurator } from './Snackbar';
import { styled } from '../theme';
import { collapseClasses } from '@mui/material';

const StyledSnackbarProvider = styled((props: SnackbarProviderProps) => (
  <OriginSnackbarProvider
    classes={{ containerRoot: props.className }}
    {...props}
  >
    {props.children}
  </OriginSnackbarProvider>
))<SnackbarProviderProps>({
  gap: 16,
  '&.SnackbarContainer-left': {
    left: 24,
  },
  '&.SnackbarContainer-bottom': {
    bottom: 24,
  },
  [`&.SnackbarContainer-root>.${collapseClasses.root}>.${collapseClasses.wrapper}`]:
    {
      padding: 0,
    },
});

export function SnackbarProvider(props: SnackbarProviderProps) {
  return (
    <StyledSnackbarProvider
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      autoHideDuration={10000}
      maxSnack={3}
      {...props}
    >
      <SnackbarServiceConfigurator />
      {props.children}
    </StyledSnackbarProvider>
  );
}
