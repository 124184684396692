import {
  Box,
  BoxProps,
  CircularProgress as MuiCircularProgress,
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';
import React from 'react';

const ThrobberRoot = styled(MuiCircularProgress)<CircularProgressProps>(() => ({
  [`&.${circularProgressClasses.root}`]: {
    position: 'absolute',
    left: 0,
    [`.${circularProgressClasses.circle}`]: {
      strokeDasharray: '95px,200px',
    },
  },
}));

ThrobberRoot.defaultProps = {
  variant: 'indeterminate',
  theme: defaultTheme,
};

const ThrobberCircle = styled(MuiCircularProgress)<CircularProgressProps>(
  () => ({
    [`&.${circularProgressClasses.root}`]: {
      position: 'absolute',
      left: 0,
    },
  })
);

ThrobberCircle.defaultProps = {
  variant: 'determinate',
  value: 100,
};

const throbberColors = {
  blue: {
    color: defaultTheme.palette.blue['70'],
    circleColor: defaultTheme.palette.grey['30'],
  },
  yellow: {
    color: defaultTheme.palette.yellow['40'],
    circleColor: defaultTheme.palette.grey['60'],
  },
  default: {
    color: defaultTheme.palette.grey['60'],
    circleColor: defaultTheme.palette.grey['30'],
  },
};

const throbberSize = {
  xl: { size: 32, thickness: 3 },
  large: { size: 24, thickness: 4 },
  medium: { size: 20, thickness: 4 },
  small: { size: 16, thickness: 4 },
};

export interface ThrobberProps extends Omit<BoxProps, 'color'> {
  variant?: 'blue' | 'yellow';
  circleColor?: string;
  color?: string;
  size?: 'xl' | 'large' | 'medium' | 'small';
  disableShrink?: boolean;
  disabled?: boolean;
}

const Throbber = (props: ThrobberProps) => {
  const {
    variant,
    size: sizeVariant,
    disableShrink,
    disabled,
    ...boxProps
  } = props;
  const {
    color = throbberColors.default.color,
    circleColor = throbberColors.default.circleColor,
  } = variant ? throbberColors[variant] || throbberColors.default : props;

  const { size, thickness } = sizeVariant
    ? throbberSize[sizeVariant] || throbberSize.medium
    : throbberSize.medium;

  return (
    <Box {...boxProps}>
      <Box sx={{ position: 'relative', width: size, height: size }}>
        <ThrobberCircle
          size={size}
          sx={{ color: circleColor }}
          thickness={thickness}
        />
        {!disabled && (
          <ThrobberRoot
            disableShrink={disableShrink}
            size={size}
            sx={{ color }}
            thickness={thickness}
          />
        )}
      </Box>
    </Box>
  );
};

export default Throbber;
