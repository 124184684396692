export type Subject = {
  CN: string;
  INN: string;
};

export type Certificate = {
  issuer: Subject;
  serialNumber: string;
  subject: Subject;
  valid: boolean;
  validFromDate: string;
  validToDate: string;
};

export enum SignatureViewDirection {
  vertical = 'vertical',
  horizontal = 'horizontal',
}
