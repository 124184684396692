/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Icons from '@crpt-ui/icons';
import { capitalize, SvgIconProps } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';

export type IconProps = {
  name: string;
} & SvgIconProps &
  React.ComponentProps<'span'>;

const Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  ({ name, ...rest }, ref) => {
    // @ts-ignore
    const Component = useMemo(() => Icons[capitalize(name)], [name]);

    useEffect(() => {
      if (typeof Component === 'undefined') {
        console.warn(`@crpt-ui: Can’t find '${name}' icon`);
      }
    }, [Component, name]);

    return Component ? <Component ref={ref} {...rest} /> : null;
  }
);

export default Icon;
