import {
  Divider as MuiDivider,
  dividerClasses,
  DividerProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Divider = styled(MuiDivider)<DividerProps>(({ theme }) => ({
  [`&.${dividerClasses.root}`]: {
    fontFamily: theme.typography.fontFamily,
    borderColor: theme.palette.grey['30'],

    [`&.${dividerClasses.withChildren}`]: {
      '::after, ::before': {
        borderColor: theme.palette.grey['30'],
      },
    },
  },
}));

Divider.defaultProps = {
  theme: defaultTheme,
};

export default Divider;
