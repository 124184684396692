import React, { memo, useCallback } from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import { useTime } from './hooks';
import { Time } from './TimePicker.types';

import { TimePickerHeader } from './components/TimePickerHeader';
import { TimePickerBody } from './components/TimePickerBody';
import { TimePickerFooter } from './components/TimePickerFooter';
import Divider from '../Divider/Divider';

import { TimeAnnotationStyles } from './TimePicker.styles';

type TimePickerProps = {
  onSubmit: (value: Time) => void;
  onClose: () => void;
  initialTime?: Time;
  isFilter?: boolean;
};

const TimePicker: React.FC<TimePickerProps> = memo(
  ({ onClose, onSubmit, initialTime, isFilter = false }) => {
    const {
      state,
      increaseMinutes,
      increaseHours,
      increaseSeconds,
      decreaseSeconds,
      decreaseMinutes,
      decreaseHours,
      changeSeconds,
      changeMinutes,
      changeHours,
      resetValues,
    } = useTime(initialTime);

    const onResetValue = useCallback(() => {
      resetValues();
      onSubmit({
        hours: '',
        minutes: '',
        seconds: '',
      });
    }, []);
    return (
      <div>
        <TimePickerHeader time={state} isFilter={isFilter} onClose={onClose} />
        <Divider />
        <TimePickerBody
          time={state}
          increaseHours={increaseHours}
          increaseMinutes={increaseMinutes}
          increaseSeconds={increaseSeconds}
          decreaseHours={decreaseHours}
          decreaseMinutes={decreaseMinutes}
          decreaseSeconds={decreaseSeconds}
          changeHours={changeHours}
          changeMinutes={changeMinutes}
          changeSeconds={changeSeconds}
        />
        <Divider />
        <TimePickerAnnotation />
        <Divider />
        {!isFilter && (
          <TimePickerFooter
            onSubmit={onSubmit}
            currentTimeValue={state}
            onReset={onResetValue}
          />
        )}
      </div>
    );
  }
);

const TimePickerAnnotationComponent: React.FC<
  WithStyles<typeof TimeAnnotationStyles>
> = memo(({ classes }) => {
  return (
    <div className={classes.content}>
      Время должно быть в&nbsp;24-часовом формате чч:мм:сс
    </div>
  );
});

const TimePickerAnnotation = withStyles(TimeAnnotationStyles)(
  TimePickerAnnotationComponent
);

export default TimePicker;
