import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@material-ui/core';
import React, { FC } from 'react';

import Radio, { RadioProps } from '../Radio/Radio';

export type RadioGroupProps = {
  options: RadioProps[];
} & MuiRadioGroupProps;

const RadioGroup: FC<RadioGroupProps> = ({ options = [], ...rest }) => (
  <MuiRadioGroup {...rest}>
    {options.map((option, index) => (
      <Radio key={index} {...option} name={`radio-${option.value}`} />
    ))}
  </MuiRadioGroup>
);

export default RadioGroup;
