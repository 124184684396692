import { TableDataRow } from './TableDataRow';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import React, { useEffect, useMemo, useRef } from 'react';
import { ListProps } from 'react-virtualized/dist/es/List';
import { TableGridInstance } from '../TableGrid.types';

interface TableFixedRowsProps<D extends Record<string, any>>
  extends Omit<
    ListProps,
    'rowRenderer' | 'rowCount' | 'scrollToIndex' | 'rowHeight'
  > {
  tableInstance: TableGridInstance<D>;
  width: number;
  horizontalPadding: number;
  rowKey?: (index: number) => number | string;
  maxDepth: number;
}

export function TableFlexibleRows<D extends Record<string, any>>(
  props: TableFixedRowsProps<D>
) {
  const measureCache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        fixedHeight: false,
        keyMapper: props.rowKey,
      }),
    []
  );

  const listRef = useRef<List | null>(null);

  const columnConfigHash = useMemo(
    () => props.tableInstance.visibleColumns.map((col) => col.id).join('|'),
    [props.tableInstance.visibleColumns]
  );

  useEffect(() => {
    measureCache.clearAll();
    listRef.current?.forceUpdateGrid();
  }, [columnConfigHash, props.width]);

  useEffect(() => {
    listRef.current?.recomputeGridSize();
  }, [props.tableInstance.state.expanded, props.tableInstance.state.sortBy]);

  return (
    <List
      autoHeight
      deferredMeasurementCache={measureCache}
      height={props.height}
      isScrolling={props.isScrolling}
      onScroll={props.onChildScroll}
      overscanRowCount={props.overscanRowCount}
      ref={listRef}
      rowCount={props.tableInstance.rows.length}
      rowHeight={measureCache.rowHeight}
      rowRenderer={(rowProps) => {
        const top = Number(rowProps.style.top) - props.scrollTop;
        const isPartiallyVisible = top < 0 && top > -props.height / 2;

        if (!rowProps.isVisible && !isPartiallyVisible) {
          return null;
        }
        return (
          <CellMeasurer
            cache={measureCache}
            columnIndex={0}
            key={rowProps.key}
            parent={rowProps.parent}
            rowIndex={rowProps.index}
          >
            <TableDataRow
              key={rowProps.key}
              rowIndex={rowProps.index}
              horizontalPadding={props.horizontalPadding}
              style={rowProps.style}
              tableInstance={props.tableInstance}
              width={props.width}
              maxDepth={props.maxDepth}
            />
          </CellMeasurer>
        );
      }}
      scrollToIndex={-1}
      scrollTop={props.scrollTop}
      width={props.width}
    />
  );
}
