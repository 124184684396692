import React from 'react';
import { AutoSizer, WindowScroller } from 'react-virtualized';
import { Box, BoxProps, defaultTheme, styled } from '@crpt/material';
import { TableHeaderRow } from './components/TableHeaderRow';
import { TableInstance } from 'react-table';
import { TableFixedRows } from './components/TableFixedRows';
import { TableFlexibleRows } from './components/TableFlexibleRows';
import { TableGridInstance } from './TableGrid.types';

interface TableGridProps<D extends Record<string, any>>
  extends Omit<BoxProps, 'children'> {
  tableInstance: TableInstance<D>;
  rowHeight?: number;
  overscanRowCount?: number;
  flexibleRows?: boolean;
  horizontalPadding: number;
  footerBackground?: string;
  rowKey?: (index: number, row: D) => number | string;
  maxDepth?: number;
  isHeaderRowActive: boolean;
}

const defaultRowHeight = 100;

const TableGridRoot = styled(Box)(() => ({
  '.ReactVirtualized__List': {
    outline: 'none',
  },
}));

TableGridRoot.defaultProps = {
  theme: defaultTheme,
};

const defaultMaxDepth = 3;

export default function TableGrid<D extends Record<string, any>>({
  tableInstance,
  rowHeight,
  overscanRowCount,
  flexibleRows,
  horizontalPadding,
  footerBackground,
  rowKey,
  maxDepth,
  isHeaderRowActive,
  ...boxProps
}: TableGridProps<D>) {
  const TableRows = flexibleRows ? TableFlexibleRows : TableFixedRows;

  const { expandedDepth } = tableInstance as TableGridInstance<D>;

  return (
    <TableGridRoot {...boxProps}>
      <WindowScroller scrollElement={window}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => {
              const actualWidth = Math.max(
                tableInstance.totalColumnsWidth + horizontalPadding * 2,
                width
              );

              return (
                <Box ref={registerChild}>
                  {isHeaderRowActive && (
                    <TableHeaderRow
                    horizontalPadding={horizontalPadding}
                    tableInstance={tableInstance as TableGridInstance<D>}
                    width={actualWidth}
                    maxDepth={maxDepth || expandedDepth || defaultMaxDepth}
                  />
                  )}
                  <TableRows
                    rowKey={
                      rowKey
                        ? (i) => rowKey(i, tableInstance.rows[i].original)
                        : undefined
                    }
                    height={height}
                    horizontalPadding={horizontalPadding}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    overscanRowCount={overscanRowCount}
                    rowHeight={rowHeight}
                    scrollTop={scrollTop}
                    tableInstance={tableInstance as TableGridInstance<D>}
                    width={actualWidth}
                    maxDepth={maxDepth || expandedDepth || defaultMaxDepth}
                  />
                </Box>
              );
            }}
          </AutoSizer>
        )}
      </WindowScroller>
    </TableGridRoot>
  );
}

TableGrid.defaultProps = {
  rowHeight: defaultRowHeight,
  overscanRowCount: 2,
  flexibleRows: false,
  horizontalPadding: 24,
};
