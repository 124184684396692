import React, { useCallback, useMemo, useState } from 'react';
import { Dialog } from '@crpt/material';
import {
  ColumnHeaderRenderHandler,
  ColumnListItem,
  ColumnOrderableTableInstance,
} from './TableConfig.types';
import { TableConfigForm } from './components/TableConfigForm';
import { getTableConfig } from './TableConfig.tools';
import {
  ColumnHeaderRenderer,
  customHeaderRenderer,
  defaultHeaderRenderer,
} from './components/ColumnHeaderRendererContext';

interface TableConfigProps<T extends Record<string, any>> {
  title?: string;
  description?: string;
  applyLabel?: string;
  resetLabel?: string;
  tableInstance: ColumnOrderableTableInstance<T>;
  onRenderColumnHeader?: ColumnHeaderRenderHandler<T>;
  children: (open: () => void) => React.ReactNode;
}

export function TableConfig<T extends Record<string, any>>({
  title,
  description,
  applyLabel,
  resetLabel,
  children,
  tableInstance,
  onRenderColumnHeader,
}: TableConfigProps<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeDialog = useCallback(() => setIsOpen(false), []);
  const openDialog = useCallback(() => setIsOpen(true), []);
  const disableVisibilityToggle = tableInstance.state.alwaysVisibleColumns;
  const hasDefaultConfig = Boolean(tableInstance.state.defaultConfig);
  const columnHeaderRenderer = useMemo(
    () =>
      onRenderColumnHeader
        ? customHeaderRenderer(onRenderColumnHeader)
        : defaultHeaderRenderer,
    [onRenderColumnHeader]
  );

  const submit = useCallback(
    (columnList: ColumnListItem<T>[]) => {
      if (tableInstance.applyConfig) {
        const config = getTableConfig(columnList);
        tableInstance.applyConfig(config);
        closeDialog();
      }
    },
    [closeDialog, tableInstance]
  );

  const applyDefault = useCallback(() => {
    if (hasDefaultConfig && tableInstance.applyDefaultConfig) {
      tableInstance.applyDefaultConfig();
      closeDialog();
    }
  }, [closeDialog, hasDefaultConfig, tableInstance]);

  return (
    <>
      {children(openDialog)}
      <Dialog maxWidth="md" open={isOpen} onClose={closeDialog}>
        <ColumnHeaderRenderer.Provider value={columnHeaderRenderer}>
          <TableConfigForm
            applyLabel={applyLabel || 'Сохранить'}
            tableInstance={tableInstance}
            description={description || 'Порядок и видимость колонок'}
            disableVisibilityToggle={disableVisibilityToggle}
            onClose={closeDialog}
            onReset={hasDefaultConfig ? applyDefault : undefined}
            onApply={submit}
            resetLabel={resetLabel || 'Сбросить настройки'}
            title={title || 'Настройка таблицы'}
          />
        </ColumnHeaderRenderer.Provider>
      </Dialog>
    </>
  );
}
