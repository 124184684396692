import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps,
  circularProgressClasses,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const CircularProgress = styled(MuiCircularProgress)<CircularProgressProps>(
  ({ theme }) => ({
    [`&.${circularProgressClasses.root}`]: {
      color: theme.palette.grey['60'],
      [`.${circularProgressClasses.circle}`]: {
        strokeDasharray: '95px,200px',
      },
    },
  })
);

CircularProgress.defaultProps = {
  theme: defaultTheme,
  size: 20,
  thickness: 4,
};

export default CircularProgress;
