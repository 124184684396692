import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      height: 24,
      lineHeight: 16 / 12,
      minWidth: 24,
      padding: `0 ${theme.spacing(0.5)}px`,

      '& + &': {
        marginLeft: theme.spacing(0.5),
      },
    },
    disabled: {
      opacity: 0.5,
    },
    selected: {
      background: theme.palette.primary.main,
    },
  });
