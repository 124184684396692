/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

export const resolve = (
  defaultValue: any,
  values?: { [key: string]: any },
  value?: string
) => {
  if (typeof value !== 'undefined' && typeof values !== 'undefined') {
    return values[value] ?? defaultValue;
  }

  return defaultValue;
};

export const useResolved = (
  defaultValue: any,
  values?: {
    [key: string]: any;
  },
  value?: string
): any =>
  useMemo(() => resolve(defaultValue, values, value), [
    defaultValue,
    value,
    values,
  ]);
