import React, { CSSProperties, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  ColumnDragObject,
  ColumnListItem,
  DND_COLUMN,
} from '../TableConfig.types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Box, Icons } from '@crpt/material';
import { ColumnHeaderRenderer } from './ColumnHeaderRendererContext';

interface DraggableColumnListItemProps {
  visibilityToggle?: boolean;
  item: ColumnListItem<any>;
  onMove: (draggedId: string | number, afterId: string | number) => void;
  onVisibilityToggle: (columnId: string | number, isVisible?: boolean) => void;
}

const columnIsDragging: CSSProperties = {
  opacity: 0.3,
};
const columnDefault: CSSProperties = {
  opacity: 1,
};

export function DraggableColumnListItem({
  visibilityToggle,
  item,
  onMove,
  onVisibilityToggle,
}: DraggableColumnListItemProps) {
  const { column, isVisible } = item;
  const dragHandleRef = useRef<Element>(null);
  const ref = useRef<Element>(null);

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: DND_COLUMN,
    item: {
      column,
      isVisible,
      getWidth: () => ref.current?.getBoundingClientRect()?.width || 'auto',
      id: column.id,
    } as ColumnDragObject,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: DND_COLUMN,
    hover({ id: draggedId }: { id: string; type: string }) {
      if (draggedId !== column.id) {
        onMove(draggedId, column.id);
      }
    },
  });

  drag(dragHandleRef);
  drop(ref);

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, []);

  return (
    <Box
      className="ConfigForm-Config"
      style={isDragging ? columnIsDragging : columnDefault}
      ref={ref}
    >
      <Box className="Config-DragHandle" ref={dragHandleRef}>
        <Icons.DragIndicator_medium />
      </Box>
      <Box
        className={
          isVisible ? 'Config-ColumHeader' : 'Config-ColumHeader --invisible'
        }
      >
        <ColumnHeaderRenderer.Consumer>
          {(renderer) => renderer(column)}
        </ColumnHeaderRenderer.Consumer>
      </Box>
      {visibilityToggle ? (
        <Box
          className="Config-Visibility"
          onClick={() => onVisibilityToggle(column.id)}
        >
          {isVisible ? (
            <Icons.VisibilityOn_medium />
          ) : (
            <Icons.VisibilityOff_medium />
          )}
        </Box>
      ) : (
        <Box className="Config-Visibility" />
      )}
    </Box>
  );
}
