import React, { useCallback } from 'react';
import { Box } from '@crpt/material';
import { FilterableTableInstance } from '../../TableFilter/TableFilter.types';

interface FilterCleanerProps<T extends Record<string, any>> {
  label?: string;
  hidden: boolean;
  tableInstance: FilterableTableInstance<T>;
}

export function FilterCleaner<T extends Record<string, any>>({
  label,
  hidden,
  tableInstance,
}: FilterCleanerProps<T>) {
  const onClearAllClick = useCallback(() => {
    tableInstance.setAllFilters && tableInstance.setAllFilters([]);
  }, [tableInstance]);

  if (hidden) {
    return <></>;
  }

  return (
    <Box component="span" className="Filter-Cleaner" onClick={onClearAllClick}>
      {label}
    </Box>
  );
}
