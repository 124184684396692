import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),

      background: theme.palette.grey[200],
      borderRadius: 4,

      '&$disableBackground': {
        background: 'transparent',
      },

      '&$disableBorderRadius': {
        borderRadius: 0,
      },
    },

    thumbprint: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: theme.spacing(2),

      '$vertical &': {
        marginBottom: theme.spacing(4),
      },

      '$error &': {
        color: theme.palette.error.main,
      },
    },

    icon: {
      marginRight: theme.spacing(2),
      color: theme.palette.success.main,

      '$error &': {
        color: theme.palette.error.main,
      },
    },

    serial: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: '0.02em',
      lineHeight: 24 / 14,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    rows: {
      '$horizontal &': {
        display: 'flex',
      },
    },
    row: {
      '$horizontal & + &': {
        marginLeft: theme.spacing(4),
      },

      '$vertical & + &': {
        marginTop: theme.spacing(4),
      },
    },

    horizontal: {},
    vertical: {},
    error: {},
    disableBackground: {},
    disableBorderRadius: {},
  });

export const SignatureAttributeStyles = () =>
  createStyles({
    root: {
      fontSize: 13,
      lineHeight: 20 / 13,

      '& + &': {
        marginTop: 4,
      },
    },
    value: {
      fontWeight: 600,
    },
  });
