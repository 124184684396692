import React, { useMemo } from 'react';
import { Box, BoxProps, defaultTheme, styled } from '@crpt/material';
import {
  FilterableColumnInstance,
  FilterableTableInstance,
  FilterValueTranslationHandler,
} from '../TableFilter';
import { FilterCleaner } from './components/FilterCleaner';
import { FilterChip } from './components/FilterChip';
import { hasOnlyOneFilterValue, hasValue } from './components/FilterChip.tools';

export interface FilterChipsProps<T extends Record<string, any>>
  extends BoxProps {
  showColumnHeaders?: boolean;
  clearLabel?: string;
  tableInstance: FilterableTableInstance<T>;
  onTranslateValue?: FilterValueTranslationHandler<T>;
}

export function FilterChips<T extends Record<string, any>>({
  tableInstance,
  showColumnHeaders,
  onTranslateValue,
  clearLabel,
  ...boxProps
}: FilterChipsProps<T>) {
  const { state, allColumns } = tableInstance;

  const filteredColumns: FilterableColumnInstance<T>[] = useMemo(() => {
    return allColumns.filter((column) =>
      state.filters?.some(
        ({ id, value }) => id === column.id && hasValue(value)
      )
    );
  }, [state.filters, allColumns]);

  if (!filteredColumns.length) {
    return null;
  }

  const hasOnlyOneChip = hasOnlyOneFilterValue(filteredColumns);

  return (
    <FilterChipsRoot {...boxProps}>
      {filteredColumns.map((column) => (
        <FilterChip
          key={column.id}
          column={column}
          showColumnHeader={showColumnHeaders}
          translationHandler={onTranslateValue}
        />
      ))}
      <FilterCleaner
        hidden={hasOnlyOneChip || !filteredColumns.length || !clearLabel}
        label={clearLabel}
        tableInstance={tableInstance}
      />
    </FilterChipsRoot>
  );
}

const FilterChipsRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  flexWrap: 'wrap',
  '.FilterChip': {
    flex: '0 0 auto',
  },
  '.FilterChip-ColumnHeader': {
    ...theme.typography.p3,
    color: theme.palette.grey['60'],
    marginRight: 4,
  },
  '.FilterChip-FilterValue': {
    ...theme.typography.h6,
    color: theme.palette.grey['90'],
  },
  '.Filter-Cleaner': {
    ...theme.typography.p3,
    cursor: 'pointer',
    color: theme.palette.blue['100'],
    userSelect: 'none',
    '&:hover': {
      color: theme.palette.blue['90'],
    },

    '&:active': {
      color: theme.palette.grey['100'],
    },
  },
}));

FilterChipsRoot.defaultProps = {
  theme: defaultTheme,
};
