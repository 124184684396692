import {
  TabProps as MuiTabProps,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  withStyles,
} from '@material-ui/core';
import React, { FC } from 'react';

import Tab from './Tab';
import TabsStyles from './Tabs.styles';

export type TabsProps = {
  items: MuiTabProps[];
} & MuiTabsProps;

const Tabs: FC<TabsProps> = ({ items = [], variant = 'standard', ...rest }) => (
  <MuiTabs variant={variant} {...rest}>
    {items.map((item, index) => (
      <Tab key={index} {...item} />
    ))}
  </MuiTabs>
);

export default withStyles(TabsStyles, {
  name: 'Tabs',
})(Tabs);
