import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles(
  (theme: Theme) => ({
    root: {
      cursor: 'pointer',
      transition: theme.transitions.create('color'),

      '&:active': {
        color: theme.palette.text.secondary,
      },
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    startIcon: {
      marginRight: 8,
    },
    endIcon: {
      marginLeft: 8,
    },
  }),
  {
    name: 'Link',
  }
);
