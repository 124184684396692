import React from 'react';
import { Icons, Link } from '@crpt/material';
import { EmptyDataPanel, EmptyDataPanelProps } from '../EmptyDataPanel';
import { FilterableTableInstance } from '../TableFilter';

export interface NoFilteredDataPanelProps<T>
  extends Partial<Omit<EmptyDataPanelProps, 'children'>> {
  tableInstance: FilterableTableInstance<T>;
  description?: string;
}

/** Панель для отображения пустых данных после фильтрации с возможностью сбросить фильтры.
 * @param {FilterableTableInstance} props.tableInstance экземпляр react table.
 * @param {React.ReactNode} [props.icon] - иконка.
 * @param {string} [props.title] - заголовок панели.
 * @param {string} [props.description] - описание вида: "<reset>Сбросьте фильтры</reset> или выберите другие,
 * где reset - тег с текстом для сброса фильтра".
 */
export function NoFilteredDataPanel<T>(props: NoFilteredDataPanelProps<T>) {
  const {
    tableInstance,
    icon = <Icons.Search_xl />,
    title = 'Ничего не найдено',
    description = '<reset>Сбросьте фильтры</reset> или выберите другие',
    ...boxProps
  } = props;

  const descMatch = description.match(/^(.*)<reset>(.+)<\/reset>(.*)$/);
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.assert(descMatch, `Failed to parse description: ${description}`);
  }

  return (
    <EmptyDataPanel icon={icon} title={title} {...boxProps}>
      {descMatch?.[1]}
      <Link onClick={() => tableInstance.setAllFilters?.([])}>
        {descMatch?.[2]}
      </Link>
      {descMatch?.[3]}
    </EmptyDataPanel>
  );
}
