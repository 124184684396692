import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Chip,
  Icons,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  useTheme,
} from '../index';
import { Box } from '@mui/material';

export type RenderTagsHandler = (args: {
  tags: string[];
  deleteTag: (value: string) => void;
}) => React.ReactNode;

export interface InputTagsProps
  extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: string[] | undefined;
  onChange: (value: string[]) => void;
  renderTags?: RenderTagsHandler;
  tagOnBlur?: boolean;
}

export function InputTags(props: InputTagsProps) {
  const {
    value: tags,
    onChange,
    renderTags = defaultRenderTags,
    tagOnBlur,
    onBlur,
    ...textFieldProps
  } = props;

  const theme = useTheme();
  const [isNewInput, setIsNewInput] = useState<boolean>(true);
  const [duplicateValue, setDuplicateValue] = useState<string>();
  const [originalInput, setOriginalInput] = useState<string>('');

  const viewInputValue = useMemo(() => {
    const isEmpty = !duplicateValue && (isNewInput || !tags);
    return isEmpty ? '' : originalInput;
  }, [duplicateValue, tags, isNewInput, originalInput]);

  const visibleTags: string[] = useMemo(() => {
    if (Array.isArray(tags)) {
      return isNewInput ? tags : tags.slice(0, -1);
    }
    return [];
  }, [tags, isNewInput]);

  const handleInputValue = useCallback(
    (tagList: string[] | undefined, inputValue: string) => {
      if (Array.isArray(tagList)) {
        const indexOfValue = tagList.indexOf(inputValue);
        const lastIndex = tagList.length - 1;
        const isDuplicate =
          (isNewInput && indexOfValue >= 0) ||
          (!isNewInput && indexOfValue >= 0 && indexOfValue < lastIndex);

        setDuplicateValue(isDuplicate ? inputValue : undefined);

        if (isNewInput && isDuplicate) {
          return;
        } else if (!isNewInput && isDuplicate) {
          setIsNewInput(true);
          onChange(tagList.slice(0, -1));
        } else if (isNewInput && inputValue) {
          setIsNewInput(false);
          onChange([...tagList, inputValue]);
        } else if (!isNewInput && !inputValue) {
          setIsNewInput(true);
          onChange(tagList.slice(0, -1));
        } else if (!isNewInput && inputValue) {
          const newFilter = [...tagList];
          newFilter[lastIndex] = inputValue;
          onChange(newFilter);
        }
      } else if (inputValue) {
        setIsNewInput(false);
        onChange([inputValue]);
      }
    },
    [isNewInput, onChange]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOriginalInput(event.target.value);
      const inputValue = event.target.value.trim();
      const tagList = Array.isArray(tags) ? tags : undefined;
      handleInputValue(tagList, inputValue);
    },
    [tags, handleInputValue]
  );

  const onAddTagClick = useCallback(() => {
    setIsNewInput(true);
  }, []);

  const onRemoveTagClick = useCallback(
    (value: string) => {
      if (!Array.isArray(tags)) {
        return;
      }
      const tagList = tags.filter((item) => item !== value);
      onChange(tagList);
      if (duplicateValue === value) {
        handleInputValue(tagList, duplicateValue);
      }
    },
    [duplicateValue, tags, handleInputValue, onChange]
  );

  const onInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        onAddTagClick();
      }
    },
    [onAddTagClick]
  );

  const onBlurHandler = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (e) => {
      if (tagOnBlur) {
        onAddTagClick();
      }
      onBlur?.(e);
    },
    [onAddTagClick, onBlur, tagOnBlur]
  );

  useEffect(() => {
    if (
      Array.isArray(tags) &&
      !isNewInput &&
      tags[tags.length - 1] !== originalInput.trim()
    ) {
      setIsNewInput(true);
    }
  }, [tags, isNewInput, originalInput]);

  const iconColor = viewInputValue
    ? theme.palette.blue['70']
    : theme.palette.grey['60'];

  return (
    <>
      <TextField
        {...textFieldProps}
        InputProps={{
          endAdornment: (
            <InputAdornment onClick={onAddTagClick} position="end">
              <Box
                component={Icons.Add_medium}
                cursor={!duplicateValue && !isNewInput ? 'pointer' : 'default'}
                sx={{ color: iconColor }}
              />
            </InputAdornment>
          ),
        }}
        onBlur={onBlurHandler}
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        value={viewInputValue}
      />
      {renderTags({
        tags: visibleTags,
        deleteTag: onRemoveTagClick,
      })}
    </>
  );
}

const defaultRenderTags: RenderTagsHandler = ({ tags, deleteTag }) => (
  <Stack
    direction="row"
    flexWrap="wrap"
    gap="8px"
    marginTop={tags.length ? '8px' : 0}
  >
    {tags.map((value) => (
      <Chip key={value} label={value} onDelete={() => deleteTag(value)} />
    ))}
  </Stack>
);
