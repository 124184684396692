import { ColumnInstance } from 'react-table';
import { Box, defaultTheme, Icons, styled } from '@crpt/material';
import React from 'react';
import { ColumnHeaderRenderer } from './ColumnHeaderRendererContext';

interface DraggingPreviewProps {
  column: ColumnInstance<Record<string, any>>;
  isVisible: boolean;
}

export function ColumnDraggingPreview({
  column,
  isVisible,
}: DraggingPreviewProps) {
  return (
    <DraggingPreviewRoot>
      <div className="Preview-DragHandle">
        <Icons.DragIndicator_medium />
      </div>
      <div className="Preview-ColumHeader">
        <ColumnHeaderRenderer.Consumer>
          {(renderer) => renderer(column)}
        </ColumnHeaderRenderer.Consumer>
      </div>
      <div className="Preview-Visibility">
        {isVisible ? (
          <Icons.VisibilityOn_medium />
        ) : (
          <Icons.VisibilityOff_medium />
        )}
      </div>
    </DraggingPreviewRoot>
  );
}

const DraggingPreviewRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.grey['5'],
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.16)',
  color: theme.palette.grey['60'],
  padding: 16,
  borderRadius: 8,
  gap: 8,
  '.Preview-DragHandle': {
    flex: '0 0 auto',
  },
  '.Preview-ColumHeader': {
    ...theme.typography.p2,
    flex: '1 1 auto',
  },
  '.Preview-Visibility': {
    flex: '0 0 auto',
  },
}));

DraggingPreviewRoot.defaultProps = {
  theme: defaultTheme,
};
