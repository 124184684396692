import { ReactNode, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import useHeaderPortal from './hooks/useHeaderPortal';

export interface WindowHeaderProps {
  children: ReactNode;
}

export function WindowHeader({ children }: WindowHeaderProps) {
  const headerRoot = useHeaderPortal();
  const render = createPortal(children, headerRoot);
  useLayoutEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [children]);

  return render;
}
