import { FilterableColumnInstance } from '../../TableFilter';

function isValue<T>(value: T | string): boolean {
  return value !== undefined && value !== null && value !== '';
}

export function valueToArray<T = any>(value: T | T[]): T[] {
  const arrayValues = Array.isArray(value) ? value : [value];
  return arrayValues.filter(isValue);
}

export function hasValue<T = any>(value: T | T[]): boolean {
  return Boolean(valueToArray(value).length);
}

export function removeValue<T = any>(
  value: T | T[],
  index: number
): T[] | undefined {
  if (Array.isArray(value)) {
    return value.filter((v, i) => i !== index);
  } else {
    return undefined;
  }
}

export function hasOnlyOneFilterValue<T extends Record<string, any>>(
  filteredColumns: FilterableColumnInstance<T>[]
) {
  return (
    filteredColumns.length === 1 &&
    valueToArray(filteredColumns[0].filterValue).length === 1
  );
}