/* eslint-disable no-shadow */
import { FormGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Checkbox from '../Checkbox/Checkbox';

const CheckboxGroup = ({ options, value, onChange, ...rest }) => {
  const [state, setState] = useState(value);

  const handleChange = useCallback(
    (event, checked) => {
      const nextState = [...state];

      if (checked) {
        nextState.push(event.target.value);
      } else {
        const nextStateValueIndex = nextState.findIndex(
          (nextStateValue) => nextStateValue === event.target.value
        );

        nextState.splice(nextStateValueIndex, 1);
      }

      setState(nextState);

      if (typeof onChange === 'function') {
        onChange(nextState);
      }
    },
    [onChange, state]
  );

  return (
    <FormGroup {...rest}>
      {options.map((option) => (
        <Checkbox
          {...option}
          checked={state.includes(option.value)}
          key={option.value}
          onChange={handleChange}
        />
      ))}
    </FormGroup>
  );
};

CheckboxGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.string),
};

CheckboxGroup.defaultProps = {
  options: [],
  value: [],
};

export default CheckboxGroup;
