import { Drawer as MuiDrawer, backdropClasses, DrawerProps } from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Drawer = styled(MuiDrawer)<DrawerProps>(() => ({
  [`.${backdropClasses.root}`]: {
    backgroundColor: 'rgba(28, 40, 55, 0.7)',
    backdropFilter: 'blur(12px)',
  },
}));

Drawer.defaultProps = {
  theme: defaultTheme,
};

export default Drawer;
