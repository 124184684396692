import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  withStyles,
} from '@material-ui/core';
import React, { FC } from 'react';

import TabStyles from './Tab.styles';

const Tab: FC<MuiTabProps> = ({
  disableFocusRipple = true,
  disableRipple = true,
  disableTouchRipple = true,
  ...rest
}) => (
  <MuiTab
    disableFocusRipple={disableFocusRipple}
    disableRipple={disableRipple}
    disableTouchRipple={disableTouchRipple}
    {...rest}
  />
);

export default withStyles(TabStyles, {
  name: 'Tab',
})(Tab);
