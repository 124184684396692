import {
  actions,
  ColumnInstance,
  Hooks,
  TableInstance,
  UseColumnOrderInstanceProps,
} from 'react-table';
import React from 'react';
import { toggleRowSelectionColumnId } from './useSelectRowColumn';
import { actionsColumnId } from './useActionColumn';
import {
  TableColumnConfiguration,
  TableConfiguration,
} from '../../table.types';

actions.setDefaultConfig = 'setDefaultConfig';

type UseTableConfigTableInstance<T extends Record<string, any>> = Omit<
  TableInstance<T> & Partial<UseColumnOrderInstanceProps<T>>,
  'state'
> & {
  setDefaultConfig: (config: TableConfiguration) => void;
  applyDefaultConfig: () => void;
  getCurrentConfig: () => TableConfiguration;
  applyConfig: (config: TableConfiguration) => void;
  state: TableInstance<T>['state'] & UseTableConfigTableState;
};

type UseTableConfigTableState = {
  defaultConfig?: TableConfiguration;
  hiddenColumns: string[];
};

export const useTableConfig = <T extends Record<string, any>>(
  hooks: Hooks<T>
) => {
  hooks.stateReducers.push((state, action) =>
    reducer(state as UseTableConfigTableState, action)
  );
  hooks.useInstance.push((instance) =>
    useInstance(instance as UseTableConfigTableInstance<T>)
  );
};

function reducer(
  state: UseTableConfigTableState,
  action: Record<string, any>
): UseTableConfigTableState | undefined {
  if (action.type === actions.init) {
    if (state.defaultConfig) {
      const initialState = getInitialState(state.defaultConfig);
      return {
        ...state,
        ...initialState,
      };
    }
  }

  if (action.type === actions.resetHiddenColumns) {
    if (state.defaultConfig) {
      const initialState = getInitialState(state.defaultConfig);
      return {
        ...state,
        hiddenColumns: initialState.hiddenColumns,
      };
    }
  }

  if (action.type === actions.setDefaultConfig) {
    return {
      ...state,
      defaultConfig: action.value,
    };
  }
}

function useInstance<T extends Record<string, any>>(
  instance: UseTableConfigTableInstance<T>
) {
  const { dispatch } = instance;

  instance.setDefaultConfig = React.useCallback(
    (value: TableConfiguration) =>
      dispatch({
        type: actions.setDefaultConfig,
        value,
      }),
    []
  );

  instance.applyDefaultConfig = React.useCallback(
    () => applyDefaultConfig(instance),
    [instance]
  );

  instance.applyConfig = React.useCallback(
    (value: TableConfiguration) => applyConfig(instance, value),
    [instance]
  );

  instance.getCurrentConfig = React.useCallback(
    (): TableConfiguration => getCurrentConfig(instance),
    [instance]
  );
}

function getCurrentConfig<T extends Record<string, any>>(
  instance: TableInstance<T>
): TableConfiguration {
  const { allColumns: tableColumns } = instance;

  const columns = tableColumns
    .filter((column) => !isServiceColumn(column))
    .map((column, index): TableColumnConfiguration => {
      return {
        id: column.id,
        isVisible: column.isVisible,
        order: index,
      };
    });

  return { columns };
}

function getInitialState(config: TableConfiguration) {
  const hiddenColumns = config.columns
    .filter((conf) => !(conf.isVisible ?? true))
    .map((conf) => conf.id);

  const columnOrder = getColumnOrder(config?.columns);
  return {
    hiddenColumns,
    columnOrder,
  };
}

function applyConfig<T extends Record<string, any>>(
  instance: UseTableConfigTableInstance<T>,
  config: TableConfiguration
) {
  if (!instance.setColumnOrder) {
    console.error('useColumnOrder is required');
    return null;
  }

  const { allColumns: tableColumns } = instance;
  const { columns: columnsConfig } = config;

  tableColumns
    .filter((column) => !isServiceColumn(column))
    .forEach((column) => {
      const conf = columnsConfig.find(({ id }) => column.id === id);
      if (conf && column.toggleHidden) {
        const isVisible = conf.isVisible ?? true;
        column.toggleHidden(!isVisible);
      }
    });

  const columnOrder = getColumnOrder(columnsConfig);

  if (hasSelectionRows(instance)) {
    columnOrder.unshift(toggleRowSelectionColumnId);
  }

  if (hasActionsColumn(instance)) {
    columnOrder.push(actionsColumnId);
  }

  instance.setColumnOrder(columnOrder);
}

function getColumnOrder(columnsConfig: TableColumnConfiguration[]) {
  const columnOrder = columnsConfig
    .filter((conf) => conf.order == null)
    .map((conf) => conf.id);
  const ordered = columnsConfig
    .filter((conf) => conf.order != null)
    .sort(sortByOrder) as Required<
    Omit<TableColumnConfiguration, 'isVisible'>
  >[];

  ordered.forEach((conf) => columnOrder.splice(conf.order, 0, conf.id));
  return columnOrder;
}

function applyDefaultConfig<T extends Record<string, any>>(
  instance: UseTableConfigTableInstance<T>
) {
  const defaultConfig = instance.state?.defaultConfig;
  if (defaultConfig) {
    applyConfig(instance, defaultConfig);
  }
}

function hasActionsColumn<T extends Record<string, any>>(
  tableInstance: UseTableConfigTableInstance<T>
) {
  return tableInstance.visibleColumns.some((col) => col.id === actionsColumnId);
}

function hasSelectionRows<T extends Record<string, any>>(
  tableInstance: UseTableConfigTableInstance<T>
) {
  return tableInstance.visibleColumns.some(
    (col) => col.id === toggleRowSelectionColumnId
  );
}

function isServiceColumn<T extends Record<string, any>>(
  column: ColumnInstance<T> & { isMerged?: boolean }
) {
  return (
    column.id === toggleRowSelectionColumnId ||
    column.id === actionsColumnId ||
    column.isMerged
  );
}

function sortByOrder(
  confA: TableColumnConfiguration,
  confB: TableColumnConfiguration
) {
  return (confA.order ?? 0) - (confB.order ?? 0);
}
