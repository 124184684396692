import { Box, defaultTheme, styled, TableCell } from '@crpt/material';
import React, { CSSProperties } from 'react';
import { tableCellClasses } from '@mui/material';
import { ExpanderCell } from './ExpanderCell';
import {
  getCellSizing,
  getDepthCorrection,
  getInstanceInfo,
  levelWidth,
} from '../TableGrid.tools';
import { TableGridCell, TableGridInstance } from '../TableGrid.types';

const TableDataRowRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.grey['5'],
  '& .DataCell': {
    flex: '1 1 auto',
    display: 'flex',
    overflow: 'hidden',
  },
  '& .DataCell-Content': {
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'normal',
    whiteSpace: 'normal',
  },
  [`& .${tableCellClasses.root}.ExpanderCell`]: {
    padding: '20px 0px 0 0',
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    borderLeft: `1px solid transparent`,
  },
  '&.--expanded': {
    '& .ExpanderCell': {
      backgroundColor: theme.palette.grey['00'],
      borderLeftColor: theme.palette.blue['70'],
    },
    '& .DataCell': {
      backgroundColor: theme.palette.grey['00'],
    },
  },
  [`&:hover .${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.grey['20'],
  },
}));

TableDataRowRoot.defaultProps = {
  theme: defaultTheme,
};

interface TableDataRowProps<D extends Record<string, any>> {
  tableInstance: TableGridInstance<D>;
  rowIndex: number;
  style: CSSProperties;
  width: number;
  horizontalPadding: number;
  maxDepth: number;
}

export function TableDataRow<D extends Record<string, any>>({
  tableInstance,
  rowIndex,
  horizontalPadding,
  style,
  width,
  maxDepth,
}: TableDataRowProps<D>) {
  const row = tableInstance.rows[rowIndex];

  tableInstance.prepareRow(row);

  const { hasRowSelection, hasRowExpanding } = getInstanceInfo(tableInstance);
  const { className } = row.getRowProps();
  const classNameExpanded = row.isExpanded || row.depth > 0 ? '--expanded' : '';

  return (
    <Box style={style}>
      <TableDataRowRoot
        paddingX={`${horizontalPadding}px`}
        width={width}
        className={`${className} ${classNameExpanded}`}
      >
        {hasRowExpanding && <ExpanderCell row={row} levelWidth={levelWidth} />}
        {row.cells.map((cell: TableGridCell<D>, index) => {
          const depthCorrection = getDepthCorrection({
            hasRowExpanding,
            hasRowSelection,
            cellIndex: index,
            rowDepth: cell.row.depth,
            maxDepth,
          });

          const cellSizing = getCellSizing(cell.column, depthCorrection);

          return (
            <TableCell
              key={cell.column.id}
              className="DataCell"
              component={Box}
              style={cellSizing}
            >
              <Box className="DataCell-Content">{cell.render('Cell')}</Box>
            </TableCell>
          );
        })}
      </TableDataRowRoot>
    </Box>
  );
}
