import { Box } from '@crpt/material';
import React from 'react';

interface LabeledValueProps {
  columnHeader: React.ReactNode | string | undefined;
  value: any;
}

export function FilterValue({ columnHeader, value }: LabeledValueProps) {
  return (
    <Box>
      {columnHeader && (
        <span className="FilterChip-ColumnHeader">{columnHeader}:</span>
      )}
      <span className="FilterChip-FilterValue">{value}</span>
    </Box>
  );
}
