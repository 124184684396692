import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: '8px 8px 8px 16px',
      height: 48,
      minHeight: 48,
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      fontSize: 14,
      textTransform: 'none',
      lineHeight: 16 / 14,
      backgroundColor: '#f2f2f2',
      opacity: 1,
      overflow: 'visible',

      '&:before': {
        display: 'block',
        content: '" "',
        position: 'absolute',
        borderLeft: '24px solid white',
        borderTop: '36px solid transparent',
        borderBottom: '36px solid transparent',
        top: -12,
        right: -16,
        zIndex: 1,
      },
      '&:after': {
        display: 'block',
        content: '" "',
        position: 'absolute',
        borderLeft: '16px solid #f2f2f2',
        borderTop: '24px solid transparent',
        borderBottom: '24px solid transparent',
        top: 0,
        right: -8,
        zIndex: 1,
      },

      '&:last-of-type:before': {
        display: 'none',
      },
      '&:last-of-type:after': {
        display: 'none',
      },

      '&$disabled': {
        opacity: 1,
        color: '#b8b8b8',
        background: '#f9f9f9',
        '&:after': {
          borderLeftColor: '#f9f9f9',
        },
      },
    },
    disabled: {},
    selected: {
      backgroundColor: theme.palette.primary.main,

      '&:after': {
        borderLeftColor: theme.palette.primary.main,
      },
    },
  });
