import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import React from 'react';
import { defaultTheme, styled } from '../theme';

const Default = styled('span')(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  color: theme.palette.grey['100'],

  transition: theme.transitions.create(['background', 'stroke', 'color']),

  'input:disabled ~ &': {
    background: theme.palette.grey['30'],
    color: theme.palette.grey['60'],
  },
}));

Default.defaultProps = {
  theme: defaultTheme,
};

const Icon = styled(Default)(({ theme }) => ({
  color: theme.palette.grey['60'],

  'input:not(:disabled):hover ~ & #Checkbox-border': {
    stroke: theme.palette.grey['70'],
  },

  '& #Checkbox-border': {
    stroke: theme.palette.grey['60'],
    fill: 'none',
  },

  'input:disabled ~ & #Checkbox-border': {
    stroke: theme.palette.grey['30'],
  },
}));

Icon.defaultProps = {
  theme: defaultTheme,
};

const CheckedIcon = styled(Default)(({ theme }) => ({
  background: theme.palette.yellow['40'],
  svg: {
    stroke: theme.palette.grey['100'],
    fill: 'none',
  },

  'input:not(:disabled):hover ~ &': {
    background: theme.palette.yellow['60'],
  },
}));

CheckedIcon.defaultProps = {
  theme: defaultTheme,
};

const Checkbox = styled(MuiCheckbox)<CheckboxProps>(({ theme }) => ({
  padding: 0,
  color: theme.palette.grey['05'],

  '&:hover': {
    background: 'transparent',
  },

  [`&.MuiCheckbox-colorError #Checkbox-border`]: {
    stroke: theme.palette.red['70'],
  },
}));

Checkbox.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
  icon: (
    <Icon>
      <svg
        id="Checkbox-border"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_35034_5086)">
          <rect
            x="0.5"
            y="0.5"
            width="19"
            height="19"
            rx="3.5"
          />
        </g>
        <defs>
          <clipPath id="clip0_35034_5086">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  ),
  checkedIcon: (
    <CheckedIcon>
      <svg
        width="11"
        height="8"
        viewBox="0 0 11 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke="currentColor"
          d="M1 4L4.27273 7L10 1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </CheckedIcon>
  ),
  indeterminateIcon: (
    <CheckedIcon>
      <svg
        width="10"
        height="2"
        viewBox="0 0 10 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L5.33333 1L9 1"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </CheckedIcon>
  ),
};

export default Checkbox;
