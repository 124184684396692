import {
  Input as MuiInput,
  InputProps,
  inputAdornmentClasses,
  inputClasses,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Input = styled(MuiInput)<InputProps>(({ theme }) => ({
  [`&.${inputClasses.root}`]: {
    ...theme.typography.p2,
    height: 52,
    background: theme.palette.grey['0'],
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey['30']}`,
    padding: 1,

    [`&, &:hover, &.${inputClasses.focused}`]: {
      background: theme.palette.grey['0'],
    },

    [`&.${inputClasses.focused}`]: {
      border: `1px solid ${theme.palette.grey['50']}`,
    },

    [`&.${inputClasses.disabled}`]: {
      border: `1px solid ${theme.palette.grey['50']}`,
      background: theme.palette.grey['30'],
    },

    [`&.${inputClasses.error}`]: {
      border: `1px solid ${theme.palette.red['70']}`,
    },

    [`.${inputClasses.input}`]: {
      padding: '16px 16px',
      height: 20,
      borderRadius: 7,

      '&:focus': {
        background: 'none',
        border: 'none',
      },
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.grey['60'],
      },
    },

    [`.${inputAdornmentClasses.root}`]: {
      [`&.${inputAdornmentClasses.positionEnd}`]: {
        marginRight: 16,
        marginLeft: 0,
      },
      [`&.${inputAdornmentClasses.positionStart}`]: {
        marginLeft: 16,
        marginRight: -2,
      },
    },
  },
}));

Input.defaultProps = {
  theme: defaultTheme,
  autoComplete: 'off',
  disableUnderline: true,
};

export default Input;
