import {
  ButtonBase,
  ButtonBaseProps,
  capitalize,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, forwardRef, ReactNode, useCallback } from 'react';

import ButtonStyles from './Button.styles';

export interface ButtonProps
  extends Omit<ButtonBaseProps, 'classes'>,
    WithStyles<typeof ButtonStyles> {
  active?: boolean;
  color?: 'primary' | 'error' | 'secondary';
  endIcon?: ReactNode;
  fullWidth?: boolean;
  icon?: ReactNode;
  loading?: boolean;
  size?: 'sm' | 'md' | 'lg';
  startIcon?: ReactNode;
  variant?: 'contained' | 'outlined' | 'text';
  width?: number;
}

const Button: FC<ButtonProps> = forwardRef(
  (
    {
      active,
      children,
      classes,
      className,
      color = 'primary',
      disabled,
      endIcon,
      fullWidth,
      icon,
      loading,
      size = 'md',
      startIcon = icon,
      variant = 'contained',
      width,
      ...rest
    },
    ref
  ) => {
    const renderStartIcon = useCallback(
      () =>
        startIcon ? (
          <span className={classes.startIcon}>{startIcon}</span>
        ) : null,
      [classes, startIcon]
    );

    const renderEndIcon = useCallback(
      () =>
        endIcon ? <span className={classes.endIcon}>{endIcon}</span> : null,
      [classes, endIcon]
    );

    return (
      <ButtonBase
        TouchRippleProps={{
          className: classes.ripple,
        }}
        className={clsx(
          classes.root,
          classes[variant],
          classes[
            `${variant}${capitalize(color)}` as
              | 'containedError'
              | 'containedPrimary'
              | 'outlinedError'
              | 'outlinedPrimary'
              | 'textError'
              | 'textPrimary'
              | 'textSecondary'
          ],
          [
            classes[
              `size${capitalize(size)}` as 'sizeLg' | 'sizeMd' | 'sizeSm'
            ],
          ],
          {
            [classes.active]: active,
            [classes.disabled]: disabled,
            [classes.fullWidth]: fullWidth,
            [classes.loading]: loading,
            [classes.square]: !!startIcon && !children,
          },
          className
        )}
        disabled={disabled}
        ref={ref}
        style={{
          minWidth: width,
        }}
        {...rest}
      >
        <span className={classes.preloader} />
        <span className={classes.body}>
          {renderStartIcon()}
          {children && <span className={classes.label}>{children}</span>}
          {renderEndIcon()}
        </span>
      </ButtonBase>
    );
  }
);

export default withStyles(ButtonStyles)(Button);
