import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';

import {
  useInputClasses,
  useLabelClasses,
  useRootClasses,
} from './Input.styles';
import { EndAdornment } from './EndAdornment';

const Input = React.forwardRef(
  (
    {
      error,
      InputLabelProps,
      InputProps,
      label,
      loading,
      inputProps,
      onChange,
      required,
      clearable = false,
      inputRef: inputRefProp,
      hint,
      multiple,
      className,
      ...rest
    },
    forwardRef
  ) => {
    const { defaultValue, value } = rest;
    const rootClasses = useRootClasses();
    const inputClasses = useInputClasses();
    const inputLabelClasses = useLabelClasses();

    const inputRef = useRef(null);
    const prevValue = useRef(defaultValue);
    useImperativeHandle(forwardRef || inputRefProp, () => inputRef.current);

    const isControlled = value !== undefined;
    const [canClear, setCanClear] = useState(
      defaultValue && defaultValue.length
    ); // canClear используется для uncontrolled input
    const refValue = inputRef.current ? inputRef.current.value : defaultValue;

    const handleChange = useCallback(
      (event, value) => {
        if (!isControlled && (!value || (!prevValue.current && value))) {
          setCanClear(!!value);
          prevValue.current = value;
        }

        if (typeof onChange === 'function') {
          onChange(event, value);
        } else {
          inputRef.current.value = value;
        }
      },
      [isControlled, onChange]
    );

    const checkValue = (value) => {
      if (typeof value === 'number' && value === 0) {
        return true;
      }
      if (isArray(value) && value.length === 0) {
        return false;
      }
      return Boolean(value);
    };

    const hasValue = useMemo(() => {
      return checkValue(isControlled ? value : refValue);
    }, [isControlled, refValue, value]);

    required = required || className === 'required';

    return (
      <TextField
        label={label}
        error={Boolean(error)}
        onChange={(event) => handleChange(event, event.target.value)}
        className={clsx(rootClasses.root, {
          [rootClasses.error]: Boolean(error),
          [rootClasses.required]: required,
          [rootClasses.value]: required && hasValue,
        })}
        InputProps={{
          ...InputProps,
          classes: inputClasses,
          disableUnderline: true,
          endAdornment: React.createElement(EndAdornment, {
            InputProps,
            isControlled,
            value,
            clearable,
            canClear,
            handleChange,
            loading,
            error,
            hint,
          }),
        }}
        InputLabelProps={{
          ...InputLabelProps,
          classes: inputLabelClasses,
        }}
        inputRef={inputRef}
        inputProps={{
          ...inputProps,
          style: {
            padding: label ? '16px 16px 0' : '0 16px',
          },
        }}
        {...rest}
      />
    );
  }
);

Input.propTypes = {
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hint: PropTypes.string,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool.isRequired,
  select: PropTypes.bool,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']).isRequired,
};

Input.defaultProps = {
  disabled: false,
  error: false,
  InputLabelProps: {},
  InputProps: {},
  loading: false,
  required: false,
  select: false,
  variant: 'filled',
};

export default Input;
