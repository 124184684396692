import {
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

export const AccordionDetails = styled(
  MuiAccordionDetails
)<AccordionDetailsProps>(({ theme }) => ({
  padding: `20px 60px`,
  ...theme.typography.p2,
}));

AccordionDetails.defaultProps = {
  theme: defaultTheme,
};
