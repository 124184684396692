import React, { useCallback, useMemo, useState } from 'react';
import { Drawer } from '@crpt/material';
import { formValuesToFilterValues } from './TableFilter.tools';
import { FilterForm } from './components/FilterForm';
import {
  FilterableColumnInstance,
  FilterableTableInstance,
} from './TableFilter.types';

export interface TableFilterProps<T extends Record<string, any>> {
  title?: string;
  applyLabel?: string;
  cancelLabel?: string;
  tableInstance: FilterableTableInstance<T>;
  order?: string[];
  children: (open: () => void) => React.ReactNode;
  width?: number | string;
}

const defaultWidth = 408;

export function TableFilter<T extends Record<string, any>>({
  title,
  applyLabel,
  cancelLabel,
  tableInstance,
  order,
  children,
  width,
}: TableFilterProps<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);

  const onSubmit = useCallback(
    (formValues: Record<string, any>) => {
      if (!tableInstance.setAllFilters) {
        console.error('useFilters is required');
        return null;
      }

      const filterValues = formValuesToFilterValues(formValues);
      tableInstance.setAllFilters(filterValues);
      setIsOpen(false);
    },
    [tableInstance]
  );

  const columns = useMemo(() => {
    const allColumns = tableInstance.allColumns;

    if (!order?.length) {
      return allColumns;
    }
    const ordered = order
      .map((columnId) => allColumns.find((column) => column.id === columnId))
      .filter(Boolean);
    const unordered = allColumns.filter((column) => !order.includes(column.id));

    return [...ordered, ...unordered] as FilterableColumnInstance<T>[];
  }, [tableInstance.allColumns, order]);

  return (
    <>
      {children(onOpen)}
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{ sx: { width: width || defaultWidth } }}
      >
        <FilterForm
          width={width || defaultWidth}
          applyLabel={applyLabel || 'Применить'}
          cancelLabel={cancelLabel || 'Отмена'}
          columns={columns}
          flex="1 1 0"
          onClose={onClose}
          onSubmit={onSubmit}
          title={title || 'Фильтры'}
        />
      </Drawer>
    </>
  );
}
