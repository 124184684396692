import React from 'react';
import {
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from 'notistack';
import SnackbarContent from './SnackbarContent';
import { Box } from '@mui/material';
import { Icons } from '../Icon';

let useSnackbarRef: ProviderContext | null = null;
export const SnackbarServiceConfigurator: React.VFC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export interface SnackbarOptions extends OptionsObject {
  showClose?: boolean;
}

export function enqueueSnackbar(
  message: SnackbarMessage,
  options?: SnackbarOptions
) {
  // eslint-disable-next-line no-console
  console.assert(
    useSnackbarRef,
    'You should initialize the SnackbarProvider before calling'
  );
  return useSnackbarRef?.enqueueSnackbar(message, getOptions(options));
}

export function closeSnackbar(key?: SnackbarKey) {
  return useSnackbarRef?.closeSnackbar(key);
}

function getOptions(options: SnackbarOptions = {}): OptionsObject {
  const { showClose, ...snackbarOptions } = options;
  return {
    content: (key: SnackbarKey, message: SnackbarMessage) => {
      const actionRender =
        typeof options.action === 'function'
          ? options.action(key)
          : options.action;
      const action = showClose ? (
        <>
          {actionRender}
          <Box
            className="Snackbar-CloseButton"
            component={Icons.Close_small}
            onClick={() => closeSnackbar(key)}
          />
        </>
      ) : (
        actionRender
      );
      return <SnackbarContent action={action} message={message} />;
    },
    ...snackbarOptions,
  };
}
