import { FormControl as MuiFormControl, FormControlProps } from '@mui/material';
import { defaultTheme, styled } from '../theme';

const FormControl = styled(MuiFormControl)<FormControlProps>(({ theme }) => ({
  ...theme.typography.p2,
}));

FormControl.defaultProps = {
  theme: defaultTheme,
  variant: 'standard',
};

export default FormControl;
