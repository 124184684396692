import {
  Accordion as MuiAccordion,
  accordionClasses,
  AccordionProps,
  accordionSummaryClasses,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

export const Accordion = styled(MuiAccordion)<AccordionProps>(({ theme }) => ({
  border: `none`,

  [`&.${accordionClasses.expanded} .${accordionSummaryClasses.root}`]: {
    borderColor: theme.palette.grey['30'],
  },
  [`&.${accordionClasses.expanded} + &:not(.${accordionClasses.expanded}) .${accordionSummaryClasses.root}`]:
    {
      borderColor: theme.palette.grey['30'],
    },
  [`&.${accordionClasses.expanded}:first-of-type .${accordionSummaryClasses.root}:not(:hover)`]:
    {
      borderColor: theme.palette.grey['00'],
    },
  [`&.${accordionClasses.expanded}:first-of-type .${accordionSummaryClasses.root}:hover`]:
    {
      borderColor: theme.palette.grey['20'],
    },
  '&:before': {
    display: 'none',
  },
}));

Accordion.defaultProps = {
  theme: defaultTheme,
  disableGutters: true,
  elevation: 0,
  square: true,
};
