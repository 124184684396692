import {
  createStyles,
  FormControlLabel,
  Theme,
  withStyles,
} from '@material-ui/core';

export default withStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'flex-start',
      margin: '8px 0 0 0',
      verticalAlign: 'top',

      '&:first-child': {
        marginTop: 0,
      },
    },
    label: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 24 / 14,
      margin: '0 0 0 8px',

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },
  })
)(FormControlLabel);
