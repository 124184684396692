import {
  FilterableColumnInstance,
  FilterValueTranslationHandler,
} from '../../TableFilter';
import React, { useCallback, useMemo } from 'react';
import { Chip } from '@crpt/material';
import { FilterValue } from './FilterValue';
import { removeValue, valueToArray } from './FilterChip.tools';

interface FilterChipProps<T extends Record<string, any>> {
  column: FilterableColumnInstance<T>;
  showColumnHeader?: boolean;
  translationHandler?: FilterValueTranslationHandler<T>;
}

export function FilterChip<T extends Record<string, any>>({
  column,
  showColumnHeader,
  translationHandler,
}: FilterChipProps<T>) {
  const onDelete = useCallback(
    (index: number) => {
      if (column.setFilter) {
        column.setFilter(removeValue(column.filterValue, index));
      }
    },
    [column]
  );

  const renderValues = useMemo(() => {
    const values = valueToArray(column.filterValue);

    const displayValue = translationHandler
      ? values.map((value) => translationHandler(column, value) || String(value))
      : values.map((value) => String(value));

    const getHeaderRender = () =>
      column.render ? column.render('Header') : column.Header;
    const columnHeader = showColumnHeader ? getHeaderRender() : null;

    return displayValue.map((value, index) => (
      <FilterValue columnHeader={columnHeader} key={index} value={value} />
    ));
  }, [column, showColumnHeader, translationHandler, column.filterValue]);

  return (
    <>
      {renderValues.map((renderValue, index) => (
        <Chip
          className="FilterChip"
          key={index}
          label={renderValue}
          onDelete={() => onDelete(index)}
        />
      ))}
    </>
  );
}
