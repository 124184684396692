import {
  ButtonBase,
  ButtonBaseProps,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import PaginationButtonStyles from './PaginationButton.styles';

export type PaginationButtonProps = ButtonBaseProps &
  WithStyles<typeof PaginationButtonStyles> & {
    selected?: boolean;
  };

const PaginationButton: React.FC<PaginationButtonProps> = ({
  children,
  classes,
  className,
  disabled = false,
  selected = false,
  ...rest
}) => (
  <ButtonBase
    className={clsx(
      classes.root,
      {
        [classes.disabled]: disabled,
        [classes.selected]: selected,
      },
      className
    )}
    disabled={disabled}
    disableRipple
    disableTouchRipple
    {...rest}
  >
    {children}
  </ButtonBase>
);

export default withStyles(PaginationButtonStyles)(PaginationButton);
