import { TableInstance } from 'react-table';
import React from 'react';


interface TableColumnsProps<D extends Record<string, any>> {
  tableInstance: TableInstance<D>;
}

export const TableColumns = <D extends Record<string, any>>(
  props: TableColumnsProps<D>
) => {
  const { visibleColumns } = props.tableInstance;
  return (
    <colgroup>
      {visibleColumns.map((column) => (
        <col
          key={column.id}
          style={{ width: column.width, minWidth: column.width }}
        />
      ))}
    </colgroup>
  );
};