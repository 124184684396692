import {
  FormControlLabelProps as MuiFormControlLabelProps,
  RadioProps as MuiRadioProps,
} from '@material-ui/core';
import React, { FC } from 'react';

import FormControlLabel from '../FormControlLabel/FormControlLabel';
import Icon from '../Icon/Icon';
import RadioControl from './RadioControl';

export type RadioProps = Omit<MuiFormControlLabelProps, 'color' | 'control'> &
  Pick<
    MuiRadioProps,
    'checkedIcon' | 'disableRipple' | 'disableTouchRipple' | 'icon' | 'color'
  >;

const Radio: FC<RadioProps> = ({
  checkedIcon = <Icon name="radioboxChecked" />,
  color = 'default',
  disableRipple = true,
  disableTouchRipple = true,
  icon = <Icon name="radiobox" />,
  ...rest
}) => (
  <FormControlLabel
    control={
      <RadioControl
        checkedIcon={checkedIcon}
        color={color}
        disableRipple={disableRipple}
        disableTouchRipple={disableTouchRipple}
        icon={icon}
      />
    }
    {...rest}
  />
);

export default Radio;
