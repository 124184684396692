import { ChangeTimeType, TimeType } from '../TimePicker.types';
import { convertNumberToCorrectString } from './timeConvert';

const changeAndConvertTimeValue = (
  value: number,
  maxValue: number,
  type: ChangeTimeType
): string => {
  if (type === ChangeTimeType.increase) {
    const increasedValue = value + 1;
    const resultNumber = increasedValue > maxValue ? 0 : increasedValue;
    return convertNumberToCorrectString(resultNumber);
  } else {
    const decreasedValue = value - 1;
    const resultNumber = decreasedValue < 0 ? maxValue : decreasedValue;
    return convertNumberToCorrectString(resultNumber);
  }
};

const correctInputValueChange = (value: number, maxValue: number): string => {
  if (value > maxValue) {
    return convertNumberToCorrectString(maxValue);
  } else if (value < 0) {
    return convertNumberToCorrectString(0);
  }
  return convertNumberToCorrectString(value);
};

export const handleInputValueChange = (
  value: string,
  type: TimeType
): string => {
  if (type === TimeType.hours) {
    return correctInputValueChange(Number(value), 23);
  }
  return correctInputValueChange(Number(value), 59);
};

const changeHours = (value: string, type: ChangeTimeType): string => {
  return changeAndConvertTimeValue(Number(value), 23, type);
};

const changeMinutesAndSeconds = (
  value: string,
  type: ChangeTimeType
): string => {
  return changeAndConvertTimeValue(Number(value), 59, type);
};

export const changeTimeValue = (
  value: string,
  type: ChangeTimeType,
  timeType: TimeType
): string => {
  const correctStringValue = value === '' ? '0' : value;
  if (timeType === TimeType.hours) {
    return changeHours(correctStringValue, type);
  } else {
    return changeMinutesAndSeconds(correctStringValue, type);
  }
};
