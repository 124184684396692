import { Box, defaultTheme, styled } from '../index';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  ScrollToTopButton,
  scrollToTopButtonPadding,
  scrollToTopButtonSize,
} from './components/ScrollToTopButton';

const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

const WindowFooterRoot = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  height: 0,
  overflow: 'visible',
  background: theme.palette.grey['0'],
  boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.04)',
  transition: theme.transitions.create(['height']),
}));

WindowFooterRoot.defaultProps = {
  theme: defaultTheme,
};

export interface WindowFooterProps {
  backgroundColor?: string;
  showScrollToTop?: boolean;
  scrollThreshold: number;
  maxRatio: number;
}

export function WindowFooter({
  backgroundColor,
  showScrollToTop,
  scrollThreshold,
  maxRatio,
  children,
}: PropsWithChildren<WindowFooterProps>) {
  const [bottomOffset, setBottomOffset] = useState<string>('0');
  const footerRef = useRef<HTMLDivElement>(null);
  const footerContentRef = useRef<HTMLDivElement>(null);
  const scrollToTopButtonRef = useRef<HTMLButtonElement>(null);

  const onScroll = useCallback(() => {
    if (scrollToTopButtonRef.current) {
      scrollToTopButtonRef.current.style.display =
        window.scrollY > scrollThreshold ? 'block' : 'none';
    }
  }, [scrollToTopButtonRef]);

  const onResize = useCallback(() => {
    const rect = footerContentRef.current?.getBoundingClientRect();
    const maxHeight = Math.min(
      window.innerHeight * maxRatio,
      window.innerHeight
    );
    const footerHeight = Math.min(rect?.height || 0, maxHeight);
    if (footerRef.current) {
      footerRef.current.style.height = `${footerHeight}px`;
    }

    const buttonOffset = scrollToTopButtonSize + scrollToTopButtonPadding * 2;
    const offset = buttonOffset + footerHeight;
    setBottomOffset(`${offset}px`);
  }, []);

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onResize);

    if (!showScrollToTop) {
      if (scrollToTopButtonRef.current) {
        scrollToTopButtonRef.current.style.display = 'none';
      }
      return;
    }

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, [showScrollToTop, onScroll]);

  useLayoutEffect(onScroll, [onScroll]);
  useLayoutEffect(onResize, [children, onResize]);

  return (
    <Box style={{ height: bottomOffset }}>
      <WindowFooterRoot ref={footerRef} style={{ backgroundColor }}>
        <ScrollToTopButton onClick={scrollToTop} ref={scrollToTopButtonRef} />
        <div ref={footerContentRef}>{children}</div>
      </WindowFooterRoot>
    </Box>
  );
}

WindowFooter.defaultProps = {
  scrollThreshold: 50,
  showScrollToTop: true,
  maxRatio: 0.5,
};
