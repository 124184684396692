import React, { CSSProperties } from 'react';
import { TableInstance } from 'react-table';
import {
  Box,
  BoxProps,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@crpt/material';
import { TableColumns } from './components/TableColumns';

export interface SimpleTableProps<D extends Record<string, any>>
  extends BoxProps {
  tableInstance: TableInstance<D>;
  isTableLayoutFixed: boolean;
}

const fixedTableLayoutStyle: CSSProperties = { tableLayout: 'fixed' };

export default function SimpleTable<D extends Record<string, any>>(
  props: SimpleTableProps<D>
) {
  const { tableInstance, isTableLayoutFixed, ...boxProps } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const tableStyle = isTableLayoutFixed ? fixedTableLayoutStyle : undefined;

  return (
    <Box {...boxProps}>
      <Table {...getTableProps()} style={tableStyle}>
        {isTableLayoutFixed && <TableColumns tableInstance={tableInstance} />}
        <TableHead>
          {headerGroups.map((headerGroup, k) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={k}>
              {headerGroup.headers.map((column, m) => (
                <TableCell {...column.getHeaderProps()} key={m}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <TableCell {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}

SimpleTable.defaultProps = {
  isTableLayoutFixed: true,
};
