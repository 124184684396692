import { fade, makeStyles, Theme } from '@material-ui/core';

interface TooltipClassesProps {
  maxWidth?: number;
}

export default makeStyles<Theme, TooltipClassesProps>((theme) => ({
  tooltip: {
    background: fade(theme.palette.common.black, 0.6),
    borderRadius: 4,
    boxSizing: 'border-box',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 16 / 12,
    maxWidth: ({ maxWidth }) => maxWidth,
    padding: theme.spacing(1),
    whiteSpace: 'pre-line',
  },
  arrow: {
    color: fade(theme.palette.common.black, 0.6),
  },
}));
