import addDays from 'date-fns/addDays';
import { useCallback, useEffect } from 'react';

function useDay({
  date,
  focusedDate,
  isDateSelected,
  isDateFocused,
  isLastSelectedDate,
  isFirstSelectedDate,
  isDateHovered,
  isDateBlocked,
  onDateSelect,
  onDateFocus,
  onDateHover,
  dayRef,
  disabledDayFocus,
}) {
  const onClick = useCallback(() => onDateSelect(date), [date, onDateSelect]);
  const onMouseEnter = useCallback(() => onDateHover(date), [
    date,
    onDateHover,
  ]);
  useEffect(() => {
    if (dayRef && dayRef.current && isDateFocused(date)) {
      !disabledDayFocus && dayRef.current.focus();
    }
  }, [dayRef, date, isDateFocused]);
  const disabled = isDateBlocked(date) && !isDateHovered(date);
  return {
    tabIndex: focusedDate === null || isDateFocused(date) ? 0 : -1,
    isSelected: isDateSelected(date),
    isSelectedStart: isFirstSelectedDate(date),
    isSelectedEnd: isLastSelectedDate(date),
    isWithinHoverRange: isDateHovered(date),
    disabledDate: disabled,
    onKeyDown: (e) => {
      if (e.key === 'ArrowRight') {
        onDateFocus(addDays(date, 1));
      } else if (e.key === 'ArrowLeft') {
        onDateFocus(addDays(date, -1));
      } else if (e.key === 'ArrowUp') {
        onDateFocus(addDays(date, -7));
      } else if (e.key === 'ArrowDown') {
        onDateFocus(addDays(date, 7));
      }
    },
    onClick: disabled
      ? (event) => {
          event.preventDefault();
        }
      : onClick,
    onMouseEnter,
  };
}
export default useDay;
