import { makeStyles, Theme } from '@material-ui/core';

interface useClassesProps {
  disabledOptionsAsLabel: boolean
}

export const useClasses = makeStyles<Theme, useClassesProps>((theme) => ({
  root: {
    width: '100%',

    '& $popupIndicator, & $clearIndicator': {
      padding: 0,
      margin: 0,
    },
  },
  paper: {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    margin: '0',
    '& > ul': {
      maxHeight: '300px',
    },
  },
  listbox: {
    padding: '8px 0px',
  },
  option: {
    padding: '8px 16px',
    boxSizing: 'border-box',
    minHeight: 40,
    fontSize: 14,
    lineHeight: 16 / 14,
    '&[data-focus="true"]': {
      backgroundColor: '#f7f7f7',
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
    },
    '&[aria-disabled="true"]': {
      fontSize: ({disabledOptionsAsLabel}) => disabledOptionsAsLabel ? '16px' : 'auto',
      fontWeight: ({disabledOptionsAsLabel}) => disabledOptionsAsLabel ? 600 : 'auto',
      opacity: ({disabledOptionsAsLabel}) => disabledOptionsAsLabel ? 1 : 'auto',
    },
  },
  popupIndicator: {
    transition: theme.transitions.create('transform'),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  clearIndicator: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  endAdornment: {
    position: 'static',
  },
  input: {
    padding: '16px 16px 0 16px !important',
  },
  inputRoot: {
    paddingLeft: '0 !important',
    paddingTop: '0 !important',
  },
  noOptions: {
    color: theme.palette.text.disabled,
    fontSize: 14,
    lineHeight: 16 / 14,
  },
}));
