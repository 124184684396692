import { useEffect, useState } from 'react';

const headerContainerId = 'WindowHeaderContainer';
const headerId = 'WindowHeader';

function resolveHeaderContainer() {
  let containerElement = document.getElementById(headerContainerId);

  if (!containerElement) {
    containerElement = document.createElement('div');
    containerElement.setAttribute('id', headerContainerId);
    document.body.insertBefore(containerElement, document.body.firstChild);
  }

  return containerElement;
}

function createHeaderElement(): HTMLElement {
  const containerElement = resolveHeaderContainer();
  const headerElement = document.createElement('div');

  headerElement.setAttribute('id', headerId);

  containerElement.appendChild(headerElement);

  const bodyStyle = window.getComputedStyle(document.body);
  const bodyPaddingTop = bodyStyle.paddingTop || '0';
  const topOffset = Number(bodyPaddingTop.replace('px', ''));

  headerElement.style.position = 'unset';
  headerElement.style.top = bodyStyle.paddingTop;
  headerElement.style.right = bodyStyle.paddingRight;
  headerElement.style.left = bodyStyle.paddingLeft;
  headerElement.style.willChange = 'top, position';

  let currentHeaderHeight = 0;

  const onChangeSize = () => {
    const rect = headerElement.getBoundingClientRect();
    currentHeaderHeight = rect.height;
    containerElement.style.height = `${currentHeaderHeight}px`;
  };

  const onScroll = () => {
    headerElement.style.top = `${-Math.min(
      window.scrollY - topOffset,
      currentHeaderHeight
    )}px`;

    if (window.scrollX > 0 || window.scrollY === 0) {
      headerElement.style.position = 'fixed';
    } else {
      headerElement.style.position = 'unset';
    }
  };

  const onContentChanged: MutationCallback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (
        mutation.type === 'childList' &&
        headerElement.childElementCount === 0
      ) {
        removeHeader();
      } else {
        onChangeSize();
      }
    }
  };

  const onBodyChanged: MutationCallback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        const bodyStyle = window.getComputedStyle(document.body);
        headerElement.style.right = bodyStyle.paddingRight;
        headerElement.style.left = bodyStyle.paddingLeft;
      }
    }
  };

  window.addEventListener('resize', onChangeSize);
  window.addEventListener('scroll', onScroll);

  const observer = new MutationObserver(onContentChanged);
  observer.observe(headerElement, {
    childList: true,
    subtree: true,
    attributes: true,
  });

  const bodyObserver = new MutationObserver(onBodyChanged);
  bodyObserver.observe(document.body, { attributes: true });

  const removeHeader = () => {
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onChangeSize);
    observer.disconnect();
    bodyObserver.disconnect();
    headerElement.remove();
    containerElement.remove();
  };

  return headerElement;
}

const resolveHeaderElement = () => {
  const headerElement = document.querySelector(`#${headerId}`);
  if (!headerElement) {
    return createHeaderElement();
  }
  return headerElement;
};

interface ChildContainer {
  element: HTMLElement | null;
}

function useHeaderPortal() {
  const [childContainer] = useState<ChildContainer>({ element: null });

  useEffect(function setupElement() {
    const headerElement = resolveHeaderElement();
    headerElement.appendChild(childContainer.element as Node);

    return function removeChildContainer() {
      childContainer.element?.remove();
      childContainer.element = null;
    };
  }, []);

  function getChildContainerElement() {
    if (!childContainer.element) {
      childContainer.element = document.createElement('div');
    }
    return childContainer.element;
  }

  return getChildContainerElement();
}

export default useHeaderPortal;
