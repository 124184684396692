import { Tab as MuiTab, tabClasses, TabProps } from '@mui/material';
import { defaultTheme, styled } from '../theme';

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    ...theme.typography.p2,
    color: theme.palette.grey['60'],
    textTransform: 'none',
    padding: '18px 0',
    minWidth: 'unset',
    maxWidth: 'unset',
    margin: '0 16px',
  },
  [`&:hover`]: {
    color: theme.palette.grey['100'],
  },

  [`&.${tabClasses.selected}`]: {
    color: theme.palette.grey['100'],
  },
}));

Tab.defaultProps = {
  theme: defaultTheme,
  disableRipple: true,
};

export default Tab;
