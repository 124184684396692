import {
  Theme,
  Typography as MuiTypography,
  typographyClasses,
  TypographyProps as MuiTypographyProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';
import { CreateStyledComponent } from '@mui/styled-engine';

type TypographyProps = Omit<MuiTypographyProps, 'variant'> & {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p1' | 'p2' | 'p3';
};

const Typography = styled(MuiTypography)(({ theme }) => ({
  [`&.${typographyClasses.root}`]: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey['100'],

    [`&.${typographyClasses.h1}`]: theme.typography.h1,
    [`&.${typographyClasses.h2}`]: theme.typography.h2,
    [`&.${typographyClasses.h3}`]: theme.typography.h3,
    [`&.${typographyClasses.h4}`]: theme.typography.h4,
    [`&.${typographyClasses.h5}`]: theme.typography.h5,
    [`&.${typographyClasses.h6}`]: theme.typography.h6,
    [`&.MuiTypography-p1`]: theme.typography.p1,
    [`&.MuiTypography-p2`]: theme.typography.p2,
    [`&.MuiTypography-p3`]: theme.typography.p3,
  },
})) as ReturnType<CreateStyledComponent<TypographyProps & { theme?: Theme }>>;

Typography.defaultProps = {
  theme: defaultTheme,
  variant: 'p2',
};

export default Typography;
