import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      fontSize: 10,
      fontWeight: 700,
      height: 48,
      letterSpacing: '0.02em',
      lineHeight: 16 / 10,
      maxWidth: 'none',
      minWidth: 'auto',
      padding: '0 8px',
      textTransform: 'uppercase',
      transition: theme.transitions.create('color'),
    },
    textColorInherit: {
      opacity: 1,
      color: theme.palette.text.secondary,

      '&.Mui-selected': {
        color: theme.palette.text.primary,
      },

      '&:hover': {
        boxShadow: `inset 0px -2px 0px ${theme.palette.primary.main}`,
      },
    },
  });
