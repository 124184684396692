import { ColumnInstance } from 'react-table';
import { TableGridInstance } from './TableGrid.types';

export const levelWidth = 36;

export function getInstanceInfo<D extends Record<string, any>>(
  tableInstance: TableGridInstance<D>
) {
  return {
    hasRowSelection: Boolean(tableInstance.toggleRowSelected),
    hasRowExpanding: Boolean(tableInstance.toggleRowExpanded),
  };
}

export interface GetDepthCorrectionParams {
  hasRowExpanding: boolean;
  hasRowSelection: boolean;
  cellIndex: number;
  rowDepth: number;
  maxDepth: number;
}

export function getDepthCorrection(params: GetDepthCorrectionParams): number {
  if (!params.hasRowExpanding) {
    return 0;
  }
  const hasDepthCorrection =
    (params.cellIndex === 0 && !params.hasRowSelection) ||
    (params.cellIndex === 1 && params.hasRowSelection);

  return hasDepthCorrection
    ? (params.rowDepth + 1) * levelWidth - levelWidth * params.maxDepth
    : 0;
}

const positiveOrZero = (n: number) => Math.max(n, 0);

export function getCellSizing<D extends Record<string, any>>(
  column: ColumnInstance<D>,
  depthCorrection: number
) {
  const {
    minWidth = 0,
    totalWidth,
    maxWidth = Number.MAX_SAFE_INTEGER,
  } = column;

  return {
    width: positiveOrZero(Math.max(totalWidth, minWidth) - depthCorrection),
    maxWidth: positiveOrZero(maxWidth - depthCorrection),
    minWidth: positiveOrZero(minWidth - depthCorrection),
  };
}
