import {
  Link as MuiLink,
  linkClasses,
  LinkProps as MuiLinkProps,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

export interface FlexibleLinkColor {
  normal: string;
  hover?: string;
  active?: string;
}

export type LinkProps = MuiLinkProps<any, any> & {
  flexibleColor?: FlexibleLinkColor;
};

const Link = styled(MuiLink, {
  shouldForwardProp: (prop) => prop !== 'flexibleColor' && prop !== 'navigate',
})<LinkProps>(({ theme, color, flexibleColor }) => {
  const normalColor = flexibleColor?.normal || color;
  const hoverColor = flexibleColor?.hover || normalColor;
  const activeColor = flexibleColor?.active || normalColor;
  return {
    fontFamily: theme.typography.fontFamily,
    cursor: 'pointer',
    transition: theme.transitions.create('color'),

    color: normalColor || theme.palette.blue['70'],
    '&:hover': {
      color: hoverColor || theme.palette.blue['100'],
    },
    '&:active': {
      color: activeColor || theme.palette.grey['100'],
    },

    [`&.${linkClasses.underlineAlways}`]: {
      textDecoration: 'underline dotted',
      color: normalColor || theme.palette.blue['70'],
      '&:hover': {
        textDecoration: 'underline',
        color: hoverColor || theme.palette.blue['100'],
      },
      '&:active': {
        textDecoration: 'underline',
        color: activeColor || theme.palette.grey['100'],
      },
    },
  };
});

Link.defaultProps = {
  theme: defaultTheme,
  underline: 'none',
};

export default Link;
