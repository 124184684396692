import React, { memo, useCallback } from 'react';
import {
  FilterEditor,
  FilterRenderProps,
  FilterValidationHandler,
} from '../../TableFilter';
import { default as setEndOfDay } from 'date-fns/endOfDay';
import { DatePicker } from '@crpt/material';

interface DateFilterOptions {
  title?: React.ReactNode;
  format?: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  endOfDay?: boolean;
  validator?: FilterValidationHandler<DateFilterValue>;
}

interface DateFilterValue {
  date?: Date;
}

export function makeDateFilter(filterOptions?: DateFilterOptions) {
  const {
    format = 'dd.MM.yyyy',
    label = 'Дата',
    minDate,
    maxDate,
    endOfDay = false,
    title,
    validator,
  } = filterOptions || {};
  return memo((props: FilterRenderProps<DateFilterValue>) => {
    const {
      column,
      filterValue,
      setFilterValue,
      useValidator,
      executeValidation,
    } = props;

    const { error } = useValidator(validator);

    const onValueChange = useCallback(
      (value: Date) => {
        const date = endOfDay ? setEndOfDay(value) : value;
        setFilterValue({ date });
      },
      [setFilterValue]
    );

    return (
      <FilterEditor column={column} error={error} title={title}>
        <DatePicker
          name={column.id}
          dateFormat={format}
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          onBlur={executeValidation}
          onCalendarClose={executeValidation}
          onChange={onValueChange}
          selected={filterValue?.date}
          error={Boolean(error)}
        />
      </FilterEditor>
    );
  });
}
