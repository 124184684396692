/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BaseTextFieldProps as MuiBaseTextFieldProps } from '@material-ui/core';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@material-ui/lab';
import React, { FC } from 'react';

import Icon from '../Icon/Icon';
import Input from '../Input/Input';
import { useClasses } from './Autocomplete.styles';

export type AutocompleteProps = MuiAutocompleteProps<any, any, any, any> &
  Pick<MuiBaseTextFieldProps, 'label' | 'error' | 'required'> & {
    hint?: string;
    disabledOptionsAsLabel: boolean;
  };

export const Autocomplete: FC<AutocompleteProps> = ({
  error,
  label,
  loading,
  required,
  hint,
  disabledOptionsAsLabel,
  ...rest
}) => {
  const classes = useClasses({disabledOptionsAsLabel});

  return (
    <MuiAutocomplete
      classes={classes}
      closeIcon={<Icon name="Close" />}
      getOptionLabel={(option) => option['label']}
      popupIcon={<Icon name="Chevron" />}
      //@ts-ignore
      renderInput={(props) => (
        <Input
          {...props}
          //@ts-ignore
          error={error}
          hint={hint}
          label={label}
          loading={loading}
          required={required}
        />
      )}
      {...rest}
    />
  );
};

Autocomplete.defaultProps = {
  loadingText: 'Загрузка...',
  noOptionsText: 'Нет данных',
};

export default Autocomplete;
