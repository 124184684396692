import React from 'react';
import {
  ButtonBase,
  ButtonBaseProps,
  defaultTheme,
  Icons,
  styled,
} from '@crpt/material';

const DropdownToggleRoot = styled(ButtonBase)<ButtonBaseProps>(({ theme }) => ({
  width: 24,
  height: 24,
  color: theme.palette.blue['70'],
  '& .Arrow': {
    transform: 'rotate(-90deg)',
    '&.--open': {
      transform: 'rotate(0deg)',
    },
  },
}));

DropdownToggleRoot.defaultProps = {
  disableRipple: true,
  theme: defaultTheme,
};

export type DropdownToggleProps = {
  open?: boolean;
} & ButtonBaseProps;

export function DropdownToggle({ open, ...buttonProps }: DropdownToggleProps) {
  return (
    <DropdownToggleRoot {...buttonProps}>
      <Icons.ArrowBigDown_medium className={open ? 'Arrow --open' : 'Arrow'} />
    </DropdownToggleRoot>
  );
}
