import * as React from 'react';
import {
  CellProps,
  Column,
  ColumnInstance,
  ColumnInterfaceBasedOnValue,
  Hooks,
  Renderer,
} from 'react-table';

export function useCellContainer<T extends Record<string, any>>(
  hooks: Hooks<T>
) {
  hooks.columns.push((columns) => {
    return makeCellContainers(
      columns as UseCellContainerColumn<T>[]
    ) as Column<T>[];
  });
}

interface UseCellContainer<T extends Record<string, any>> {
  component: React.FC;
  props?: React.ComponentProps<any>;
  Cell?: Renderer<UseCellContainerCellProps<T>>;
}

type UseCellContainerColumn<T extends Record<string, any>> = Omit<
  Column<T>,
  'columns'
> &
  ColumnInterfaceBasedOnValue<T> & {
    Container: UseCellContainer<T>;
    columns?: UseCellContainerColumn<T>[];
  };

type UseCellContainerColumnInstance<T extends Record<string, any>> =
  ColumnInstance<T> & UseCellContainerColumn<T>;

type UseCellContainerCellProps<T extends Record<string, any>> = CellProps<T> & {
  column: UseCellContainerColumnInstance<T>;
  children?: React.ReactNode;
};

const isEmpty = (value: any) =>
  value === undefined || value === null || value === '';

function makeCellContainers<T extends Record<string, any>>(
  columns?: UseCellContainerColumn<T>[]
) {
  if (!columns?.length) {
    return;
  }

  return columns.map((original) => {
    const column = { ...original };

    if (typeof column.Container === 'object' && column.Container.component) {
      column.Container = {
        ...column.Container,
        Cell: column.Cell,
      };

      if (typeof column.Container.props === 'function') {
        column.Cell = ContainerWithDynamicProps;
      } else {
        column.Cell = ContainerWithStaticProps;
      }
    }
    column.columns = makeCellContainers(column.columns);
    return column;
  });
}

const ContainerWithStaticProps = <T extends Record<string, any>>(
  props: UseCellContainerCellProps<T>
) => {
  const OriginalCell = props.column.Container.Cell as React.FC<any>;
  const Container = props.column.Container.component;
  const containerProps = props.column.Container.props;
  const renderContent = () => {
    if (OriginalCell) {
      return <OriginalCell {...props} />;
    } else if (props.children) {
      return props.children;
    }
    return isEmpty(props.value) ? null : String(props.value);
  };
  return <Container {...containerProps}>{renderContent()}</Container>;
};

const ContainerWithDynamicProps = <T extends Record<string, any>>(
  props: UseCellContainerCellProps<T>
) => {
  const OriginalCell = props.column.Container.Cell as React.FC<any>;
  const Container = props.column.Container.component;
  const getContainerProps = props.column.Container.props;
  const renderChildren = () => {
    if (OriginalCell) {
      return <OriginalCell {...props} />;
    } else if (props.children) {
      return props.children;
    }
    return isEmpty(props.value) ? null : String(props.value);
  };
  const containerProps = getContainerProps({
    ...props,
    children: renderChildren(),
  });

  return <Container {...containerProps} />;
};
