import {
  CheckboxProps as MuiCheckboxProps,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core';
import React from 'react';

import FormControlLabel from '../FormControlLabel/FormControlLabel';
import Icon from '../Icon/Icon';
import CheckboxControl from './CheckboxControl';

export type CheckboxProps = Omit<
  MuiFormControlLabelProps,
  'color' | 'control'
> &
  Pick<
    MuiCheckboxProps,
    | 'checkedIcon'
    | 'color'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'icon'
    | 'indeterminate'
    | 'indeterminateIcon'
  >;
const Checkbox: React.FC<CheckboxProps> = ({
  checkedIcon = <Icon name="checkboxChecked" />,
  color = 'primary',
  disableRipple = true,
  disableTouchRipple = true,
  icon = <Icon name="checkbox" />,
  indeterminate,
  indeterminateIcon = <Icon name="checkboxIndeterminate" />,
  ...rest
}) => (
  <FormControlLabel
    control={
      <CheckboxControl
        checkedIcon={checkedIcon}
        color={color}
        disableRipple={disableRipple}
        disableTouchRipple={disableTouchRipple}
        icon={icon}
        indeterminate={indeterminate}
        indeterminateIcon={indeterminateIcon}
      />
    }
    {...rest}
  />
);

export default Checkbox;
