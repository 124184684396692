import React, { memo } from 'react';
import Button from '../../../Button/Button';
import Icon from '../../../Icon/Icon';
import clsx from 'clsx';
import TimeComponentStyles from './TimeComponent.styles';
import { WithStyles, withStyles, InputBase } from '@material-ui/core';

type TimeComponentProps = {
  value: string;
  onIncrease: () => void;
  onDecrease: () => void;
  handleChange: (value: string) => void;
} & WithStyles<typeof TimeComponentStyles>;

const TimeComponentSource: React.FC<TimeComponentProps> = memo(
  ({ value, onIncrease, onDecrease, handleChange, classes }) => {
    const handleInputChange = (event: any) => {
      handleChange(event.target.value);
    };
    return (
      <div className={classes.wrapper}>
        <InputBase
          style={{ marginLeft: '8px' }}
          className={classes.input}
          type="number"
          value={value}
          onChange={handleInputChange}
          inputProps={{ 'aria-label': 'naked' }}
        />
        <div className={classes.actionsButtonsWrapper}>
          <div className={classes.increaseButtonWrapper}>
            <Button
              className={clsx(classes.button, classes.rotatedButton)}
              variant="text"
              icon={<Icon name="chevron" />}
              onClick={onIncrease}
            />
          </div>
          <Button
            className={classes.button}
            variant="text"
            icon={<Icon name="chevron" />}
            onClick={onDecrease}
          />
        </div>
      </div>
    );
  }
);

export const TimeComponent = withStyles(TimeComponentStyles)(
  TimeComponentSource
);
