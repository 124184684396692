import {
  TableBody as MuiTableBody,
  tableBodyClasses,
  TableBodyProps,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';
import { defaultTheme, styled } from '../theme';

const TableBody = styled(MuiTableBody)<TableBodyProps>(({ theme }) => ({
  [`&.${tableBodyClasses.root} .${tableRowClasses.root}:last-child .${tableCellClasses.root}`]:
    {
      boxShadow: 'none',
    },
  [`&.${tableBodyClasses.root} .${tableRowClasses.root}:hover .${tableCellClasses.root}`]:
    {
      backgroundColor: theme.palette.grey['20'],
    },
}));

TableBody.defaultProps = {
  theme: defaultTheme,
};

export default TableBody;
