import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { Input } from '@crpt-ui/core';
import parse from 'date-fns/parse';

import RangeDatePicker from './RangeDatePicker';
import formatDatesString from './utils/formatDatesString';
import { START_YEAR, YEARS_AFTER } from './Datepicker.constants';

const RangeDatePickerInput = ({
  onChange,
  startDate,
  endDate,
  minDate,
  maxDate,
  displayFormat = 'dd.MM.yyyy',
  startYear = START_YEAR,
  yearsAfter = YEARS_AFTER,
  ...props
}) => {
  const [state, setState] = useState({
    value: formatDatesString(
      { startDate, endDate },
      { displayFormat, type: 'range' }
    ),
    startDate,
    endDate,
  });

  useEffect(() => {
    setState({
      value: formatDatesString(
        { startDate, endDate },
        { displayFormat, type: 'range' }
      ),
      startDate,
      endDate,
    });
  }, [startDate, endDate]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDateSubmit = (d) => {
    setState({
      value: formatDatesString(d, { displayFormat, type: 'range' }),
      startDate: d ? d.startDate : null,
      endDate: d ? d.endDate : null,
    });
    onChange(d);
    handleClose();
  };
  const handleChangeInput = (inputDate) => {
    const parsedDate = parse(inputDate, displayFormat, new Date());
    setState({ value: inputDate });
    if (parsedDate instanceof Date && !isNaN(parsedDate)) {
      onChange(parsedDate);
    }
  };

  return (
    <div>
      <Input
        value={state.value}
        onChange={(e) => handleChangeInput(e.target.value)}
        onClick={handleClick}
        {...props}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {anchorEl ? (
          <RangeDatePicker
            onClose={handleClose}
            onSubmit={handleDateSubmit}
            startDate={state.startDate}
            endDate={state.endDate}
            minDate={minDate}
            maxDate={maxDate}
            startYear={startYear}
            yearsAfter={yearsAfter}
          />
        ) : null}
      </Menu>
    </div>
  );
};
RangeDatePickerInput.propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  displayFormat: PropTypes.string,
};

export default RangeDatePickerInput;
