import React from 'react';

import Input from '../Input/Input';

const Textarea = (props) => {
  return React.createElement(Input, {
    ...props,
    multiline: true,
  });
};

export default React.memo(Textarea);
