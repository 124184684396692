import {
  actions,
  CellProps,
  Hooks,
  Row,
  TableInstance,
  UseColumnOrderState,
  UseRowStateRowProps,
} from 'react-table';
import { IconButton, Icons, MenuItem, MenuList, Popover } from '@crpt/material';
import React, { BaseSyntheticEvent, useCallback } from 'react';
import { TableRowAction, TableRowActionValueAccessor } from '../../table.types';

export const actionsColumnId = 'actionsColumn';

type ToggleSelectionCellProps = Omit<CellProps<any>, 'row' | 'state'> & {
  row: CellProps<any>['row'] & Partial<UseRowStateRowProps<any>>;
  state: { rowActions?: TableRowAction<any>[] };
};

type RowActionConfig = {
  key: number;
  label: string;
  disabled: boolean;
  hidden: boolean;
  onClick: (event: BaseSyntheticEvent) => void;
};

interface UseActionColumnTableInstance {
  setRowActions: (actions: TableRowAction<any>[]) => void;
}

actions.setRowActions = 'setRowActions';

const ActionsCell = (props: ToggleSelectionCellProps) => {
  const { row } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'rowMenuPopover' : undefined;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const actions: RowActionConfig[] = React.useMemo(
    () => makeActionConfigs(props.state.rowActions, row, handleClose),
    [props.state.rowActions, row, handleClose]
  );
  const isDisabled = isActionsDisabled(actions, row);

  return (
    <>
      <IconButton onClick={handleClick} disabled={isDisabled}>
        <Icons.Meatballs_medium />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        id={id}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>{actions.map(renderMenuItem)}</MenuList>
      </Popover>
    </>
  );
};

const actionColumn = {
  Cell: ActionsCell,
  id: actionsColumnId,
  disableFilters: true,
  minWidth: 84,
  maxWidth: 84,
};

export const useActionColumn = <T extends Record<string, any>>(
  hooks: Hooks<T>
) => {
  hooks.visibleColumns.push((columns) => {
    return [...columns, actionColumn];
  });
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);

  hooks.visibleColumnsDeps.push((deps, { instance }) => {
    const state = instance.state as UseColumnOrderState<any>;
    return [...deps, state.columnOrder];
  });
};

function reducer(state: Record<string, any>, action: Record<string, any>) {
  if (action.type === actions.init) {
    return {
      ...state,
      rowActions: state.rowActions || [],
    };
  }

  if (action.type === actions.setRowActions) {
    return {
      ...state,
      rowActions: action.value,
    };
  }
}

function useInstance(instance: any) {
  const { dispatch } = instance as TableInstance;

  (instance as UseActionColumnTableInstance).setRowActions = React.useCallback(
    (value) => {
      return dispatch({
        type: actions.setRowActions,
        value,
      });
    },
    [dispatch]
  );
}

const renderMenuItem = (action: RowActionConfig) => (
  <MenuItem
    disabled={action.disabled}
    key={action.key}
    onClick={action.onClick}
  >
    {action.label}
  </MenuItem>
);

function getActionAttr(
  action: TableRowAction<any>,
  row: Row<any>,
  attr: keyof TableRowAction<any>,
  defaultValue: any = undefined
) {
  if (typeof action[attr] === 'function') {
    return (action[attr] as TableRowActionValueAccessor<any>)(row);
  }

  return action[attr] ?? defaultValue;
}

function makeActionConfigs(
  actions: TableRowAction<any>[] | undefined,
  row: Row<any>,
  onClose: () => void
): RowActionConfig[] {
  if (!actions?.length) {
    return [];
  }
  return actions
    .map((action, index) => ({
      label: getActionAttr(action, row, 'label'),
      disabled: getActionAttr(action, row, 'disabled', false),
      hidden: getActionAttr(action, row, 'hidden', false),
      key: index,
      onClick: (event: BaseSyntheticEvent) => {
        event.preventDefault();
        action.callback(row);
        onClose();
      },
    }))
    .filter((action) => !action.hidden);
}

function isActionsDisabled(
  actions: RowActionConfig[],
  row: ToggleSelectionCellProps['row']
): boolean {
  return (
    !actions.length ||
    Boolean(row?.state?.disabled) ||
    actions.every((action) => action.hidden || action.disabled)
  );
}
