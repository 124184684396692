import { Time } from '../TimePicker.types';
import { isTimeValid } from './timeValidation';
import { convertNumberToCorrectString } from './timeConvert';

export const getTimeTitle = (time?: Time, titleForInput = false): string => {
  if (time && isTimeValid(time)) {
    const hours = convertNumberToCorrectString(Number(time.hours));
    const minutes = convertNumberToCorrectString(Number(time.minutes));
    const seconds = convertNumberToCorrectString(Number(time.seconds));
    return `${hours}:${minutes}:${seconds}`;
  }
  return titleForInput ? '' : 'Время';
};
