import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      fontSize: 14,
      lineHeight: 24 / 14,
      margin: '32px 0',

      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    error: {
      color: theme.palette.error.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    icon: {
      marginRight: 8,
    },
  });
