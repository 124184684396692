import React, { memo, useCallback, useMemo } from 'react';
import {
  FilterEditor,
  FilterRenderProps,
  FilterValidationHandler,
} from '../../TableFilter';
import { DatePicker, defaultTheme, Stack, styled } from '@crpt/material';

interface DateRangeFilterOptions<
  KeyFrom extends string,
  KeyTo extends string,
  Value
> {
  title?: React.ReactNode;
  format?: string;
  startLabel?: string;
  endLabel?: string;
  minDate?: Date;
  maxDate?: Date;
  CustomInput?: React.ForwardRefExoticComponent<React.RefAttributes<unknown>>;
  validator?: FilterValidationHandler<
    DateRangeFilterValue<KeyFrom, KeyTo, Value>
  >;
  keyFrom?: KeyFrom;
  keyTo?: KeyTo;
  dateConverter?: {
    toDate: (value: Value, isStart: boolean, key: KeyFrom | KeyTo) => Date;
    toValue: (date: Date, isStart: boolean, key: KeyFrom | KeyTo) => Value;
  };
}

export type DateRangeFilterValue<
  KeyFrom extends string,
  KeyTo extends string,
  Value
> = Partial<Record<KeyFrom | KeyTo, Value>>;

const DateDivider = styled('div')(({ theme }) => ({
  width: 16,
  borderBottom: '1px solid',
  borderColor: theme.palette.grey['100'],
  flex: '0 0 auto',
}));

DateDivider.defaultProps = {
  theme: defaultTheme,
};

export function makeDateRangeFilter<
  Value = Date,
  KeyFrom extends string = 'startDate',
  KeyTo extends string = 'endDate'
>(filterOptions?: DateRangeFilterOptions<KeyFrom, KeyTo, Value>) {
  const {
    title,
    format = 'dd.MM.yyyy',
    startLabel = 'От',
    endLabel = 'До',
    minDate,
    maxDate = new Date(),
    validator,
    CustomInput,
    keyFrom = 'startDate',
    keyTo = 'endDate',
    dateConverter,
  } = filterOptions || {};
  return memo(
    (props: FilterRenderProps<DateRangeFilterValue<KeyFrom, KeyTo, Value>>) => {
      const {
        column,
        filterValue,
        setFilterValue,
        useValidator,
        executeValidation,
      } = props;
      const { error } = useValidator(validator);

      const [startDate, endDate] = useMemo(() => {
        const valueFrom = filterValue?.[keyFrom as KeyFrom];
        const valueTo = filterValue?.[keyTo as KeyTo];

        if (dateConverter) {
          const { toDate } = dateConverter;
          return [
            valueFrom
              ? toDate(valueFrom as Value, true, keyFrom as KeyFrom)
              : undefined,
            valueTo
              ? toDate(valueTo as Value, false, keyTo as KeyTo)
              : undefined,
          ];
        }
        return [valueFrom as Date | undefined, valueTo as Date | undefined];
      }, [filterValue]);

      const onStartChange = useCallback(
        (value: Date) => {
          setFilterValue({
            ...filterValue,
            [keyFrom]:
              dateConverter && value
                ? dateConverter.toValue(value, true, keyFrom as KeyFrom)
                : value,
          });
        },
        [filterValue, setFilterValue]
      );

      const onEndChange = useCallback(
        (value: Date) => {
          setFilterValue({
            ...filterValue,
            [keyTo]:
              dateConverter && value
                ? dateConverter.toValue(value, false, keyTo as KeyTo)
                : value,
          });
        },
        [filterValue, setFilterValue]
      );

      return (
        <FilterEditor column={column} error={error} title={title}>
          <Stack alignItems="center" direction="row" gap="12px">
            <DatePicker
              name={column.id}
              dateFormat={format}
              label={startLabel}
              maxDate={endDate || maxDate}
              minDate={minDate}
              onBlur={executeValidation}
              onCalendarClose={executeValidation}
              onChange={onStartChange}
              selected={startDate}
              customInput={<CustomInput />}
              error={Boolean(error)}
            />
            <DateDivider />
            <DatePicker
              name={column.id}
              dateFormat={format}
              label={endLabel}
              maxDate={maxDate}
              minDate={startDate}
              customInput={<CustomInput />}
              onBlur={executeValidation}
              onCalendarClose={executeValidation}
              onChange={onEndChange}
              selected={endDate}
              error={Boolean(error)}
            />
          </Stack>
        </FilterEditor>
      );
    }
  );
}
