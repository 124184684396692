import React from 'react';
import {
  Box,
  BoxProps,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@crpt/material';

export interface EmptyDataPanelProps extends BoxProps {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

export function EmptyDataPanel({
  icon,
  title,
  children,
  ...boxProps
}: EmptyDataPanelProps) {
  const theme = useTheme();
  return (
    <Box {...boxProps}>
      <Paper
        variant="outlined"
        sx={{
          padding: '20px',
          borderRadius: '8px',
          borderColor: theme.palette.grey['30'],
        }}
      >
        <Stack direction="row" gap="20px">
          <Box color={theme.palette.grey['60']}>{icon}</Box>
          <Stack gap="4px">
            <Typography variant="h4">{title}</Typography>
            <Typography variant="p2" color={theme.palette.grey[100]}>
              {children}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
